import { useTheme } from "@emotion/react";
import { Container, useMediaQuery } from "@mui/material";
import React from "react";
import { AdminContainer } from "../components/admin/adminContainer";
export const AdminSite = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<AdminContainer />
		</Container>
	);
};
