import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { CreateInput } from "../../../assets/js/formInputs";
import { SubmitContactBtn } from "../../home/contact/submitContactBtn";
import { createFormularLangs } from "../../../assets/dummyData/langy";
import { useSelector } from "react-redux";
import { forgot } from "../../../assets/js/axiosCalls";
import { motion } from "framer-motion";

export const ForgotPwd = () => {
	const [langs] = useState(createFormularLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("successCheckEmailMsg")[language]}
	const inputRef = useRef({});
	const [showLoading, setShowLoading] = useState(false);
	const [errSubmit, setErrSubmit] = useState(false);
	const [err, setErr] = useState(false);
	const [checkYourEmail, setCheckYourEmail] = useState(false);
	const [badMail, setBadMail] = useState(false);

	const handleChangeInputs = (resEvent, resName, errorInput) => {
		inputRef.current[resName] = errorInput ? "" : resEvent.target.value;
		return {};
	};

	const dummyInputData = [
		{
			col: "",
			name: "mail",
			typ: "text",
			label:langs.get("lb_zadaj_svoj_mail")[language],
			errBool: false,
			reqBool: true,
			cb: handleChangeInputs,
			help:langs.get("lbMsg_zadaj_svoj_mail")[language],
			row: "",
		},
	];

	const handleSubmit = (e) => {
		e.preventDefault();
		setBadMail(false);
		setErr(false);
		let sendObj = inputRef.current;
		setShowLoading(true);
		setErrSubmit(false);

		let out = setTimeout(() => {
			if (!sendObj.mail) {
				setErr(true);
			} else {
				setErr(false);
				try {
					forgot(sendObj).then((res) => {
						if (res.status !== 500) {
							setShowLoading(false);
							setCheckYourEmail(true);
						} else {
							setErr(true);
							setBadMail(true);
							setShowLoading(false);
							setErrSubmit(true);
						}
					});
				} catch {
					console.log(err);
					setCheckYourEmail(false);
					setShowLoading(false);
					setErrSubmit(true);
				}
			}
			clearTimeout(out);
		}, 1000);
	};

	return (
		<Box component="form" noValidate autoComplete="off">
			<Grid
				container
				justifyContent="center"
				spacing={3}
				sx={{
					padding: 3,
				}}
			>
				{!checkYourEmail && (
					<>
						{dummyInputData.map((item, idx) => {
							return (
								<Grid key={idx} item xs={12}>
									<CreateInput data={item} />
								</Grid>
							);
						})}
						{err && (
							<Grid item xs={12} align="left">
								<motion.div
									initial={{ opacity: 0, scale: 0.5 }}
									animate={{ opacity: 1, scale: 1 }}
									transition={{
										duration: 0.1,
										ease: [0, 0.71, 0.2, 1.01],
									}}
								>
									<Alert severity="error">
										<AlertTitle>{langs.get("errTitleFrom")[language]}</AlertTitle>
										<Typography>{badMail ? langs.get("errNespravnyMail")[language] : langs.get("errRequiredMail")[language]} </Typography>
									</Alert>
								</motion.div>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "end",
								width: "100%",
							}}
						>
							{!checkYourEmail && <SubmitContactBtn loading={showLoading} afterErrloading={errSubmit} cb={handleSubmit} text={langs.get("btn_poziadat_o_zmenu")[language]} errMsg={err} w={1} />}
						</Grid>
					</>
				)}
				{checkYourEmail && (
					<Grid item xs={12} align="left">
						<Alert>
							<AlertTitle>{langs.get("successSendMsgFrom")[language]}</AlertTitle>
							<Typography>{langs.get("successCheckEmailMsg")[language]} </Typography>
						</Alert>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
