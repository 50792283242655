import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";

const CoverImage = styled("div")({
	width: 100,
	height: 100,
	objectFit: "cover",
	overflow: "hidden",
	flexShrink: 0,
	borderRadius: 8,
	backgroundColor: "rgba(0,0,0,0.08)",
	"& > img": {
		width: "100%",
	},
});

export const HeaderWidget = (props) => {
	const { songObj } = props;
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<CoverImage>{Object.keys(songObj.album).length !== 0 && songObj.album.images[2] && <img alt="can't win - Chilling Sunday" src={songObj.album.images[2].url} />}</CoverImage>
			<Box sx={{ ml: 1.5, minWidth: 0 }}>
				<Typography variant="caption" color="text.secondary" fontWeight={500}>
					{songObj.artists.length !== 0 && songObj.artists[0].name}
				</Typography>
				<Typography noWrap>
					<b>{songObj.name && songObj.name}</b>
				</Typography>
			</Box>
		</Box>
	);
};
