import mssql from "../images/loga/sql.svg";
import node from "../images/loga/node.svg";
import reactLogo from "../images/loga/React.svg";
import csharp from "../images/loga/Csharp.svg";
import js from "../images/loga/JavaScript.svg";
import nest from "../images/loga/NestJS.svg";
import adobe from "../images/loga/adobe.svg";
import auto from "../images/loga/auto.svg";
import angular from "../images/loga/angular.svg";
import aws from "../images/loga/aws.svg";
import bootstrap from "../images/loga/bootstrap.svg";
import git from "../images/loga/git.svg";
import html from "../images/loga/htmlcss.svg";
import jq from "../images/loga/jquery.svg";
import lang from "../images/loga/lang.svg";
import linux from "../images/loga/linux.svg";
import mongo from "../images/loga/mongo.svg";
import oop from "../images/loga/oop.svg";
import ras from "../images/loga/ras.jpg";
import wezeo from "../images/loga/wezeo.svg";
import vue from "../images/loga/vue.jpg";
import unicorn from "../images/loga/unicorn.svg";
import uat from "../images/loga/uat.jpg";
import typescript from "../images/loga/typescript.svg";
import tp from "../images/loga/tp.svg";
import tailwind from "../images/loga/tailwind.svg";
import serverless from "../images/loga/serverless.svg";
import sass from "../images/loga/sass.svg";
const courses = [
	{
		title: "Complete Microsoft SQL Server Database Administration Course",
		subtitle: "",
		address: "",
		date: "",
		iconSrc: mssql,
	},
	{
		title: "Serverless Framework Bootcamp",
		subtitle: " Node.js, AWS & Microservices",
		address: "",
		date: "",
		iconSrc: serverless,
	},
	{
		title: "Amazon Web Services ",
		subtitle: "(AWS) with JavaScript & NodeJS",
		address: "",
		date: "",
		iconSrc: aws,
	},
	{
		title: "Complete Linux Training Course to Get Your Dream IT",
		subtitle: "",
		address: "",
		date: "",
		iconSrc: linux,
	},
	{
		title: "Complete C# Masterclass",
		subtitle: "",
		address: "",
		date: "",
		iconSrc: csharp,
	},
	{
		title: "React Native",
		subtitle: "The Practical Guide [2023]",
		address: "",
		date: "",
		iconSrc: reactLogo,
	},
	{
		title: "React - The Complete Guide",
		subtitle: "Hooks, React Router, Redux",
		address: "",
		date: "",
		iconSrc: reactLogo,
	},
	{
		title: "Master NestJS",
		subtitle: "The JavaScript Node.js Framework",
		address: "",
		date: "",
		iconSrc: nest,
	},
	{
		title: "Understanding TypeScript",
		subtitle: "2021 Edition",
		address: "",
		date: "",
		iconSrc: typescript,
	},
	{
		title: "NestJS Zero to Hero",
		subtitle: "Modern TypeScript Back-end Development",
		address: "",
		date: "",
		iconSrc: nest,
	},
	{
		title: "NodeJS ",
		subtitle: "The Complete Guide (MVC, REST APIs, GraphQL, Deno)",
		address: "",
		date: "",
		iconSrc: node,
	},
	{
		title: "The Complete Modern JavaScript Masterclass 2021",
		subtitle: "",
		address: "",
		date: "",
		iconSrc: js,
	},
	{
		title: "Quasar V1: PWA",
		subtitle: "with Vue JS 2, Firebase, NodeJS & Express",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Vuetify",
		subtitle: "Create an App with Vue JS & Vuex - in 5 Hours!",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Intro to Node JS & Express",
		subtitle: "",
		address: "",
		date: "",
		iconSrc: node,
	},
	{
		title: "JavaScript DOM Dynamic Web interactive content Boot Camp",
		subtitle: "Vzhurudolu.cz",
		address: "",
		date: "",
		iconSrc: js,
	},
	{
		title: "Praktický úvod do Vue",
		subtitle: "Vzhurudolu.cz",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Vue 3 prakticky",
		subtitle: "Vue Mastery",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Intro to Vue 2 & Vue 3",
		subtitle: "Vue Mastery",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Real World Vue 3 & Tourin Vue Router",
		subtitle: "Vue Mastery",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Vue 3 Forms",
		subtitle: "Vue Mastery",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "MongoDb",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: mongo,
	},
	{
		title: "Webrebel 1: HTML, CSS & JavaScript",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: html,
	},
	{
		title: "JavaScript & ES6",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: js,
	},
	{
		title: "React.js",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: reactLogo,
	},
	{
		title: "Angular 2+",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: angular,
	},
	{
		title: "Vue.js + SPA",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: vue,
	},
	{
		title: "Css preprocesory: Sass",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: sass,
	},
	{
		title: "Git",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: git,
	},
	{
		title: "OOP",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: oop,
	},
	{
		title: "Bootstrap",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: bootstrap,
	},
	{
		title: "Tailwind Css",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: tailwind,
	},
	{
		title: "Adobe Illustrator",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: adobe,
	},
	{
		title: "Adobe Photoshop",
		subtitle: "Learn2code",
		address: "",
		date: "",
		iconSrc: adobe,
	},
	{
		title: "Úvod do OOP v JavaScripte & jQuery",
		subtitle: "ITnetwork.cz",
		address: "",
		date: "",
		iconSrc: jq,
	},
];
const workPositions = [
	{
		title: "Frontend Developer - Ticketportal",
		subtitle: "Html, css, Javascript(JQuery), VBScript, Bootstrap 5, T-SQL(ssms), tfs",
		address: "Kalinčiakova 33, 831 04 Bratislava",
		date: "11/2021 - trvá",
		iconSrc: tp,
	},
	{
		title: "Full-stack Developer - Unicorn (Stáž)",
		subtitle: "React.js",
		address: "Ševčenkova 3770/34, 851 01, Bratislava",
		date: "10/2021",
		iconSrc: unicorn,
	},
	{
		title: "Software Programmer - WEZEO",
		subtitle: "Vue.js, Bootstrap",
		address: "Palackého 14 811 02 Bratislava",
		date: "9/2021",
		iconSrc: wezeo,
	},
	{
		title: "Web Developer - Ringier Axel Springer (Stáž)",
		subtitle: "Html, Css, React.js, Typescript",
		address: "Prievozská 1318/14, 821 09 Bratislava",
		date: "3/2021 - 7/2021",
		iconSrc: ras,
	},
];

export const initialData = [
	{
		panel: "panel1",
		textHeader: "workExperienceTitle",
		textSubHeader: "lang_onlyRelevatWork",
		textDetail: workPositions,
		lang: false,
	},
	{
		panel: "panel2",
		textHeader: "label_education",
		textSubHeader: "label_school",
		textDetail: [
			{
				title: "label_schoolTitle",
				subtitle: "label_schoolOdb",
				address: "Vlastenecké námestie 1, Bratislava 851 01",
				date: "2004 - 2009",
				iconSrc: uat,
			},
		],
		lang: true,
	},
	{
		panel: "panel3",
		textHeader: "label_courses",
		textSubHeader: "label_courseTitles",
		textDetail: courses,
		lang: false,
	},
	{
		panel: "panel4",
		textHeader: "label_other",
		textSubHeader: "label_otherSubtitle",
		textDetail: [
			{
				title: "label_engLanguage",
				subtitle: "B1",
				address: "",
				date: "",
				iconSrc: lang,
			},
			{
				title: "label_diverlicense",
				subtitle: "B",
				address: "",
				date: "",
				iconSrc: auto,
			},
		],
		lang: true,
	},
];
