import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createProfileLangs } from "../../assets/dummyData/langy";
import { theme } from "../../theme/theme";
import { useTheme } from "@emotion/react";
import { InfoProfileContainer } from "./infoProfile/infoProfileContainer";
import { EditProfileButtons } from "./editProfileButtons";

export const ProfileContainer = () => {
	const [langs] = useState(createProfileLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	return (
		<Grid container p={3} spacing={4}>
			<Grid item xs={12}>
				<Typography variant={tablet ? "h2" : "h4"} align="center" color={mode === "dark" ? theme.palette.primary.main : theme.palette.dark.main} fontWeight={900}>
					{langs.get("langProfile")[language].toLocaleUpperCase()}
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<InfoProfileContainer />
			</Grid>
			<Grid item xs={12}>
				<EditProfileButtons />
			</Grid>
		</Grid>
	);
};
