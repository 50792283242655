import React, { useEffect, useState } from "react";
import { ForgotPwd } from "./forgotPwd";
import { Card, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { theme } from "../../../theme/theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { checkTokenChngPwd } from "../../../assets/js/axiosCalls";
import { ForgotPwdCreate } from "./forgotPwdCreate";
import { createFormularLangs } from "../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const ForgotPwdContainer = () => {
	const [langs] = useState(createFormularLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);

	const search = useLocation().search;
	const [token, setToken] = useState(null);
	const [preloader, setPreloader] = useState(false);
	const [errToken, setErrToken] = useState(false);
	const [allowCreate, setAllowCreate] = useState(false);
	const [user, setUser] = useState("");
	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		setToken(searchParams.get("token"));
		if (token) {
			setPreloader(true);
			try {
				let out = setTimeout(() => {
					checkTokenChngPwd(token)
						.then((res) => {
							setPreloader(false);
							if (res && res.status === 200) {
								setUser(res.user);
								setAllowCreate(true);
							} else {
								setErrToken(true);
								setAllowCreate(false);
							}
						})
						.catch((err) => {
							console.error(err);
						});
					clearTimeout(out);
				}, 1000);
			} catch (error) {
				console.error(error);
			}
		}
	}, [search, token]);
	return (
		<Container maxWidth="md">
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{
					duration: 0.8,
					delay: 0.5,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<Card className="bg-gradient">
					<Grid
						container
						padding={3}
						spacing={3}
						sx={{
							height: "100%",
							position: "relative",
						}}
					>
						{preloader && (
							<div
								style={{
									color: theme.palette.dark.main,
									position: "absolute",
									top: "50.7%",
									left: "50%",
									zIndex: 2000,
									transform: "translate(-50%,-50%)",
								}}
							>
								<CircularProgress size={80} color="dark" />
							</div>
						)}
						{errToken && token && (
							<ErrorOutlineIcon
								sx={{
									fontSize: 90,
									color: theme.palette.dark.main,
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%,-50%)",
									zIndex: 2000,
								}}
							/>
						)}
						<Grid item xs={12}>
							<Typography color={mode === 'dark' ? theme.palette.primary.main :theme.palette.dark.main} variant="h4">{langs.get("btnforgotPwd")[language].toLocaleUpperCase()}</Typography>
						</Grid>
						<Grid item xs={12}>
							{allowCreate ? <ForgotPwdCreate user={user} token={token} /> : <ForgotPwd />}
						</Grid>
					</Grid>
				</Card>
			</motion.div>
		</Container>
	);
};
