import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: localStorage.getItem("mode") ? localStorage.getItem("mode") : "dark",
};

export const globalThemeModeSlice = createSlice({
	name: "mode",
	initialState,
	reducers: {
		setThemeMode: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setThemeMode } = globalThemeModeSlice.actions;

export default globalThemeModeSlice.reducer;
