import { useTheme } from "@emotion/react";
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { theme } from "../../../theme/theme";

const MenuProps = {
	sx: {
		"& .MuiMenu-paper": {
			color: theme.palette.light.main,
		},
		"& .MuiMenuItem-root:hover": {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.dark.main,
		},
		"& .Mui-selected": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.dark.main,
		},
	},
};
const stylesOver = {
	color: theme.palette.light.main,
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: theme.palette.dark.main,
	},
	".MuiSvgIcon-root": {
		color: theme.palette.light.main,
	},
	"&:before": {
		borderBottom: `1px solid ${theme.palette.light.main}`,
	},
	"&:hover": {
		":before": {
			borderBottom: `1px solid ${theme.palette.dark.main}`,
		},
	},
	"& .MuiMenuItem-root": {
		backgroundColor: theme.palette.dark.main,
	},
	"& .MuiMenu-paper": {
		backgroundColor: theme.palette.dark.main,
	},
};

function getStyles(name, personName, theme) {
	return {
		fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}
export const SelectStyled = (props) => {
	const mode = useSelector((state) => state.mode.value);
	const theme = useTheme();
	const [personName, setPersonName] = useState(props.multi ? [] : [props.options[0]]);
	const idLabel = props.lang.replaceAll(" ", "");

	const handleChange = (event) => {
		console.log(event.target.value);
		props.cb(event.target.value, props.name, null);

		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value,
		);
	};
	return (
		<FormControl sx={{ width: "100%" }}>
			<InputLabel
				id={idLabel}
				color={mode === "dark" ? "dark" : "light"}
				sx={{
					color: mode === "dark" ? theme.palette.dark.main : theme.palette.light.main,
					"&$formLabelFocused": { color: theme.palette.light.main },
				}}
			>
				{props.lang}
			</InputLabel>
			<Select
				autoWidth
				labelId={idLabel}
				name={props.name}
				multiple={props.multi ? true : false}
				value={personName}
				label={props.lang}
				onChange={handleChange}
				input={<OutlinedInput id={idLabel} label={props.lang} />}
				renderValue={(selected) => (
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, width: "80%" }}>
						{selected.map((value) => (
							<Chip
								key={value}
								size="small"
								sx={{
									color: theme.palette.dark.main,
									background: mode === "dark" ? theme.palette.secondary.main : theme.palette.light.main,
								}}
								label={value}
							/>
						))}
					</Box>
				)}
				MenuProps={MenuProps}
				sx={stylesOver}
			>
				{props.options.map((name) => (
					<MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
						{name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
