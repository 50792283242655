import { useTheme } from "@emotion/react";
import { Container, Grid, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createCvLangs } from "../assets/dummyData/langy";
import { Cvpaper } from "../components/cv/cvpaper";
import { SideContainer } from "../components/cv/sideContainer";

export const Curriculum = () => {
	const [langs] = useState(createCvLangs());
	const language = useSelector((state) => state.language.value);
	const loged = useSelector((state) => state.userLoginBtn.value);
	const navigate = useNavigate();
	//{langs.get("storyTitle")[language]}
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const memoizedCallback = useCallback(() => {
		if (!loged) {
			console.log(loged);
			navigate("/topics/login");
		}
	}, [loged, navigate]);
	useEffect(() => {
		memoizedCallback();
	}, [memoizedCallback]);

	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<SideContainer langs={langs} language={language} />
				</Grid>
				<Grid item xs={12} md={9}>
					<Cvpaper langs={langs} language={language} />
				</Grid>
			</Grid>
		</Container>
	);
};
