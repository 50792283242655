export const formContainerState = [
	{
		id: "meno",
		label: "firstNameFrom",
		typ: "text",
		value: "meno",
		hide: true,
		err: false,
		errMsg: "errFirstNameFrom",
		helperText: null,
		ref: null,
		cb: () => null,
	},
	{
		id: "priezvisko",
		label: "lastNameFrom",
		typ: "text",
		value: "priezvisko",
		hide: true,
		err: false,
		errMsg: "errLastNameFrom",
		helperText: null,
		ref: null,
		cb: () => null,
	},
	{
		id: "email",
		label: "emailFrom",
		typ: "text",
		value: "email",
		hide: false,
		err: false,
		errMsg: "errEmailFrom",
		helperText: null,
		ref: null,
		cb: () => null,
	},
	{
		id: "heslo",
		label: "paswdFrom",
		typ: "password",
		value: "heslo",
		hide: false,
		err: false,
		errMsg: "errPaswdFrom",
		helperText: "helpPaswdFrom",
		ref: null,
		cb: () => null,
	},
	{
		id: "reheslo",
		label: "rePaswdFrom",
		typ: "password",
		value: "heslo",
		hide: true,
		err: false,
		errMsg: "errRePaswdFrom",
		helperText: null,
		ref: null,
		cb: () => null,
	},
	{
		id: "policy",
		label: "lang_termsInput",
		typ: "checkbox",
		value: "https://www.privacypolicies.com/live/3de54dc0-608b-43d6-8c48-650197602bd1",
		hide: true,
		err: false,
		errMsg: "errLang_termsInput",
		helperText: null,
		cb: () => null,
	},
];
