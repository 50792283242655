import React, { useEffect, useRef, useState } from "react";
import { createProfileLangs } from "../../../assets/dummyData/langy";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { editProfileInfo } from "../../../assets/js/axiosCalls";
import { CreateInput } from "../../../assets/js/formInputs";
import { SubmitContactBtn } from "../../home/contact/submitContactBtn";
import { VacancyList } from "./vacancyList";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../store/features/profile/profileSlice";

export const EditForm = (props) => {
	const [vacancyList, setVacancyList] = useState([]);
	const [langs] = useState(createProfileLangs());
	const language = useSelector((state) => state.language.value);
	const profileData = useSelector((state) => state.profile.userdata);
	const dispatch = useDispatch();

	const [showLoading, setShowLoading] = useState(false);
	const [errSubmit, setErrSubmit] = useState(false);
	const [err, setErr] = useState(false);

	const navigate = useNavigate();

	const inputRef = useRef({});

	const sendEditForm = (e) => {
		e.preventDefault();
		setErr(false);
		let sendObj = inputRef.current;
		sendObj.vacancy = vacancyList;
		setShowLoading(true);
		setErrSubmit(false);

		const data = { user: props.user, data: sendObj };

		try {
			editProfileInfo(data).then((res) => {
				if (res.status !== 500) {
					setShowLoading(false);
					dispatch(setUserData(sendObj));

					navigate("/profile");
				} else {
					setErr(true);
					setShowLoading(false);
					setErrSubmit(true);
				}
			});
		} catch {
			console.log("ERR EDIT");
		}
	};
	const handleChangeVacancyList = (resEvent) => {
		setVacancyList(resEvent);
		dispatch(setUserData({ vacancy: resEvent }));
	};
	const handleChangeInputs = (resEvent, resName, errorInput) => {
		inputRef.current[resName] = errorInput ? "" : resEvent.target ? resEvent.target.value : resEvent;
		return {};
	};
	useEffect(() => {
		let objVal = Object.values(profileData);
		Object.keys(profileData).map((i, idx) => handleChangeInputs(objVal[idx], i, null));
	}, [inputRef, profileData]);
	const dummyInputData = [
		{
			col: "4",
			name: "company",
			typ: "text",
			label: langs.get("lb_titleCompany")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
			val: Object.keys(profileData).length !== 0 && profileData.company ? profileData.company : "",
		},
		{
			col: "4",
			name: "position",
			typ: "text",
			label: langs.get("lb_titlePosition")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
			val: Object.keys(profileData).length !== 0 && profileData.position ? profileData.position : "",
		},
		{
			col: "4",
			name: "area",
			typ: "text",
			label: langs.get("lb_titleArea")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
			val: Object.keys(profileData).length !== 0 && profileData.area ? profileData.area : "",
		},
		{
			col: "4",
			name: "address",
			typ: "text",
			label: langs.get("lb_address")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
			val: Object.keys(profileData).length !== 0 && profileData.address ? profileData.address : "",
		},
		{
			col: "4",
			name: "web",
			typ: "text",
			label: langs.get("lb_titleWeb")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
			val: Object.keys(profileData).length !== 0 && profileData.web ? profileData.web : "",
		},
	];

	return (
		<Box component="form" noValidate autoComplete="off">
			<Grid
				container
				justifyContent="center"
				spacing={2}
				sx={{
					padding: 3,
				}}
			>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
					}}
				>
					<Typography align="center" gutterBottom variant="body2">
						{langs.get("lb_editInfo")[language]}
					</Typography>
					{dummyInputData.map((item, idx) => {
						return <CreateInput key={idx} data={item} />;
					})}
				</Grid>
				<Grid item xs={12} md={6}>
					<VacancyList
						cb={handleChangeVacancyList}
						data={Object.keys(profileData).length !== 0 && profileData.vacancy ? profileData.vacancy : null}
						label={langs.get("lb_addPosition")[language]}
						title={langs.get("lb_listVacancy")[language]}
					/>
				</Grid>
				<Grid item xs={12}>
					<SubmitContactBtn loading={showLoading} afterErrloading={errSubmit} cb={sendEditForm} text={langs.get("lb_btnConfirm")[language]} errMsg={err} w={1} />
				</Grid>
			</Grid>
		</Box>
	);
};
