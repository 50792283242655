import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { IconComponent } from "../../../assets/images/svg/iconComponent";
import { theme } from "../../../theme/theme";

export const AboutCardItem = (props) => {
	const { image, subtitle, text, imgSize } = props;
	const mode = useSelector((state) => state.mode.value);
	return (
		<Paper
			elevation={10}
			className={mode === "light" ? "bg-gradient" : "bg-gradient-dark"}
			sx={{
				overflow: "hidden",
			}}
		>
			<div
				style={{
					position: "relative",
					zIndex: 1,
					width: "100%",
				}}
			>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: mode === "light" ? theme.palette.secondary.main : theme.palette[mode].main,
						clipPath: "polygon(0 0, 60px 0, 190px 100%, 0 100%)",
						zIndex: -1,
					}}
				></div>
				<Stack direction={imgSize === 10 ? "row" : "column"} spacing={3} alignItems="center">
					<Box mt={1}>
						<IconComponent src={image} size={10} />
					</Box>
					<Box
						sx={{
							marginTop: "0px !important",
							padding: imgSize === 10 ? 2 : 0,
							paddingBottom: imgSize === 10 ? 2 : 3,
							width: "100%",
						}}
					>
						<Typography
							gutterBottom
							color={theme.palette.light.main}
							variant="h5"
							fontWeight={700}
							sx={{
								textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
							}}
						>
							{subtitle}
						</Typography>
						<Typography variant="subtitle2" color={theme.palette.light.main} fontStyle="italic" maxWidth={400} sx={{ padding: 2 }}>
							{text}
						</Typography>
					</Box>
				</Stack>
			</div>
		</Paper>
	);
};
