import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderWidget } from "./headerWidget";
import { PlayerButtons } from "./playerButtons";
import { SliderDurationWidget } from "./sliderDurationWidget";
import { SliderVolumeWidget } from "./sliderVolumeWidget";

const Widget = styled("div")(({ theme }) => ({
	padding: 16,
	borderRadius: 16,
	width: 343,
	maxWidth: "100%",
	margin: "auto",
	position: "relative",
	zIndex: 1,
	backgroundColor: theme.palette.mode === "dark" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.4)",
	backdropFilter: "blur(40px)",
}));

export const ContainerPlayer = () => {
	const songObj = useSelector((state) => state.song);
	const songTrack = useSelector((state) => state.song.preview_url);

	const [play, setPlay] = useState(null);

	const seterPlay = (e) => {
		setPlay(e);
	};

	useEffect(() => {
		songTrack && seterPlay(new Audio(songTrack));
	}, [songTrack]);

	return (
		<Widget>
			<HeaderWidget songObj={songObj} />
			<SliderDurationWidget duration={play && play.duration} />
			<PlayerButtons track={play} />
			<SliderVolumeWidget />
		</Widget>
	);
};
