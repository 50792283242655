import { DeleteOutline } from "@mui/icons-material";
import { Button, FormGroup, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../theme/theme";
import AddIcon from "@mui/icons-material/Add";
export const VacancyList = (props) => {
	const [vacancyList, setVacancyList] = useState(props.data ? props.data : []);
	const [inputValue, setInputValue] = useState("");

	const handleChangeInput = (e) => setInputValue(e.target.value);
	const removeItem = (delItem) => {
		let newItems = vacancyList.filter((i) => i !== delItem);
		setVacancyList(newItems);
		props.cb(newItems);
	};
	const handleAdd = () => {
		if (vacancyList.includes(inputValue) || inputValue === "") return;
		setVacancyList([...vacancyList, inputValue]);
		props.cb([...vacancyList, inputValue], "vacancy");
		setInputValue("");
	};
	return (
		<Grid container>
			<Grid item xs={12} mb={1}>
				<Typography align="center" gutterBottom mb={1.6} variant="body2">
					{props.title}
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormGroup
							sx={{
								position: "relative",
							}}
							row
						>
							<TextField
								name="addPosition"
								autoComplete="false"
								type="text"
								label={props.label}
								fullWidth
								value={inputValue}
								onChange={handleChangeInput}
								variant="outlined"
								sx={{
									width: "100%",
								}}
							/>
							<Button
								variant="outlined"
								disableElevation
								onClick={handleAdd}
								sx={{
									position: "absolute",
									right: 0,
									height: "100%",
								}}
							>
								<AddIcon />
							</Button>
						</FormGroup>
					</Grid>
				</Grid>
			</Grid>
			{vacancyList.map((item, idx) => {
				return (
					<Grid key={idx} item xs={12}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								padding: ".1rem",
								marginBottom: 1,
								background: theme.palette.light.mainTransparent,
								color: theme.palette.dark.main,
								borderRadius: 1,
							}}
						>
							<Typography
								variant="body2"
								sx={{
									paddingLeft: 1,
								}}
							>
								{item}{" "}
							</Typography>
							<IconButton size="small">
								<DeleteOutline onClick={() => removeItem(item)} />
							</IconButton>
						</Stack>
					</Grid>
				);
			})}
		</Grid>
	);
};
