import { Box } from "@mui/material";
import { PieChart, pieArcClasses } from "@mui/x-charts";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { theme } from "../../../../theme/theme";

export const MainPieItem = (props) => {
	const mode = useSelector((state) => state.mode.value);
	const { data, data2, large } = props;
	const dat1 = data.map((i) => {
		return { ...i };
	});
	const dat2 = [data2];

	const [seriesOptions, setSeriesOptions] = useState({
		paddingAngle: 1,
		startAngle: -90,
		innerRadius: 20,
		cornerRadius: 8,
		outerRadius: 85,
		highlightScope: { faded: "global", highlighted: "item" },
		faded: { innerRadius: 40, additionalRadius: -30 },
	});

	const colorsArr = data.map((i) => {
		return i.color;
	});
	useEffect(() => {
		if (large === false) {
			setSeriesOptions({
				paddingAngle: 1,
				startAngle: -90,
				innerRadius: 40,
				cornerRadius: 5,
				outerRadius: 85,
				highlightScope: { faded: "global", highlighted: "item" },
				faded: { innerRadius: 40, additionalRadius: -30 },
			});
		}
	}, [large, data]);

	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			exit={{ opacity: 0, scale: 0.5 }}
			transition={{
				duration: 0.8,
				delay: 0.5,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			<Box
				sx={{
					background: mode === "light" && theme.palette.gray.mainTransparent,
				}}
			>
				<PieChart
					colors={colorsArr}
					series={[
						{
							...seriesOptions,
							data: dat1,
							paddingAngle: 3,
							outline: "none",
						},
						{
							innerRadius: 90,
							outerRadius: 110,
							data: dat2,
						},
					]}
					sx={{
						[`& .${pieArcClasses.faded}`]: {
							fill: "gray",
						},
						"--ChartsLegend-rootColor": "#ccc",
						"--ChartsLegend-rootOffsetX": "-70px",
						"--ChartsLegend-rootOffsetY": "-5px",
						"--ChartsLegend-itemWidth": "8px",
						"--ChartsLegend-labelSpacing": "5px",
						"--ChartsLegend-rootSpacing": "10px",
					}}
					width={500}
					height={300}
				/>
			</Box>
		</motion.div>
	);
};
