import { useTheme } from "@emotion/react";
import { Container, Paper, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import bgImage from "../assets/images/jpg/leoNew2.jpg";
import { theme } from "../theme/theme";

export const BgLeo = () => {
	const query = useTheme();
	const mobile = useMediaQuery(query.breakpoints.up("sm"));
	const mode = useSelector((state) => state.mode.value);
	return (
		<Container
			maxWidth="100wv"
			sx={{
				position: "absolute",
				top: 0,
				left: "50%",
				transform: "translate(-50%)",
				zIndex: -1,
			}}
		>
			<Paper
				elevation={10}
				sx={{
					width: "100%",
					position: "absolute",
					top: "0px",
					left: 0,
					height: mobile ? 400 : 500,
					backgroundImage: `url(${bgImage})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundColor: theme.palette.dark.main,
					boxShadow: `0px 3rem 3rem ${mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.main}`,
				}}
			>
			</Paper>
		</Container>
	);
};
