import { createTheme, outlinedInputClasses } from "@mui/material";
export const theme = createTheme({
	components: {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: "#F2EBE0",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& input": {
						color: "#ffffff",
					},
					"& label": {
						color: "#534451",
					},
					"& label.Mui-focused": {
						color: "#ffffff",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: "#534451",
				},
				root: {
					[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: "#242424",
					},
					[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: "#242424",
					},
					"& label.Mui-focused": {
						color: "#242424",
					},
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
				sx: {
					display: "flex",
					gap: 1,
				},
			},
		},
		MuiBadge: {
			defaultProps: {
				overlap: "circular",
			},
			styleOverrides: {
				badge: {
					backgroundColor: "#F2EBE0",
					color: "#7F056E",
					zIndex: 1500,
					borderRadius: 2,
				},
			},
		},
	},
	typography: {
		fontFamily: ["Popins", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
	},
	palette: {
		contrast: "#7F056E",
		darker: "#C09247",
		neutral: "#CAB387",
		background: {
			paper: "#f2ebe0",
		},
		js: {
			main: "#EFDA4F",
			mainTransparent: "#efda4f8a",
		},
		primary: {
			main: "#CAB387",
			mainTransparent: "rgba(202, 178, 135, 0.61)",
			text: "#CAB387",
		},
		secondary: {
			main: "#C09247",
			mainTransparent: "rgba(192, 146, 71, 0.5)",
			text: "#242424",
		},
		danger: {
			main: "#7F056E",
			mainTransparent: "rgba(127, 5, 111, 0.58)",
		},
		info: {
			main: "#24457d",
			mainTransparent: "rgba(36, 69, 125, 0.47)",
		},
		light: {
			main: "#f4f2f2",
			mainTransparent: "rgba(244, 242, 242, 0.56)",
			text: "#242424",
		},
		dark: {
			main: "#242424",
			mainTransparent: "rgba(36, 36, 36, 0.45)",
			text: "#f7f7f7",
		},
		gray: {
			main: "rgba(143,143, 143)",
			mainTransparent: "rgba(143,143, 143,  0.45)",
			text: "#f7f7f7",
		},
	},
});
