import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { OauthGoogleButton } from "./oauthGoogleButton";

export const OauthGoogleLogin = () => {
	return (
		<GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
			<OauthGoogleButton />
		</GoogleOAuthProvider>
	);
};
