import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formContainerState } from "../../../assets/dummyData/formContainerState";
import { createLoginLangs } from "../../../assets/dummyData/langy";
import { LoginForm } from "./loginForm";
import { OauthGoogleLogin } from "./oauthGoogleLogin";

export const FormContainer = () => {
	const [langs] = useState(createLoginLangs());
	const language = useSelector((state) => state.language.value);

	const [formTyp, setFormTyp] = useState("login");
	const [arrInputs] = useState(formContainerState);

	const handleChangeFormType = useCallback(() => {
		setFormTyp(formTyp === "login" ? "registration" : "login");
	}, [formTyp]);

	const reloadForm = useCallback(() => {
		return (
			<Grid
				container
				padding={3}
				spacing={3}
				sx={{
					height: "100%",
				}}
			>
				<Grid item xs={12}>
					<Stack
						direction="row"
						alignItems="start"
						justifyContent="space-between"
						sx={{
							position: "relative",
						}}
					>
						<Typography variant="h4">{formTyp === "login" ? langs.get("lang_login")[language].toLocaleUpperCase() : langs.get("lang_registration")[language].toLocaleUpperCase()}</Typography>
						<OauthGoogleLogin />
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<LoginForm formType={formTyp} arrInputs={arrInputs} />
				</Grid>
				<Grid item xs={12}>
					<Stack alignItems="end">
						<Button variant="text" color="danger" onClick={handleChangeFormType}>
							{formTyp === "login" ? langs.get("lang_registration")[language] : langs.get("lang_login")[language]}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		);
	}, [arrInputs, formTyp, handleChangeFormType, langs, language]);

	useEffect(() => {
		reloadForm();
	}, [reloadForm]);

	return reloadForm();
};
