import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Paper, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { htmlCanvasToPdf } from "../../assets/js/htmlCanvasToPdf";
import "../../assets/styles/css/stylesCv.module.scss";
import { CvAcordeon } from "./cvAcordeon";
import { ExResumeMob } from "./exResumeMob";
import { ResumeList } from "./resumeList";
import { theme } from "../../theme/theme";
import { useSelector } from "react-redux";

export const Cvpaper = (props) => {
	const { langs, language } = props;

	const [pdfButton, setPdfButton] = useState(false);
	const mode = useSelector((state) => state.mode.value);

	useEffect(() => {
		if (!pdfButton) return;
		setTimeout(() => {
			const element = document.getElementById("resume");
			const mob = document.getElementById("resumeMob");
			console.log(document.getElementById("resume").style.color)
			element.style.color = 'black'
			htmlCanvasToPdf(element, mob);
			setPdfButton(false);
		}, 1000);
	}, [pdfButton]);

	const exportToPDF = () => {
		setPdfButton(true);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Paper
					sx={{
						padding: 3,
					}}
				>
					<Stack justifyContent="end" direction="row" spacing={2}>
						<Button variant="outlined" color="dark" onClick={exportToPDF}>
							{langs.get("btnDownloadPdf")[language]} {pdfButton && <CircularProgress size={15} />}
						</Button>
						<Link to="/topics/projects" style={{ textDecoration: "none", color: "gray" }} color="secondary">
							<Button variant="outlined" color="dark">
								{langs.get("btnGoToProject")[language]}
							</Button>
						</Link>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<CvAcordeon langs={langs} language={language} />
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{
					background: mode ==='dark' ? theme.palette.primary.main : theme.palette.light.main
				}}>
					<ResumeList langs={langs} language={language} />
				</Paper>
			</Grid>
			<div
				style={{
					display: "none",
				}}
			>
				<ExResumeMob langs={langs} language={language} />
			</div>
		</Grid>
	);
};
