import { List } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { FavItemPlay } from "./favItemPlay";

export const FavoriteItemsList = () => {
	const favoriteList = useSelector((state) => state.music.favorite);

	return (
		!!favoriteList.length && (
			<List sx={{ width: "100%", maxWidth: 360, maxHeight: 400, overflow: "hidden", overflowY: "scroll" }}>
				{favoriteList.map((item, idx) => {
					return item.preview_url && <FavItemPlay key={idx} item={item} />;
				})}
			</List>
		)
	);
};
