import { Grid, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { cloneElement } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../theme/theme";

export const BurgerLink = (props) => {
	const { openBurger, open, data } = props;

	const lockIcon = useSelector((state) => state.lockIcon.value);
	const mode = useSelector((state) => state.mode.value);
	const location = useLocation();

	const getBtn = (item, cb) => {
		let updatedElement;
		if (location.pathname === item.url) {
			updatedElement = cloneElement(item.icon, { color: theme.palette.danger.main });
		}
		return (
			<ListItem disablePadding sx={{ display: "block" }} onClick={cb}>
				<ListItemButton
					component={Link}
					to={item.url}
					sx={{
						color: mode === "dark" ? theme.palette.primary.main : theme.palette.dark.main,
						minHeight: 48,
						justifyContent: open ? "initial" : "center",
						px: 2.5,
					}}
				>
					<Grid container alignItems="center">
						<Grid
							item
							xs
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : "auto",
									justifyContent: "center",
								}}
							>
								{location.pathname === item.url ? updatedElement : item.icon}
							</ListItemIcon>
							<ListItemText primary={item.text} />
						</Grid>
					</Grid>
				</ListItemButton>
			</ListItem>
		);
	};

	const renderBtn = (item) => {
		if (lockIcon === true && item.url === "/topics/login") return;
		if (lockIcon !== true && item.url === "/profile") return;

		return getBtn(item, () => openBurger(false));
	};

	return renderBtn(data);
};
