import { Paper, Stack, TextField, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { theme } from "../../../../theme/theme";
import { createFinanceLangs } from "../../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const InfoRezervaLeft = (props) => {
	const [langs] = useState(createFinanceLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("lang_more")[language]}
	const mode = useSelector((state) => state.mode.value);
	const { plat, setR, rezerva } = props;

	const handleChangeRezerva = (e) => {
		setR(e.target.value);
	};
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{
				duration: 0.8,
				delay: 0.5,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			<Paper
				p={2}
				sx={{
					background: mode === "dark" ? theme.palette.info.mainTransparent : theme.palette.gray.mainTransparent,
					color: theme.palette.info.main,
					padding: 2,
				}}
			>
				<Stack spacing={1}>
					<Typography variant="h5" color="text.light">
						{langs.get("infoTitle_reserve")[language]}
						<span
							style={{
								background: "#24457d",
								paddingInline: 10,
								borderRadius: 3,
								marginInline: 5,
								color: "white",
							}}
						>
							{plat * 6} €
						</span>
					</Typography>
					<TextField
						type="number"
						label={langs.get("actualState_reserve")[language]}
						value={rezerva}
						sx={{
							background: mode==='dark' && theme.palette.dark.mainTransparent,
						}}
						onChange={handleChangeRezerva}
					/>
				</Stack>
			</Paper>
		</motion.div>
	);
};
