import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: false,
};

export const userLoginBtnSlice = createSlice({
	name: "userLoginBtn",
	initialState,
	reducers: {
		setStateLoginBtn: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setStateLoginBtn } = userLoginBtnSlice.actions;

export default userLoginBtnSlice.reducer;
