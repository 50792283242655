import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as icon3 } from "../../assets/images/loga/mongo.svg";
import { ReactComponent as icon1 } from "../../assets/images/loga/node.svg";
import { ReactComponent as icon2 } from "../../assets/images/icons/react.svg";
import { IconComponent } from "../../assets/images/svg/iconComponent";
import { theme } from "../../theme/theme";

export const WelcomeText = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	return (
		<Container maxWidth="xl">
			<Grid container justifyContent="center" alignItems="center" spacing={tablet ? 4 : 1} p={tablet ? 3 : 1}>
				<Grid item xs="auto">
					<IconComponent class="svgShadow" src={icon1} size={tablet ? 12 : 5} color={tablet ? theme.palette.gray.main : theme.palette.light.main} />
				</Grid>
				<Grid item xs="auto">
					<IconComponent class="svgShadow" src={icon2} size={tablet ? 12 : 5} color={tablet ? theme.palette.gray.main : theme.palette.light.main} />
				</Grid>
				<Grid item xs="auto">
					<IconComponent class="svgShadow" src={icon3} size={tablet ? 17 : 10} color={tablet ? theme.palette.gray.main : theme.palette.light.main} />
				</Grid>
			</Grid>
		</Container>
	);
};
