import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../theme/theme";

export const NewsItem = (props) => {
	return (
		<motion.div
			whileHover={{ scale: 1 }}
			initial={{ opacity: 0, scale: 0.5 }}
			whileInView={{ opacity: 1, scale: 1 }}
			viewport={{ once: true }}
			transition={{
				duration: 1,
				delay: props.delay,
				ease: [0, 0.71, 0.2, 1],
			}}
		>
			<Typography
				component={Link}
				to={props.url ? props.url : "/topics"}
				variant="body2"
				noWrap
				color={props.url ? "text.light" : theme.palette.js.main}
				sx={{
					"&:hover": {
						color: "darker",
						fontWeight: 500,
					},
				}}
			>
				{props.title}
			</Typography>
		</motion.div>
	);
};
