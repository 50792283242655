export const skillData = [
	{ title: "React.js", skill: 100 },
	{ title: "React-Native", skill: 50 },
	{ title: "JavaScript", skill: 100 },
	{ title: "Rest API", skill: 100 },
	{ title: "SQL", skill: 50 },
	{ title: "Typescript", skill: 80 },
	{ title: "CSS", skill: 60 },
	{ title: "Sass", skill: 60 },
	{ title: "Bootstrap 5", skill: 100 },
	{ title: "JQuery", skill: 100 },
	{ title: "Material UI", skill: 100 },
	{ title: "Node.js", skill: 50 },
	{ title: "Express", skill: 50 },
	{ title: "HTML5", skill: 100 },
	{ title: "Git", skill: 80 },
	{ title: "Linux", skill: 60 },
	{ title: "Windows", skill: 100 },
	{ title: "Adobe", skill: 100 },
];