import { Button, Stack } from "@mui/material";
import React, { cloneElement } from "react";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../theme/theme";

export const MuiLink = (props) => {
	const location = useLocation();

	const getBtn = (item, optText = null) => {
		let updatedElement;
		if (location.pathname === item.url) {
			updatedElement = cloneElement(item.icon, { color: theme.palette.danger.main });
		}
		return (
			<Button component={Link} to={optText ? null : item.url} variant="text" size="small" color="dark">
				<Stack justifyContent="center" alignItems="center">
					{location.pathname === item.url ? updatedElement : item.icon}
					{item.text}
				</Stack>
			</Button>
		);
	};

	return getBtn(props.data);
};
