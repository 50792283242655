import { useTheme } from "@emotion/react";
import { Paper, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createWelcomeLangs } from "../../../assets/dummyData/langy";
import { theme } from "../../../theme/theme";
import { ContactForm } from "./contactForm";

export const ContactGrid = () => {
	const [langs] = useState(createWelcomeLangs());
	const language = useSelector((state) => state.language.value);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const mode = useSelector((state) => state.mode.value);
	return (
		<Paper
			elevation={10}
			className={mode === "light" ? "bg-gradient" : "bg-gradient-dark"}
			sx={{
				overflow: "hidden",
			}}
		>
			<div
				style={{
					position: "relative",
					zIndex: 1,
					width: "100%",
					height: "100%",
				}}
			>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100.1%",
						backgroundColor: mode === "light" ? theme.palette.secondary.main : theme.palette[mode].main,
						clipPath: "polygon(0 0, 60px 0, 290px 100%, 0 100%)",
						zIndex: -1,
					}}
				></div>
				<Typography
					align="center"
					variant={tablet ? "h2" : "h4"}
					color={theme.palette.light.main}
					fontWeight={900}
					sx={{
						textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
					}}
				>
					{langs.get("titleContact")[language]}
				</Typography>

				<ContactForm />
			</div>
		</Paper>
	);
};
