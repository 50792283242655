import React from "react";
import { useTheme } from "@emotion/react";
import { Container, useMediaQuery } from "@mui/material";
import { ContactGrid } from "./contactGrid";
import { motion } from "framer-motion";
import { theme } from "../../../theme/theme";
import { useSelector } from "react-redux";

export const ContactSection = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const mode = useSelector((state) => state.mode.value);
	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
				paddingTop: 15,
				backgroundColor: mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.main,
				clipPath: "polygon(100% 0%, 93% 0%, 0% 18%, 0% 100%, 100% 100%)",
				position: "relative",
				top: "-10rem",
			}}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				whileInView={{ opacity: 1, scale: 1 }}
				viewport={{ once: true }}
				transition={{
					duration: 0.4,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<ContactGrid />
			</motion.div>
		</Container>
	);
};
