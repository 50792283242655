import React from "react";
import { Box, Container, Divider, Grid, List, ListItemText, Typography } from "@mui/material";
import { ApexGraph } from "./apexGraph";
import { theme } from "../../theme/theme";

const experienceArr = [
	{ text: "Ticketportal - Frontend Developer", date: "11/2021 -", actual: true },
	{ text: "Unicorn - Full-stack Developer", date: "10/2021 - 11/2021", actual: false },
	{ text: "WEZEO | Hybrid Software Studio - Software Programmer", date: "9/2021 - 10/2021", actual: false },
	{ text: "Ringier axel springer - web-developer", date: "3/2021 - 7/2021", actual: false },
];

export const ResumeList = (props) => {
	const { langs, language } = props;

	return (
		<Container id="resume">
			<Grid color={theme.palette.dark.main} container gap={2} paddingTop={2}>
				<Grid item xs={12} md={3} align="center">
					<Box>
						<Typography variant="h5">Michal Krasňanský</Typography>
						<Typography gutterBottom variant="subtitle1">
							{langs.get("langDevJs")[language]}
						</Typography>
					</Box>
					<Divider />
					<Typography variant="h5">{langs.get("label_contactText")[language]}</Typography>
					<Box>
						<Typography variant="subtitle1">+421 905 225 236</Typography>
						<Typography gutterBottom variant="subtitle1">
							miso.krasnansky@gmail.com
						</Typography>
					</Box>
					<Divider />
					<Typography variant="h5">{langs.get("label_znalostiText")[language]}</Typography>
					<ApexGraph color="gray" />
				</Grid>
				<Grid item xs={12} md>
					<Container>
						<Typography gutterBottom variant="subtitle1">
							{langs.get("cvUvodText")[language]}
						</Typography>
						<Divider />
						<Typography gutterBottom variant="h6" fontWeight={400}>
							{langs.get("workExperienceTitle")[language]}
						</Typography>
						<List dense disablePadding>
							{experienceArr.map((item, idx) => {
								return (
									<ListItemText key={idx}>
										{item.text} ( {item.date} {item.actual && langs.get("label_actualJob")[language]} )
									</ListItemText>
								);
							})}
						</List>
						<Divider />
						<Typography gutterBottom variant="h6" fontWeight={400}>
							{langs.get("label_educationTitle")[language]}
						</Typography>
						<Typography gutterBottom variant="subtitle1">
							{langs.get("label_educationSubtitle")[language]}
						</Typography>
						<Divider />
						<Typography gutterBottom variant="h6" fontWeight={400}>
							{langs.get("label_courses")[language]}
						</Typography>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h7" fontWeight={300}>
									Udemy
								</Typography>
								<List
									dense
									disablePadding
									sx={{
										fontStyle: "italic",
									}}
								>
									<ListItemText>Complete Microsoft SQL Server Database Administration Course</ListItemText>
									<ListItemText>Complete C# Masterclass</ListItemText>
									<ListItemText>React Native (The Practical Guide 2023)</ListItemText>
									<ListItemText>Serverless Framework Bootcamp (Node.js, AWS & Microservices)</ListItemText>
									<ListItemText>Amazon Web Services (AWS) with JavaScript & NodeJS</ListItemText>
									<ListItemText>Complete Linux Training Course to Get Your Dream IT</ListItemText>
									<ListItemText>React - The Complete Guide (incl Hooks, React Router, Redux)</ListItemText>
									<ListItemText>Master NestJS - The JavaScript Node.js Framework</ListItemText>
									<ListItemText>Understanding TypeScript - 2021 Edition</ListItemText>
									<ListItemText>NestJS Zero to Hero - Modern TypeScript Back-end Development</ListItemText>
									<ListItemText>NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)</ListItemText>
									<ListItemText>The Complete Modern JavaScript Masterclass 2021</ListItemText>
									<ListItemText>Quasar V1: PWA (with Vue JS 2, Firebase, NodeJS & Express)</ListItemText>
									<ListItemText>Vuetify: Create an App with Vue JS & Vuex - in 5 Hours!</ListItemText>
									<ListItemText>Intro to Node JS & Express</ListItemText>
									<ListItemText>JavaScript DOM Dynamic Web interactive content Boot Camp</ListItemText>
								</List>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={6}>
										<Typography variant="h7" fontWeight={300}>
											Learn2code
										</Typography>
										<List
											dense
											disablePadding
											sx={{
												fontStyle: "italic",
											}}
										>
											<ListItemText>MongoDb</ListItemText>
											<ListItemText>Webrebel 1: HTML, CSS & JavaScript</ListItemText>
											<ListItemText>JavaScript & ES6</ListItemText>
											<ListItemText>React.js</ListItemText>
											<ListItemText>Angular 2+</ListItemText>
											<ListItemText>Vue.js + SPA</ListItemText>
											<ListItemText>Css preprocesory: Sass</ListItemText>
											<ListItemText>Git</ListItemText>
											<ListItemText>OOP</ListItemText>
											<ListItemText>Bootstrap</ListItemText>
											<ListItemText>Tailwind Css</ListItemText>
											<ListItemText>Adobe Illustrator, Photoshop</ListItemText>
										</List>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h7" fontWeight={300}>
											Vzhurudolu.cz
										</Typography>
										<List
											dense
											disablePadding
											sx={{
												fontStyle: "italic",
											}}
										>
											<ListItemText>Practical introduction to Vue & Vue 3 practically</ListItemText>
										</List>
										<Typography variant="h7" fontWeight={300}>
											Vue Mastery
										</Typography>
										<List
											dense
											disablePadding
											sx={{
												fontStyle: "italic",
											}}
										>
											<ListItemText>Intro to Vue 2 & Vue 3</ListItemText>
											<ListItemText>Real World Vue 3 & Tourin Vue Router</ListItemText>
											<ListItemText>Vue 3 Forms</ListItemText>
										</List>
										<Typography variant="h7" fontWeight={300}>
											ITnetwork.cz
										</Typography>

										<List
											dense
											disablePadding
											sx={{
												fontStyle: "italic",
											}}
										>
											<ListItemText>Introduction to OOP in JavaScript & jQuery</ListItemText>
										</List>
										<Divider />
										<List
											dense
											disablePadding
											sx={{
												fontStyle: "italic",
											}}
										>
											<ListItemText>{langs.get("label_engLanguage")[language]} B1</ListItemText>
											<ListItemText>{langs.get("label_diverlicense")[language]} B</ListItemText>
										</List>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>
		</Container>
	);
};
