import { ReactComponent as javascript } from "../images/loga/JavaScript.svg";
import { ReactComponent as vue } from "../images/loga/vue.svg";
import { ReactComponent as react } from "../images/loga/React.svg";
import { ReactComponent as nftIcon } from "../images/loga/nft.svg";
import { ReactComponent as nodeLogo } from "../images/loga/nodeLogoQuality.svg";
import { ReactComponent as oweeLogo } from "../images/loga/oweeLogo.svg";
import { ReactComponent as pepeLogo } from "../images/loga/pepeLight.svg";
import { ReactComponent as rmLogo } from "../images/loga/rmLogo.svg";
import itunes from "../images/projectImg/itunes.png";
import mealShop from "../images/projectImg/mealShop.png";
import nft from "../images/projectImg/nft.png";
import oldcv from "../images/projectImg/oldcv.png";
import owee from "../images/projectImg/owee.png";
import pepe from "../images/projectImg/pepe.png";
import rmgarden from "../images/projectImg/rmgarden.png";
import todo from "../images/projectImg/todo.png";
import { IconComponent } from "../images/svg/iconComponent";

export const sourceProjectsData = [
	{
		parentTitle: "React.js",
		parentIcon: <IconComponent src={react} size={6} color='black' />,
		projects: [
			{
				ptojectTitle: "Meal Shop",
				projectTextStack: ["React.js", "React Context", "CSS"],
				icon: "",
				projectData: {
					title: "Meal Shop",
					subtitle: "lang_subtitleMealShop",
					describe: "lang_describeMealShop",
					img: [mealShop],
					links: {
						github: "https://github.com/mkrasnansk/meal-shop",
						webSite: "https://meal-shop-react.netlify.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
			{
				ptojectTitle: "NFT",
				projectTextStack: ["React.js", "Material-UI"],
				icon: <IconComponent src={nftIcon} size={3} />,
				projectData: {
					title: "NFT",
					subtitle: "lang_subtitleNft",
					describe: "lang_describeNft",
					img: [nft],
					links: {
						github: "https://github.com/mkrasnansk/nft-pwa",
						webSite: "https://nft-pwa.netlify.app/",
					},
				},
				coming: true,
				alowComents: false,
			},
			{
				ptojectTitle: "Owee",
				projectTextStack: ["React.js"],
				icon: <IconComponent src={oweeLogo} size={3} />,
				projectData: {
					title: "owee.sk",
					subtitle: "lang_subtitleOwee",
					describe: "lang_describeOwee",
					img: [owee],
					links: {
						github: "https://github.com/mkrasnansk/ourweb",
						webSite: "https://owee-15664.web.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
			{
				ptojectTitle: "iTunes",
				projectTextStack: ["React.js"],
				icon: "",
				projectData: {
					title: "I-Tunes",
					subtitle: "lang_subtitleTunes",
					describe: "lang_describeTunes",
					img: [itunes],
					links: {
						github: "https://github.com/mkrasnansk/TS-REACT-itunes",
						webSite: "https://react-itunes-ts.netlify.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
			{
				ptojectTitle: "Pepe",
				projectTextStack: ["React.js"],
				icon: <IconComponent src={pepeLogo} size={3} color='white' />,
				projectData: {
					title: "Kadernictvo Pepe",
					subtitle: "lang_subtitlePepe",
					describe: "lang_describePepe",
					img: [pepe],
					links: {
						github: "https://github.com/mkrasnansk/pepe",
						webSite: "https://kadernictvo.netlify.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
			{
				ptojectTitle: "RmGarden",
				projectTextStack: ["React.js", "Typescript"],
				icon: <IconComponent src={rmLogo} color='white' size={3} />,
				projectData: {
					title: "RM Garden",
					subtitle: "lang_subtitleRm",
					describe: "lang_describeRm",
					img: [rmgarden],
					links: {
						github: "https://github.com/mkrasnansk/pepe",
						webSite: "https://rm-garden.netlify.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
			{
				ptojectTitle: "Old",
				projectTextStack: ["React.js", "Material-ui", "Typescript"],
				icon: "",
				projectData: {
					title: "Old Resume",
					subtitle: "lang_subtitleOld",
					describe: "lang_describeOld",
					img: [oldcv],
					links: {
						github: "https://github.com/mkrasnansk/krasnansky-react-cv",
						webSite: "https://michalkrasnansky.netlify.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
		],
	},
	{
		parentTitle: "Node.js",
		parentIcon: <IconComponent src={nodeLogo} size={3} />,
		projects: [
			// {
			// 	ptojectTitle: "NFT",
			// 	projectTextStack: ["React.js", "Material-UI"],
			// 	icon: "",
			// 	projectData: {
			// 		title: "Nft TITle",
			// 		subtitle: "NEJAKY POD nad pis",
			// 		describe: "texty a zvasty",
			// 		img: [empty],
			// 		links: {
			// 			github: "https://github.com/mkrasnansk/ourweb",
			// 			webSite: "https://owee-15664.web.app/",
			// 		},
			// 	},
			// 	coming: false,
			// 	alowComents: false,
			// },
		],
	},
	{
		parentTitle: "Vue.js",
		parentIcon: <IconComponent src={vue} size={5} />,
		projects: [
			{
				ptojectTitle: "ToDo",
				projectTextStack: ["React.js", "Material-UI"],
				icon: "",
				projectData: {
					title: "Todo app",
					subtitle: "lang_subtitleTodo",
					describe: "lang_describeTodo",
					img: [todo],
					links: {
						github: "https://github.com/mkrasnansk/todo2-vue-3",
						webSite: "https://to-do-vuetify.netlify.app/",
					},
				},
				coming: false,
				alowComents: false,
			},
		],
	},
	{
		parentTitle: "Other",
		parentIcon: <IconComponent src={javascript} size={3} />,
		projects: [
			//{
				// ptojectTitle: "ToDo",
				// projectTextStack: ["React.js", "Material-UI"],
				// icon: "",
				// projectData: {
				// 	title: "Todo app",
				// 	subtitle: "",
				// 	describe: "",
				// 	img: [todo],
				// 	links: {
				// 		github: "https://github.com/mkrasnansk/todo2-vue-3",
				// 		webSite: "https://to-do-vuetify.netlify.app/",
				// 	},
				// },
				// coming: false,
				// alowComents: false,
			//},
		],
	},
];
