import React, { useRef, useState } from "react";
import { createFormularLangs } from "../../../assets/dummyData/langy";
import { useSelector } from "react-redux";
import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import { CreateInput } from "../../../assets/js/formInputs";
import { SubmitContactBtn } from "../../home/contact/submitContactBtn";
import { updateForgotPwd } from "../../../assets/js/axiosCalls";
import { useNavigate } from "react-router-dom";

export const ForgotPwdCreate = (props) => {
	const [langs] = useState(createFormularLangs());
	const language = useSelector((state) => state.language.value);
	const navigate = useNavigate();

	const inputRef = useRef({});
	const [showLoading, setShowLoading] = useState(false);
	const [errSubmit, setErrSubmit] = useState(false);
	const [err, setErr] = useState(false);

	const handleChangeInputs = (resEvent, resName, errorInput) => {
		inputRef.current[resName] = errorInput ? "" : resEvent.target.value;
		return {};
	};

	const dummyInputData = [
		{
			col: "",
			name: "password",
			typ: "password",
			label: "new password",
			errBool: false,
			reqBool: true,
			cb: handleChangeInputs,
			help: "helpPaswdFrom",
			row: "",
		},
		{
			col: "",
			name: "repassword",
			typ: "password",
			label: "retype new password",
			errBool: false,
			reqBool: true,
			cb: handleChangeInputs,
			help: "",
			row: "",
		},
	];

	const handleSubmit = (e) => {
		e.preventDefault();
		let sendObj = inputRef.current;
		if (!sendObj.password || !sendObj.repassword) {
			setErr(true);
		} else {
			setErr(false);

			sendObj = { ...sendObj, token: props.token, user: props.user };
			setShowLoading(true);
			setErrSubmit(true);
			try {
				updateForgotPwd(sendObj)
					.then(() => {
						navigate("/topics/login");
					})
					.catch((err) => {
						console.log("catcherr ", err);
					});
			} catch {
				console.log(err);
				navigate("/topics/login");
			}
		}
	};

	return (
		<Box component="form" noValidate autoComplete="off">
			<Grid
				container
				justifyContent="center"
				spacing={3}
				sx={{
					padding: 3,
				}}
			>
				{dummyInputData.map((item, idx) => {
					return (
						<Grid key={idx} item xs={12} md={6}>
							<CreateInput data={item} />
						</Grid>
					);
				})}
				{props.user
					? err && (
							<Grid item xs={12} align="left">
								<Alert severity="error">
									<AlertTitle>{langs.get("paswdFrom")[language]}</AlertTitle>
									<Typography>{langs.get("helpPaswdFrom")[language]} </Typography>
								</Alert>
							</Grid>
					  )
					: err && (
							<Grid item xs={12} align="left">
								<Alert severity="error">
									<AlertTitle>{langs.get("errTitleFrom")[language]}</AlertTitle>
									<Typography>{langs.get("errRequiredMail")[language]} </Typography>
								</Alert>
							</Grid>
					  )}
				<Grid
					item
					xs={12}
					sx={{
						display: "flex",
						justifyContent: "end",
						width: "100%",
					}}
				>
					<SubmitContactBtn loading={showLoading} afterErrloading={errSubmit} cb={handleSubmit} text={"zmenit"} errMsg={err} w={1 / 3} />
				</Grid>
			</Grid>
		</Box>
	);
};
