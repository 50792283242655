import { useTheme } from "@emotion/react";
import { Card, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createTopicsLangs } from "../../../assets/dummyData/langy";
import { theme } from "../../../theme/theme";
import { FormIntroduceContainer } from "./formIntroduceContainer";

export const IntroduceContainer = () => {
	const [langs] = useState(createTopicsLangs());
	const language = useSelector((state) => state.language.value);
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const mode = useSelector((state) => state.mode.value);

	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{
					duration: 0.8,
					delay: 0.3,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							className={mode === "dark" ? "bg-gradient-dark" : "bg-gradient"}
							sx={{
								padding: 1,
								borderRadius: 1,
								textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
							}}
							align="center"
							color={mode === "dark" ? theme.palette.light.main : theme.palette.secondary.main}
							variant="h4"
						>
							{langs.get("introduceTitle")[language].toLocaleUpperCase() + ", " + langs.get("introduceSubTitle")[language].toLocaleUpperCase()}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Card className={mode === "dark" ? "bg-gradient-dark" : "bg-gradient"}>
							<FormIntroduceContainer />
						</Card>
					</Grid>
				</Grid>
			</motion.div>
		</Container>
	);
};
