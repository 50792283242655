import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createNavLangs } from "../../assets/dummyData/langy";

export const LinksFooter = () => {
	const [langs] = useState(createNavLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("titleSpecializtion")[language]}
	const initialLinks = [
		{ url: "/", text: langs.get("aboutme")[language] },
		{ url: "/topics", text: langs.get("topics")[language] },
		{ url: "/topics/projects", text: langs.get("lang_projectSectionTitle")[language] },
		{ url: "/topics/introduce", text: langs.get("introduceTitle")[language] },
		{ url: "/topics/apps", text: langs.get("appsTitle")[language] },
		{ url: "/cv", text: langs.get("cv")[language] },
		{
			url: "/topics/login",
			text: langs.get("login")[language],
		},
	];
	return (
		<Stack
			style={{
				height: "100%",
				marginRight: "1rem",
				minWidth: "10vw",
			}}
		>
			<Typography
				sx={{
					maxWidth: "13vw",
					whiteSpace: "wrap",
				}}
			>
				{initialLinks.map((item, idx) => {
					return (
						<Typography
							key={idx}
							component={Link}
							to={item.url}
							variant="body2"
							marginRight={1}
							color={"text.light"}
							sx={{
								"&:hover": {
									color: "text.dark",
								},
							}}
						>
							{item.text}
						</Typography>
					);
				})}
			</Typography>
		</Stack>
	);
};
