import { Paper, Typography } from "@mui/material";
import React from "react";

export const ObchodnePodmienky = () => {
	return (
		<Paper
			sx={{
				padding: 4,
			}}
		>
			<Typography variant="h4" fontWeight={900} align="center">
				Všeobecné obchodné podmienky
			</Typography>

			<Typography variant="h5" fontWeight={500}>
				1. Úvodné ustanovenia
			</Typography>
			<Typography>
				1.1. Poskytovateľom služieb online streamingu krasnansky online je spoločnosť krasnansky, s. r. o., IČO: , so sídlom Hodalova 10, 841 04 Bratislava, zapísaná v obchodnom registri Okresného súdu Bratislava I, Slovenskej
				Republiky, oddiel Sro, vložka 126527/B (ďalej len „poskytovateľ“ alebo „krasnansky“). Bankový účet poskytovateľa je: IBAN: ; DIC: . 1.2. krasnansky poskytuje službu online streamingu, umožňujúcom prezeranie inštruktážnych a
				cvičebných videí na požiadanie prostredníctvom siete internet (ďalej len „online streaming“ alebo „služba“). 1.3. Používateľom služby je fyzická alebo právnická osoba (ďalej len “používateľ”), ktorá sa registruje na
				webstránkach domény poskytovateľa krasnansky.org, ktorému poskytovateľ umožní sledovanie digitálneho obsahu bezplatne alebo za odplatu v zmysle týchto Všeobecných obchodných podmienok. 1.4. Všeobecné obchodné podmienky
				predstavujú súhrn práv a povinností poskytovateľa a používateľa (ďalej len „VOP“ alebo „podmienky používania“), s ktorými je používateľ povinný sa oboznámiť pred uzatvorením zmluvy a ktorými sa spravuje zmluvný vzťah medzi
				poskytovateľom a používateľom zmysle týchto VOP. 1.5. Poskytovateľ poskytuje svoje služby na doméne krasnansky.org (ďalej len „stránka“). 1.6. Používateľ získa po registrácii na stránkach poskytovateľa užívateľský účet, ku
				ktorému má prístup cez užívateľské rozhranie stránky poskytovateľa (ďalej len „používateľský účet“).
			</Typography>

			<Typography variant="h5" fontWeight={500}>
				2. Registrácia, uzatvorenie zmluvy a ukončenie zmluvy
			</Typography>
			<Typography>
				2.1. Potenciálny používateľ straší ako 18 rokov sa môže registrovať na stránkach poskytovateľa služby. Proces registrácie pozostáva z odoslania registračného formulára používateľom a následného potvrdenia o vytvorení účtu
				poskytovateľom. 2.2. Dokončením procesu registrácie je uzatvorená zmluva o poskytovaní služieb, ktorá sa uzatvára na dobu neurčitú. 2.3. Zaregistrovaním získava používateľ prístup k digitálnemu obsahu na stránkach
				poskytovateľa za podmienok uvedených v týchto VOP. 2.4. Používateľ sa môže kedykoľvek rozhodnúť zmluvu ukončiť a to buď adresovaním doporučeného listu do sídla poskytovateľa alebo využitím možnosti zrušenia účtu v užívateľskom
				rozhraní prístupnom po prihlásení na stránkach poskytovateľa. Zmluva bude ukončená k poslednému dňu zaplateného obdobia podľa bodu 4.1. týchto VOP; ak používateľ využíva služby bezplatne, zmluva je ukončená doručením výpovede
				alebo zrušením účtu podľa predchádzajúcej vety. Ukončenie zmluvy nezakladá právo na vrátenie odmeny za poskytovanie služieb alebo jej časti; odmena je spotrebovaná sprístupnením obsahu po dohodnuté obdobie. 2.5. Poskytovateľ
				môže zmluvu ukončiť okamžite v prípade, ak nadobudne dôvodné podozrenie, že používateľ porušil podmienky týchto VOP a to najmä vymazaním účtu používateľa. V takomto prípade poskytovateľ nie je povinný používateľovi vrátiť
				odmenu, ktorú si je oprávnený ponechať ako zmluvnú pokutu. 2.6. Poskytovateľ môže zmluvu ukončiť z dôvodov hodných osobitného zreteľa výpoveďou doručenou používateľovi prostredníctvom užívateľského rozhrania na stránkach
				poskytovateľa, e-mailom alebo iným vhodným spôsobom. Výpovedná lehota podľa tohto bodu je 14 dní.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				3. Digitálny obsah
			</Typography>

			<Typography>
				3.1. Digitálny obsah stránky je tvorený chránenými autorskými dielami, ktoré je na požiadanie možné použiť (prehrať, zobraziť) na podporovanom zariadení a softvéri za podmienok uvedených v týchto VOP (ďalej len „digitálny
				obsah“). Zoznam digitálneho obsahu je prístupný po registrácii používateľa. 3.2. Digitálny obsah je možné používať na akomkoľvek bežne dostupnom zariadení akým je počítač, smartfón alebo tablet s aktuálnou verziou webového
				prehliadača Microsoft Edge, Mozilla Firefox alebo Google Chrome. Digitálny obsah je prevažne vo formáte .mp4 a .mov. Formát digitálneho obsahu určuje poskytovateľ podľa svojho uváženia. 3.3. Používateľ berie na vedomie, že na
				používanie digitálneho obsahu je potrebné pripojenie k sieti internet, pričom minimálna rýchlosť pripojenia pre uspokojivú kvalitu obrazu bez prerušovania je 0,5 Mbps, avšak poskytovateľ odporúča zabezpečenie rýchlejšieho
				pripojenia. 3.4. Digitálny obsah je prístupný momentom registrácie používateľa v rozsahu bezplatného prístupu. Všetok digitálny obsah sa sprístupní po zaplatení odmeny v zmysle článku 4 týchto VOP. 3.5. Digitálny obsah môže
				byť chránený pred použitím neautorizovaným užívateľom, šifrovaním alebo iným vhodným zabezpečením. 3.6. Digitálny obsah je určený výhradne na osobné a nekomerčné používanie používateľom. 3.7. Digitálny obsah je neustále
				aktualizovaný, v čase sa mení, pričom autorské diela môžu byť pridané a/alebo vyňaté z digitálneho obsahu. Digitálny obsah bude tvorený spravidla minimálne troma autorskými dielami. 3.8. Poskytovateľ sa zaväzuje vyvinúť úsilie
				aby ním prevádzkovaná infraštruktúra k sprístupneniu digitálneho obsahu bola funkčná nepretržite, pričom sa bude snažiť zamedziť akýmkoľvek výpadkom. V prípade, že takýto výpadok nastane, kontaktujte prosím poskytovateľa na
				kontaktných adresách uvedených na stránkach poskytovateľa. Poskytovateľ sa zaväzuje vyvinúť úsilie aby bola dosiahnutá 98% dostupnosť k službám v sumári vždy za posledných 365 dní až do ukončenia poskytovania služieb.
				Poskytovateľ bude spravidla reagovať na výpadok poskytovania služby do 2 pracovných dní od oznámenia. 3.9. Používateľ nesmie využívať digitálny obsah na komerčné účely, šíriť ho alebo rozmnožovať ako aj ho nesmie využívať v
				rozpore so zákonmi, dobrými mravmi alebo týmito VOP.
			</Typography>

			<Typography variant="h5" fontWeight={500}>
				4. Odmena a platobné podmienky
			</Typography>

			<Typography>
				4.1. Za sprístupnenie a povolenie používania celého digitálneho obsahu a prevádzkovanie k tomu nevyhnutnej infraštruktúry sa platí odmena/poplatok (ďalej len „odmena“). Digitálny obsah bude sprístupnený bezodkladne po jej
				uhradení. 4.2. Odmena môže byť uhradená: 4.2.1. jednorazovo za vopred určenú dobu užívania služieb (ďalej len „jednorazová platba“); 4.2.2. formou pravidelnej platby za zvolený časový úsek (napr. mesačne, trojmesačne, ročne a
				pod.), ktorá bude hradená z platobného prostriedku v periodicite podľa vybraného časového úseku (ďalej len „pravidelná platba“). Prvá platba podľa tohto bodu sa uskutoční pri výbere formy pravidelnej platby. V prípade zmeny
				platobnej periodicity bude suma uhradená v posledný deň, kedy má používateľ zaplatený prístup k celému digitálnemu obsahu. Pravidelnú platbu je možné kedykoľvek zrušiť v používateľskom účte, pričom obsah bude prístupný do
				vypršania časového úseku za ktorý bola zaplatená. 4.3. Výška aktuálnej odmeny je uvedená na stránkach poskytovateľa. Poskytovateľ si vyhradzuje právo meniť odmenu v čase podľa svojej obchodnej politiky poskytovateľa, pričom
				tieto zmeny nemajú vplyv na už uhradenú odmenu ani na dĺžku ich poskytovania. O zmene výšky odmeny poskytovateľ používateľa vhodným spôsobom vopred upozorní. 4.4. Poskytovateľ podľa vlastnej úvahy sprístupní používateľom časť
				digitálneho obsahu, ktorú môžu používatelia používať bezplatne. 4.5. Odmena predstavuje odmenu/poplatok za vedenie infraštruktúry a sprístupnenie digitálneho obsahu používateľovi. Nevyužívanie digitálneho obsahu nemá za
				následok stratu nároku na odmenu poskytovateľa.4.3. Výška aktuálnej odmeny je uvedená na stránkach poskytovateľa. Poskytovateľ si vyhradzuje právo meniť odmenu v čase podľa svojej obchodnej politiky poskytovateľa, pričom tieto
				zmeny nemajú vplyv na už uhradenú odmenu ani na dĺžku ich poskytovania. O zmene výšky odmeny poskytovateľ používateľa vhodným spôsobom vopred upozorní. 4.4. Poskytovateľ podľa vlastnej úvahy sprístupní používateľom časť
				digitálneho obsahu, ktorú môžu používatelia používať bezplatne. 4.5. Odmena predstavuje odmenu/poplatok za vedenie infraštruktúry a sprístupnenie digitálneho obsahu používateľovi. Nevyužívanie digitálneho obsahu nemá za
				následok stratu nároku na odmenu poskytovateľa.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				5. Licenčné podmienky
			</Typography>
			<Typography>
				5.1. Poskytovateľ poskytuje používateľovi nevýhradné, neprevoditeľné právo na použitie digitálneho obsahu za odmenu podľa článku 4 týchto VOP. Licencia na používanie digitálneho obsahu sa udeľuje na prezeranie a zobrazovanie
				digitálneho obsahu na požiadanie a s tým súvisiace ukladanie to dočasnej pamäte podporovaného zariadenia používateľa. 5.2. V rozsahu tejto licencie nie je možné obsah stiahnuť za účelom neskoršieho použitia. 5.3. Licencia
				nepredstavuje prevod akýchkoľvek práv z poskytovateľa na používateľa. 5.4. Používateľ nesmie digitálny obsah ďalej predávať, prenajímať, požičiavať, distribuovať, vysielať, zverejňovať, sublicencovať, alebo inak ďalej šíriť.
				Používateľ nesmie digitálny obsah nijako meniť, modifikovať, znefunkčniť či odstraňovať ochranu na používanie digitálneho obsahu oprávnenými osobami.
			</Typography>

			<Typography variant="h5" fontWeight={500}>
				6. Odstúpenie od zmluvy uzatvorenej na diaľku
			</Typography>
			<Typography>
				6.1. Vzhľadom na charakter poskytovanej služby je nevyhnutné poskytovanie služby okamžite po registrácii. Poskytovateľ týmto upozorňuje používateľa, že dokončením procesu registrácie používateľ udeľuje súhlas na začatie
				poskytovania služby pred uplynutím 14dňovej lehoty na odstúpenie od zmluvy podľa zákona o ochrane spotrebiteľa pri poskytovaní služieb na základe zmluvy uzavretej na diaľku. Udelením takéhoto súhlasu používateľ stráca právo na
				odstúpenie od zmluvy do 14 dní podľa predchádzajúcej vety. Používateľ si je vedomý straty práva na odstúpenie od zmluvy pre začatie poskytovania služby pred uplynutím tejto lehoty a vyhlasuje, že bol o strate práva poučený a
				udeľuje súhlas s poskytovaním služieb pred uplynutím lehoty na odstúpenie od zmluvy.
			</Typography>

			<Typography variant="h5" fontWeight={500}>
				7. Ostatné vyhlásenia
			</Typography>
			<Typography>
				7.1. Poskytovateľ môže na základe preferencií používateľa odporúčať použitie niektorého digitálneho obsahu. Takéto odporúčanie nemá charakter poskytovania poradenských, zdravotných, rehabilitačných ani iných podobných aktivít.
				7.2. Poskytovateľ nenesie žiadnu zodpovednosť za odporúčania, výzvy, rady, alebo postupy obsiahnuté v digitálnom obsahu a ak sa používateľ rozhodne niektoré z činností vyobrazených v digitálnom obsahu sám uskutočniť, robí tak
				výlučne na vlastné riziko. 7.3. Poskytovateľ nie je zodpovedný za škodu, ktorá vznikne používateľovi v súvislosti s používaním elektronického/digitálneho obsahu. 7.4. Služby a elektronický/digitálny obsah sú poskytované v
				stave „ako stoja a ležia“, s prípadnými nedostatkami a bez poskytnutia osobitnej záruky.
			</Typography>

			<Typography variant="h5" fontWeight={500}>
				8. Reklamačné podmienky
			</Typography>
			<Typography>
				8.1 V prípade, že používateľ bude mať za to, že mu bola služba poskytnutá s vadou, môže používateľa kontaktovať na kontaktných adresách uvedených na stránkach poskytovateľa e-mailom alebo listom zaslaným poštou („reklamácia“).
				Poskytovateľ reklamáciu preskúma a o výsledku používateľa vyrozumie najneskôr do 30 dní odo dňa podania reklamácie, pokiaľ zákonná úprava neumožňuje vybaviť reklamáciu v dlhšej lehote. Ak poskytovateľ uzná reklamáciu ako
				oprávnenú, ponúkne používateľovi primeranú zľavu, prípadne inú vhodnú kompenzáciu k spokojnosti používateľa. 8.2 Používateľ berie na vedomie, že reklamovať je možné poskytované služby a prípadné nedostatky digitálnej
				infraštruktúry, nie však samotný digitálny obsah.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				9. Ochrana osobných údajov
			</Typography>
			<Typography>9.1. Kompletné informácie o ochrane osobných údajov nájdete na stránkach poskytovateľa alebo na webstránke: https://krasnansky.org/topics/login/terms</Typography>
			<Typography variant="h5" fontWeight={500}>
				10. Záverečné ustanovenia
			</Typography>
			<Typography>
				10.1. Používateľ je povinný uvádzať na svojom používateľskom účte pravdivé informácie a zabezpečiť aby tieto boli vždy aktuálne. 10.2. Poskytovateľ môže zasielať písomnosti používateľovi e-mailom alebo poštou na adresy uvedené
				v používateľskom účtu, alebo umiestnením elektronickej správy do schránky klienta v používateľskom účte. 10.3. Poskytovateľ je oprávnený kedykoľvek z dôvodov hodných osobitného zreteľa dočasne, či trvalo zmeniť rozsah alebo
				formu poskytovania služieb, či jednostranne zmeniť tieto VOP. O zmene podmienok zmluvy poskytovateľ používateľa upozorní vhodným spôsobom. Takáto zmena bude účinná voči používateľovi dňom oboznámenia sa s nimi, najneskôr však
				2 týždne po zverejnení na webovom sídle prevádzkovateľa. V prípade ak používateľ so zmenou nesúhlasí, môže od zmluvy odstúpiť v súlade s týmito VOP. 10.4. Vo veciach výslovne neupravených týmito VOP sa na vzájomné práva a
				povinnosti zmluvných strán primerane použijú predpisy Slovenskej republiky. 10.5. Akékoľvek spory vyvstávajúce z právnych vzťahov, na ktoré sa aplikujú tieto VOP sa riadia právom Slovenskej republiky a príslušnými súdmi na
				riešenie sporov sú všeobecné súdy Slovenskej republiky. 10.6. V prípade, ak používateľ, nie je spokojný so spôsobom vybavenia svojej žiadosti, reklamácie, alebo ak má za to, že poskytovateľ porušil jeho práva, môže sa obrátiť
				na poskytovateľa so žiadosťou o nápravu. Ak nebude žiadosť o nápravu vybavená k jeho spokojnosti, môže používateľ ktorý je spotrebiteľom podať návrh na začatie alternatívneho riešenia sporov podľa zákona č. 391/2015 Z.z.
				Príslušným subjektom je subjekt zapísaný v zozname subjektov alternatívneho riešenia sporov vedenom Ministerstvom hospodárstva Slovenskej republiky. Takýmto subjektom je napr. Slovenská obchodná inšpekcia, Prievozská 32, 827
				99 Bratislava, http://www.soi.sk. Prípadne môže postupovať podľa inštrukcií uvedených na webstránke: https://ec.europa.eu/consumers/odr/. Platné od: 17.8.2023
			</Typography>
		</Paper>
	);
};
