import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../theme/theme";
import { IconComponent } from "../assets/images/svg/iconComponent";
import { createOtherLangs } from "../assets/dummyData/langy";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { motion } from "framer-motion";

export const PrivateSectionAlert = () => {
	const [langs] = useState(createOtherLangs());
	const language = useSelector((state) => state.language.value);
	const navigate = useNavigate();
	const mode = useSelector((state) => state.mode.value);

	const handleGoLogin = () => {
		navigate("/topics/login");
	};
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{
				opacity: 1,
				scale: 1,
				transition: {
					opacity: {
						duration: 0.8,
						delay: 0.5,
						ease: [0, 0.71, 0.2, 1.01],
					},
				},
			}}
		>
			<Grid container justifyContent="center">
				<Grid
					item
					sx={{
						marginBottom: 1,
					}}
				>
					<Card
						sx={{
							background: mode === "dark" ? theme.palette.dark.mainTransparent : theme.palette.light.mainTransparent,
						}}
					>
						<CardContent align="center">
							<IconComponent src={LockIcon} size={3.5} color={theme.palette.danger.main} />
							<Typography
								variant="h3"
								color="text.light"
								sx={{
									maxWidth: 400,
									marginBottom: 2,
								}}
								fontWeight={900}
							>
								{langs.get("privatSectionMsgOther")[language]}
							</Typography>
							<Button
								variant="contained"
								color="danger"
								sx={{
									width: "100%",
									color: "white",
								}}
								onClick={handleGoLogin}
							>
								{langs.get("loginBtnOther")[language]}
							</Button>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</motion.div>
	);
};
