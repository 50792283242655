import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./features/dboStore/cvDataSlice";
import loginBtnReducer from "./features/topics/userLoginBtnSlice";
import lockIconReducer from "./features/topics/lockIconSlice";
import userNameReducer from "./features/topics/userNameSlice";
import languageReducer from "./features/language/globalLanguageSlice";
import themeModeReducer from "./features/themeMode/globalThemeModeSlice";
import profileReducer from "./features/profile/profileSlice";
import musicReducer from "./features/music/musicSlice";
import actualPlayReducer from "./features/music/actualPlaySlice";

export const store = configureStore({
	reducer: {
		data: dataReducer,
		userLoginBtn: loginBtnReducer,
		userName: userNameReducer,
		lockIcon: lockIconReducer,
		language: languageReducer,
		mode: themeModeReducer,
		profile: profileReducer,
		music: musicReducer,
		song: actualPlayReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
