import { useTheme } from "@emotion/react";
import { Container, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { Policy } from "./policy";
import { ObchodnePodmienky } from "./obchodnePodmienky";

export const PolicyContainer = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<Stack gap={2}>
				<Policy />
				<ObchodnePodmienky />
			</Stack>
		</Container>
	);
};
