import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createInfoLoginLangs } from "../../../assets/dummyData/langy";
import { Link } from "react-router-dom";

export const Information = () => {
	const [langs] = useState(createInfoLoginLangs());
	const language = useSelector((state) => state.language.value);

	return (
		<Box>
			<Grid container padding={3} spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4">{langs.get("lang_infoTitle")[language].toLocaleUpperCase()}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography mb={1} variant="body1">
						{langs.get("lang_info1")[language]}
					</Typography>
					<Typography variant="body1">{langs.get("lang_info2")[language]}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" gap={1}>
						<Button variant="contained" component={Link} to={'/topics/login/terms'} color="gray" fullWidth>
							{langs.get("lang_btnInfo")[language]}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};
