import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../../../theme/theme";
import { CreateInput } from "../../../../assets/js/formInputs";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

export const FirstInputs = (props) => {
	const mode = useSelector((state) => state.mode.value);
	const { plat, splatky, potraviny } = props;
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	return (
		<>
			{props.data.map((item, idx) => {
				return (
					<Grid key={idx} item xs={12} md={item.col ? item.col : true}>
						<Box
							sx={{
								background: mode === "dark" ? theme.palette.dark.main : theme.palette.info.main,
								padding: 2,
								borderRadius: 1,
								position: "relative",
							}}
						>
							<CreateInput data={item} />
							<Paper
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									position: "absolute",
									width: tablet ? 120 : 90,
									height: tablet ? 120 : 90,
									right: tablet ? 10 : 5,
									background: mode === "dark" ? theme.palette.dark.main : theme.palette.light.main,
									border: `5px solid ${item.name === "plat" ? theme.palette.info.main : item.name === "splatky" ? theme.palette.danger.main : theme.palette.gray.main}`,
									top: "50%",
									transform: "translate(0, -50%)",
									borderRadius: "2in",
								}}
							>
								<Typography
									variant={tablet ? "h4" : "h5"}
									fontWeight={700}
									sx={{
										paddingBottom: "3px",
										color: item.name === "plat" ? "text.info" : item.name === "splatky" ? "text.danger" : "text.gray",
									}}
								>
									{item.name === "plat" && plat}
									{item.name === "splatky" && splatky}
									{item.name === "potraviny" && potraviny}
								</Typography>
							</Paper>
						</Box>
					</Grid>
				);
			})}
		</>
	);
};
