import { Card, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSpotify } from "../../../../assets/js/axiosCalls";
import { setMusicToken } from "../../../../store/features/music/musicSlice";
import { FavoriteContainer } from "./musicComponents/favorite/favoriteContainer";
import { PlayListContainer } from "./musicComponents/list/playListContainer";
import { ContainerPlayer } from "./musicComponents/player/containerPlayer";
import { InputSearch } from "./musicComponents/player/inputSearch";

export const AppMusicContainer = () => {
	// const mode = useSelector((state) => state.mode.value);
	const dispatch = useDispatch();
	const tokenRedux = useSelector((state) => state.music.token);

	useEffect(() => {
		if (!Object.keys(tokenRedux).length) {
			authSpotify().then((e) => {
				dispatch(setMusicToken(e.data));
			});
		}
	}, [dispatch, tokenRedux]);

	return (
		<Container
			maxWidth="xl"
			sx={{
				position: "relative",
				height: "50vh",
			}}
		>
			<Card
				sx={{
					borderRadius: 2,
					// background: mode === "dark" ? theme.palette.dark.main : theme.palette.light.main,
					background: "linear-gradient(rgb(255, 38, 142) 0%, rgb(255, 105, 79) 100%)",
					"&:before": {
						content: '""',
						width: "100%",
						height: "100%",
						position: "absolute",
						zIndex: -1,
						background: "radial-gradient(at center center, rgb(62, 79, 249) 0%, rgba(62, 79, 249, 0) 64%)",
					},
				}}
			>
				<Grid container p={2} spacing={3} alignItems="center">
					<Grid item xs={12}>
						<InputSearch />
					</Grid>
					<Grid item xs>
						<PlayListContainer />
					</Grid>
					<Grid item xs>
						<ContainerPlayer />
					</Grid>
					<Grid item xs>
						<FavoriteContainer />
					</Grid>
				</Grid>
			</Card>
		</Container>
	);
};
