import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { theme } from "../../../../theme/theme";
import { createFinanceLangs } from "../../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const DescribeRightOstatne = (props) => {
	const [langs] = useState(createFinanceLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("lang_more")[language]}
	const mode = useSelector((state) => state.mode.value);
	const { dataList, setList, ostatne, setO } = props;

	const [item, setItem] = useState("");
	const [itemCost, setItemCost] = useState(0);
	const [total, setTotal] = useState(ostatne);

	useEffect(() => {
		if (dataList && dataList.length) {
			setList(dataList);
		}
	}, [dataList, setList]);

	const handleRemove = (e) => {
		let newDatalist = dataList.filter((item) => item.id !== e.id);
		setList(newDatalist);
		setO(total - e.suma);
		setTotal(total - e.suma);
	};
	const handleOnChangeList = () => {
		if (item !== "" && itemCost > 0) {
			setTotal(total + Number(itemCost));
			setList([...dataList, { id: (Math.random() * 1000).toFixed(0), nazov: item, suma: itemCost }]);

			let totalSum = 0;
			dataList.map((item) => (totalSum += item.suma));
			setTotal(totalSum + Number(itemCost));
			setO(total + Number(itemCost));

			setItem("");
			setItemCost(0);
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{
				duration: 0.8,
				delay: 0.5,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			<Paper
				p={2}
				sx={{
					background: mode === "dark" ? theme.palette.info.mainTransparent : theme.palette.gray.mainTransparent,
					color: theme.palette.info.main,
					padding: 2,
				}}
			>
				<Stack>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6" color="text.light">{langs.get("listOther_title")[language]} </Typography>
						</Grid>
						<Grid item xs={12} md={6} xl>
							<TextField
								variant="outlined"
								autoComplete="false"
								fullWidth
								type="text"
								value={item}
								onChange={(e) => setItem(e.target.value)}
								placeholder={langs.get("other_fee")[language]}
								InputProps={{
									style: {
										background: theme.palette.dark.mainTransparent,
										height: 33,
									},
								}}
								sx={{}}
							/>
						</Grid>
						<Grid item xs={12} md={6} xl="auto">
							<TextField
								fullWidth
								variant="outlined"
								autoComplete="false"
								type="number"
								placeholder="suma"
								value={itemCost}
								onChange={(e) => setItemCost(Number(e.target.value))}
								InputProps={{
									style: {
										background: theme.palette.dark.mainTransparent,
										height: 33,
									},
								}}
							/>
						</Grid>
						<Grid item xs={12} xl="auto">
							<Button
								onClick={handleOnChangeList}
								size="small"
								sx={{
									width: "100%",
									color: "white",
								}}
								color={mode === "dark" ? "light" : "info"}
								variant="contained"
							>
								{langs.get("btn_add")[language]}
							</Button>
						</Grid>
						<Grid item xs={12}>
							{dataList &&
								dataList.length > 0 &&
								dataList.map((item, idx) => {
									return (
										<Stack
											direction="column"
											mb={"2px"}
											key={idx}
											sx={{
												background: theme.palette.dark.mainTransparent,
												borderRadius: 1,
												color: theme.palette.light.main,
											}}
										>
											{item.suma > 0 && (
												<Grid container alignItems="center" px={1}>
													<Grid item xs>
														<Typography align="left" variant="body2">
															{item.nazov}
														</Typography>
													</Grid>
													<Grid item xs="auto">
														<Typography variant="body2">{item.suma} €</Typography>
													</Grid>
													<Grid item xs="auto">
														<IconButton aria-label="delete" size="small" color="danger" onClick={() => handleRemove(item)}>
															<DeleteIcon />
														</IconButton>
													</Grid>
												</Grid>
											)}
										</Stack>
									);
								})}
						</Grid>
						<Grid item xs={12}>
							<Stack
								direction="column"
								justifyContent="space-between"
								sx={{
									background: theme.palette.dark.main,
									borderRadius: 1,
									color: theme.palette.light.main,
								}}
							>
								<Stack direction="row" justifyContent="space-between" px={1}>
									<Typography variant="body2">{langs.get("lang_total")[language]}</Typography>
									<Typography variant="body2">{total} €</Typography>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Paper>
		</motion.div>
	);
};
