import { useTheme } from "@emotion/react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createProjectSectionLangs } from "../../../assets/dummyData/langy";
import { theme } from "../../../theme/theme";
import { ProjectGrid } from "./projectGrid";

export const ProjectContent = () => {
	const [langs] = useState(createProjectSectionLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography
						align="center"
						variant={tablet ? "h2" : "h4"}
						fontWeight={900}
						color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main}
						sx={{
							textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
						}}
					>
						{langs.get("lang_projectSectionTitle")[language]}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<ProjectGrid />
				</Grid>
			</Grid>
		</Container>
	);
};
