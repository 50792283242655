import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	list: [],
	favorite: [],
	token: {},
};

export const musicSlice = createSlice({
	name: "music",
	initialState,
	reducers: {
		setMusicList: (state, action) => {
			state.list = action.payload;
		},
		addMusicFavorite: (state, action) => {
			const addObj = action.payload;
			let has = state.favorite.find((e) => e.id === action.payload.id);
			if (!has) {
				state.favorite.push(addObj);
			}
		},
		deleteMusicFavorite: (state, action) => {
			if (!!state.favorite.length) {
				state.favorite = state.favorite.filter((e) => e.id !== action.payload.id);
			}
		},
		setMusicToken: (state, action) => {
			state.token = { ...state.token, ...action.payload };
		},
	},
});

export const { setMusicList, addMusicFavorite, deleteMusicFavorite, setMusicToken } = musicSlice.actions;

export default musicSlice.reducer;
