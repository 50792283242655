import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconComponent } from "../../../assets/images/svg/iconComponent";
import { theme } from "../../../theme/theme";

export const PhotoContainer = (props) => {
	const [obrazokSrc, setObrazokSrc] = useState("");
	const handleNacitajObr = () => {
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.click();

		input.addEventListener("change", (e) => {
			const file = e.target.files[0];
			const reader = new FileReader();

			reader.onload = () => {
				setObrazokSrc(reader.result);
				let out = setTimeout(() => {
					document.getElementById("imageProfile").style.backgroundImage = file;
					clearTimeout(out);
				}, 500);
				localStorage.setItem("profileImg", "");
			};
			reader.readAsDataURL(file);
		});
	};
	useEffect(() => {
		if (localStorage.getItem("profileImg")) setObrazokSrc(localStorage.getItem("profileImg"));
		localStorage.setItem("profileImg", obrazokSrc);
	}, [obrazokSrc]);

	return (
		<Box
			onClick={handleNacitajObr}
			sx={{
				borderRadius: "100px",
				border: obrazokSrc === "" && "2px dotted black",
				cursor: "pointer",
				width:200,
				height:200,
			}}
		>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="center"
				gap={1}
				sx={{
					height:200,
					width:200,
				}}
			>
				{obrazokSrc === "" && (
					<>
						<IconComponent src={AddAPhotoIcon} size={2} color={theme.palette.light.mainTransparent} />
						<Typography>{props.subtitle}</Typography>
					</>
				)}
				{obrazokSrc && obrazokSrc !== "" && (
					<img
						id="imageProfile"
						onError={null}
						style={{
							borderRadius: "100px",
							border: `3px solid ${theme.palette.light.main}`,
							width: "200px",
							height: "200px",
							backgroundImage: `url(${obrazokSrc})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}
						alt=" "
					/>
				)}
			</Stack>
		</Box>
	);
};
