import { DeleteProfileContainer } from "../../components/profile/deleteProfile/deleteProfileContainer";
import { EditProfileContainer } from "../../components/profile/editProfile/editProfileContainer";
import { AppsGrid } from "../../components/topics/apps/appsGrid";
import { FinanceManager } from "../../components/topics/apps/financeManager/financeManager";
import { MusicPlayer } from "../../components/topics/apps/music/musicPlayer";
import { IntroduceContainer } from "../../components/topics/introduce/introduceContainer";
import { ForgotPwdContainer } from "../../components/topics/login/forgotPwdContainer";
import { LoginContent } from "../../components/topics/login/loginContent";
import { PolicyContainer } from "../../components/topics/login/policy/policyContainer";
import { ProjectContent } from "../../components/topics/projects/projectContent";
import { AdminSite } from "../../pages/admin";
import { Curriculum } from "../../pages/curriculum";
import { Profile } from "../../pages/profile";
import { Topics } from "../../pages/topics";
import { WelcomePage } from "../../pages/welcomePage";

export const menuUrlLinks = [
	{ url: "/", text: "About me", component: <WelcomePage /> },
	{ url: "/profile", text: "Profile indo", component: <Profile /> },
	{ url: "/profile/edit", text: "Profile edit", component: <EditProfileContainer /> },
	{ url: "/profile/delete", text: "Profile delete", component: <DeleteProfileContainer /> },
	{ url: "/topics", text: "Topics", component: <Topics /> },
	{ url: "/topics/login", text: "Login", component: <LoginContent /> },
	{ url: "/topics/introduce", text: "Introduce", component: <IntroduceContainer /> },
	{ url: "/topics/login/terms", text: "Policy", component: <PolicyContainer /> },
	{ url: "/topics/projects", text: "Projects", component: <ProjectContent /> },
	{ url: "/cv", text: "CV", component: <Curriculum /> },
	{ url: "/admin/master", text: "Admin", component: <AdminSite /> },
	{ url: "/topics/apps", text: "Apps", component: <AppsGrid /> },
	{ url: "/topics/login/forgot", text: "Login", component: <ForgotPwdContainer /> },
	{ url: "/topics/apps/finance", text: "Finance manager", component: <FinanceManager /> },
	{ url: "/topics/apps/music", text: "Finance manager", component: <MusicPlayer /> },
];
