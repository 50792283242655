import React, { useCallback, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { IndexedDBManager } from "../../store/features/indexeddbstore/apiIndexeddb";
import { theme } from "../../theme/theme";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export const ApexGraph = (props) => {
	const init = useRef(false);
	const [state, setState] = useState([]);
	const mode = useSelector((state) => state.mode.value);

	const memoizedCallback = useCallback(async () => {
		const indexedDBManager = new IndexedDBManager();
		let localData = await indexedDBManager.getAllRecords();
		const loadData = await localData[0]?.skillData;
		if (loadData) {
			setState(() => {
				let data = loadData.map((i) => {
					let obj = {
						x: i.title,
						y: i.skill,
					};
					return obj;
				});
				return data;
			});
		} else if (state.length) {
			setTimeout(() => {
				window.location.reload();
			}, 5000);
		} else {
			setTimeout(() => {
				window.location.reload();
			}, 5000);
		}
	}, [state]);

	useEffect(() => {
		if (init.current) {
			return;
		}
		memoizedCallback();
		init.current = true;
	}, [init, memoizedCallback]);

	var options = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		tooltip: {
			enabled: false,
		},
		xaxis: {
			labels: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				show: !state.length ? false : true,
				style: {
					colors: props.color ? props.color : mode === 'dark'? theme.palette.primary.main: theme.palette.dark.main,
				},
			},
		},
		grid: {
			show: false,
		},
		plotOptions: {
			bar: {
				horizontal: true,
			},
		},

		colors: props.color ? [props.color] : [mode === 'dark'? theme.palette.primary.main: theme.palette.dark.main],
	};

	return (
		<div style={{ minHeight: "500px", minWidth: "90%", position: "relative" }}>
			{!state.length && (
				<CircularProgress
					size={24}
					sx={{
						color: theme.palette.dark.main,
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%,-50%)",
						zIndex: 2000,
					}}
				/>
			)}
			<Chart options={options} series={[{ data: state ?? state, name: "" }]} type="bar" height="100%" />
		</div>
	);
};
