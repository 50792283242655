import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as defIcon } from "../../../assets/images/loga/vscode.svg";
import { IconComponent } from "../../../assets/images/svg/iconComponent";
import { theme } from "../../../theme/theme";
import { ProjectModal } from "./projectModal";
import { useSelector } from "react-redux";

export const ItemProject = (props) => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const mode = useSelector((state) => state.mode.value);

	const [open, setOpen] = useState(false);
	const { icon, ptojectTitle } = props.data;
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModal = (e) => {
		setOpen(e);
	};

	return (
		<Grid item xs={"auto"} align="center">
			<IconButton onClick={handleClickOpen}>
				<Paper
					sx={{
						background: theme.palette.danger.main,
						padding: 2,
						minWidth: tablet ? 100 : 80,
						minHeight: tablet ? 100 : 80,
						borderRadius: "1in",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: "2px",
					}}
				>
					{icon !== "" ? icon : <IconComponent color={theme.palette.dark.mainTransparent} src={defIcon} size={3} />}
					<Typography variant="subtitle2" color={mode === "dark" ? theme.palette.dark.main : theme.palette.light.main}>
						{ptojectTitle}
					</Typography>
				</Paper>
			</IconButton>
			<ProjectModal open={open} cb={handleModal} data={props.data} />
		</Grid>
	);
};
