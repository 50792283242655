import { Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../../theme/theme";
import { useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

export const OblastCardItem = (props) => {
	const { title, text } = props.langs;
	const query = useTheme();
	const desk = useMediaQuery(query.breakpoints.up("lg"));
	const mode = useSelector((state) => state.mode.value);
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			whileInView={{ opacity: 1, scale: 1 }}
			viewport={{ once: true }}
			transition={{
				duration: 0.4,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			<Paper
				className={mode === "light" ? "bg-gradient" : "bg-gradient-dark"}
				elevation={10}
				sx={{
					padding: 2,
					height: "100%",
					display: "grid",
					alignContent: "center",
					justifyContent: "center",
				}}
			>
				<Typography
					gutterBottom
					color={theme.palette.js.main}
					variant="h5"
					fontWeight={700}
					sx={{
						textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
					}}
				>
					{title}
				</Typography>
				<Typography color={theme.palette.light.main} variant="subtitle1" fontStyle="italic" maxWidth={desk ? "auto" : 400}>
					{text}
				</Typography>
			</Paper>
		</motion.div>
	);
};
