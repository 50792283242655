import { Stack, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../theme/theme";

export const VacancyContainer = (props) => {
	return (
		<Stack direction="column" alignItems="center" mx={2} gap={1}>
			<Typography>{props.title}</Typography>
			{props.data && props.data.map((i, idx) => {
				return (
					<Typography
						color="text.light"
						sx={{
							background: theme.palette.secondary.main,
							paddingX: 2,
							borderRadius: 1,
							width: "100%",
						}}
						key={idx}
					>
						{i}
					</Typography>
				);
			})}
		</Stack>
	);
};
