import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React, { useState } from "react";

//TODO OSEFOVAT VSETKY TIPY INPUTOV

export const CreateInput = (props) => {
	const { name, typ, label, cb, errBool, reqBool, help, row, clas = null, val = null } = props.data;
	const [err, setErr] = useState(false);
	const [visiblePaswd, setVisiblePaswd] = useState(false);
	const handleChange = (e) => {
		let errorInput = false;
		if (typ === "number") {
			cb && cb(e.target.value);
		} else if (typ === "password") {
			if (name === "password") {
				let pwdTest = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
				if (pwdTest.test(e.target.value) === true) {
					setErr(false);
				} else {
					setErr(true);
					errorInput = true;
				}
			} else {
				if (e.target.value === document.getElementsByName("password")[0].value) {
					setErr(false);
				} else {
					setErr(true);
					errorInput = true;
				}
			}
			cb && cb(e, name, errorInput);
		} else {
			if (name === "mail") {
				let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
				if (emailReg.test(e.target.value) === true) {
					setErr(false);
				} else {
					setErr(true);
					errorInput = true;
				}
			} else {
				if (e.target.value.length < 2) {
					setErr(true);
					errorInput = true;
				} else {
					setErr(false);
				}
			}

			cb && cb(e, name, errorInput);
		}
	};
	return (
		<>
			{typ === "password" ? (
				<div
					style={{
						position: "relative",
						background: "gray",
						zIndex: 2000,
					}}
				>
					<IconButton
						onClick={() => setVisiblePaswd(!visiblePaswd)}
						sx={{
							position: "absolute",
							right: 10,
							bottom: "-3rem",
						}}
						aria-label="toggle password visibility"
					>
						{visiblePaswd ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</div>
			) : (
				""
			)}
			<TextField
				className={clas ? clas : ""}
				rows={row}
				name={name}
				multiline={row ? true : false}
				defaultValue={val === 0 ? null : val}
				placeholder={val === 0 ? '0' : null}
				autoComplete="false"
				fullWidth
				type={visiblePaswd ? "text" : typ}
				error={err || errBool}
				label={label}
				onChange={handleChange}
				helperText={help}
				required={reqBool}
			/>
		</>
	);
};
