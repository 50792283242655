import { useTheme } from "@emotion/react";
import LoginIcon from "@mui/icons-material/Login";
import { Button, Divider, Drawer, Grid, List, ListItem, ListItemButton, Typography, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOtherLangs } from "../assets/dummyData/langy";
import { IconComponent } from "../assets/images/svg/iconComponent";
import { ThemeSwitchButton } from "../components/themeSwitchButton";
import { setLanguage } from "../store/features/language/globalLanguageSlice";
import { theme } from "../theme/theme";
import { BurgerLink } from "./burgerLink";

export const BurgerMenu = (props) => {
	const { data } = props;

	const [langs] = useState(createOtherLangs());
	const language = useSelector((state) => state.language.value);
	const lockIcon = useSelector((state) => state.lockIcon.value);
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const handleOpenDrawer = (boo) => setIsOpen(boo);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const changeLangs = () => {
		dispatch(setLanguage(language === "sk" ? "eng" : "sk"));
		localStorage.setItem("language", language === "sk" ? "eng" : "sk");
	};
	useEffect(() => {
		data.map((item) => {
			if (item.url === "/topics/login") {
				if (lockIcon === true) {
					item.icon = <IconComponent src={LoginIcon} size={3.5} color={theme.palette.danger.main} />;
				} else {
					item.icon = <IconComponent src={LoginIcon} size={3.5} color={theme.palette.dark.main} />;
				}
			}
			return item;
		});
	}, [data, lockIcon]);

	return (
		<Grid container justifyContent="end">
			<Grid item>
				<MenuButton
					isOpen={isOpen}
					onClick={() => setIsOpen(true)}
					strokeWidth="5"
					color={theme.palette.light.main}
					lineProps={{ strokeLinecap: "round" }}
					transition={{ type: "spring", stiffness: 260, damping: 20 }}
					width="30"
					height="20"
					style={{
						padding: "1rem",
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<Drawer
					sx={{
						zIndex: 2000,
					}}
					anchor="top"
					open={isOpen}
					onClose={() => setIsOpen(false)}
				>
					<List>
						<ListItem disablePadding sx={{ display: "block" }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									px: 2.5,
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									cursor: "auto",
								}}
							>
								<Button
									onClick={changeLangs}
									size="small"
									sx={{
										border: (theme) => `1px solid ${theme.palette.divider}`,
										borderRadius: 1,
										color: "text.dark",
									}}
								>
									<Typography mx={tablet && 1} fontWeight={language === "eng" && 900} color={language === "eng" && theme.palette.danger.main}>
										{langs.get("langLang")["eng"]}
									</Typography>
									<Divider orientation="vertical" flexItem />
									<Typography mx={tablet && 1} fontWeight={language === "sk" && 900} color={language === "sk" && theme.palette.danger.main}>
										{langs.get("langLang")["sk"]}
									</Typography>
								</Button>
								<ThemeSwitchButton />
							</ListItemButton>
						</ListItem>
						{data.map((item, index) => {
							return <BurgerLink open={isOpen} openBurger={handleOpenDrawer} data={item} key={index} />;
						})}
					</List>
				</Drawer>
			</Grid>
		</Grid>
	);
};

const MenuButton = ({ isOpen = false, width = 35, height = 20, strokeWidth = 4, color = "#000", transition = null, lineProps = null, ...props }) => {
	lineProps = {
		stroke: color,
		strokeWidth: strokeWidth,
		vectorEffect: "non-scaling-stroke",
		transition,
		...lineProps,
	};
	const unitHeight = 4;
	const unitWidth = (unitHeight * width) / height;

	const item = {
		hidden: { y: 20, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	};

	const container = {
		hidden: { opacity: 1, scale: 0 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				delayChildren: 1,
				staggerChildren: 0.3,
			},
		},
	};

	return (
		<motion.svg viewBox={`0 0 ${unitWidth} ${unitHeight}`} variants={container} initial="hidden" animate="visible" overflow="visible" preserveAspectRatio="none" width={width} height={height} {...props}>
			{[0, 2, 4].map((index) => (
				<motion.line key={index} className='svgShadow' x1="0" x2={unitWidth} y1={index} y2={index} variants={item} {...lineProps} />
			))}
		</motion.svg>
	);
};
