import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { skillData } from "../../assets/dummyData/skillData";
import { ApexGraph } from "./apexGraph";
import { TrueStory } from "./trueStory";

export const SideContainer = (props) => {
	const { langs, language } = props;

	return (
		<Stack spacing={2}>
			<Paper
				align="center"
				sx={{
					padding: 2,
				}}
			>
				<Typography variant="h5">{langs.get("langCv")[language]}</Typography>
				<Typography variant="h7">{langs.get("langDevJs")[language]}</Typography>
			</Paper>
			<Paper>
				<ApexGraph data={skillData} />
			</Paper>
			<Paper
				align="center"
				sx={{
					padding: 2,
				}}
			>
				<TrueStory langs={langs} language={language} />
			</Paper>
		</Stack>
	);
};
