import { Button, Grid } from "@mui/material";
import React from "react";
import { RatingCards } from "./ratingCards";

export const CardTopBar = (props) => {
	return (
		<Grid container alignItems="center" justifyContent='space-between' p={1} sx={{
        }}>
			<Grid
				item
				sx={{
					display: "flex",
					gap: 1,
					height: "100%",
				}}
			>
				{props.data.buttons.map((btn, idxbtn) => {
					return (
						<Button disabled variant="contained" key={idxbtn} size="small" color="light">
							{btn}
						</Button>
					);
				})}
			</Grid>
			<Grid item >
				<RatingCards label={props.data.cardName} rate={props.data.rating}/>
			</Grid>
		</Grid>
	);
};
