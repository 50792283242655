import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOtherLangs } from "../assets/dummyData/langy";
import { ThemeSwitchButton } from "../components/themeSwitchButton";
import { setLanguage } from "../store/features/language/globalLanguageSlice";
import { theme } from "../theme/theme";
import { MuiLink } from "./muiLink";

export const LinksRouter = (props) => {
	const [langs] = useState(createOtherLangs());
	const language = useSelector((state) => state.language.value);
	const dispatch = useDispatch();
	const lockIcon = useSelector((state) => state.lockIcon.value);

	const changeLangs = useCallback(() => {
		dispatch(setLanguage(language === "sk" ? "eng" : "sk"));
		localStorage.setItem("language", language === "sk" ? "eng" : "sk");
	}, [dispatch, language]);

	const renderIconsMenu = useCallback(() => {
		return (
			<Grid
				spacing={2}
				container
				alignItems="center"
				className="bg-gradient"
				justifyContent="end"
				sx={{
					marginTop: 1,
					borderRadius: 1,
					boxShadow: 1,
					paddingRight: 3,
				}}
			>
				{props.data.map((item, key) => {
					if (lockIcon === true && item.url === "/topics/login") return null;
					if (lockIcon !== true && item.url === "/profile") return null;
					return (
						<Grid
							item
							key={key}
							xs="auto"
							sx={{
								paddingBottom: 1,
							}}
						>
							<MuiLink data={item} color={"primary"} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [props, lockIcon]);
	return (
		<Grid container spacing={2} alignItems="center" justifyContent="end">
			<Grid item>{renderIconsMenu()}</Grid>
			<Grid item>
				<Stack
					className="bg-gradient"
					p={1}
					sx={{
						borderRadius: 1,
						marginTop: 1,
					}}
				>
					<ThemeSwitchButton />
					<Button variant="contained" size="small" color="contrast" onClick={changeLangs}>
						<Stack>
							<Typography color={language === "eng" && theme.palette.danger.main} fontWeight={language === "eng" && 900}>
								{langs.get("langLang")["eng"]}
							</Typography>
							<Divider />
							<Typography color={language === "sk" && theme.palette.danger.main} fontWeight={language === "sk" && 900}>
								{langs.get("langLang")["sk"]}
							</Typography>
						</Stack>
					</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};
