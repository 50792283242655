import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createFormularLangs } from "../../../assets/dummyData/langy";
import { getFinanceData, submitForm } from "../../../assets/js/axiosCalls";
import { setStateLockIcon } from "../../../store/features/topics/lockIconSlice";
import { setUserName } from "../../../store/features/topics/userNameSlice";
import { SubmitButton } from "./submitButton";
import { setFinanceData } from "../../../store/features/profile/profileSlice";

export const LoginForm = (props) => {
	const [langs] = useState(createFormularLangs());
	const language = useSelector((state) => state.language.value);
	const { arrInputs, formType } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [visiblePaswd, setVisiblePaswd] = useState(false);
	const [loading, setLoading] = useState(false);
	const [afterSuccessloading, setAfterSuccessloading] = useState(false);
	const [afterErrloading, setAfterErrloading] = useState(false);
	const firstName = useRef();
	const lastName = useRef();
	const email = useRef();
	const passwdRef = useRef();
	const repasswdRef = useRef();
	const [policy, setPolicy] = useState(false);
	const [err, setErr] = useState();
	const [isSubmited, setIsSubmited] = useState(false);
	const handleValidPasswd = (e) => {
		passwdRef.current.value = e;
		handleNewStateInputs();
	};
	const handleRePasswd = (e) => {
		repasswdRef.current.value = e;
		handleNewStateInputs();
	};

	const handleEmail = (e) => {
		email.current.value = e;
		handleNewStateInputs();
	};
	const changeInputs = arrInputs.map((item) => {
		if (item.id === "meno") {
			item.ref = firstName;
			item.cb = (e) => (firstName.current.value = e.target.value);
		} else if (item.id === "priezvisko") {
			item.ref = lastName;
			item.cb = (e) => (lastName.current.value = e.target.value);
		} else if (item.id === "email") {
			item.cb = (e) => handleEmail(e.target.value);
			item.ref = email;
		} else if (item.id === "heslo") {
			item.cb = (e) => handleValidPasswd(e.target.value);
			item.ref = passwdRef;
		} else if (item.id === "reheslo") {
			item.cb = (e) => handleRePasswd(e.target.value);
			item.ref = repasswdRef;
		} else if (item.id === "policy") {
			item.cb = (e) => setPolicy(e.target.checked);
		}
		return item;
	});

	const [stateImputs, setStateImputs] = useState(changeInputs);
	const handleNewStateInputs = (e) => {
		const regPasswd = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
		const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		const newState = stateImputs.map((i) => {
			if (i.id === "heslo") {
				return { ...i, err: i.id === "heslo" && regPasswd.test(passwdRef?.current?.value || "") === false ? true : false };
			} else if (i.id === "reheslo") {
				return { ...i, err: i.id === "reheslo" && passwdRef?.current?.value !== repasswdRef?.current?.value ? true : false };
			} else if (i.id === "email") {
				return { ...i, err: i.id === "email" ? !emailReg.test(email?.current?.value || "") : i.err };
			} else {
				return i;
			}
		});
		setStateImputs(newState);
	};
	const createInputByTyp = (typ, item, idx) => {
		if (item.hide && formType === "login") return null;
		let input;
		if (typ === "text" || typ === "password") {
			input = (
				<Grid key={idx} item xs={12}>
					{typ === "password" ? (
						<div
							style={{
								position: "relative",
								background: "gray",
								zIndex: 2000,
							}}
						>
							<IconButton
								onClick={() => setVisiblePaswd(!visiblePaswd)}
								sx={{
									position: "absolute",
									right: 10,
									bottom: "-3rem",
								}}
								aria-label="toggle password visibility"
							>
								{visiblePaswd ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</div>
					) : (
						""
					)}
					<TextField
						sx={{
							width: "100%",
						}}
						autoComplete="false"
						type={visiblePaswd ? "text" : typ}
						error={item.err}
						id={item.id}
						label={item.err ? (langs.get(item.errMsg) ? langs.get(item.errMsg)[language] : "") : langs.get(item.label)[language]}
						onChange={item.cb}
						ref={item.ref}
						helperText={formType !== "login" ? (langs.get(item.helperText) ? langs.get(item.helperText)[language] : "") : ""}
						required
					/>
					{typ === "password" && formType === "login" && (
						<Button variant="text" component={Link} to="/topics/login/forgot" color="danger">
							<Typography
								variant="subtitle2"
								sx={{
									textAlign: "right",
									width: "100%",
								}}
							>
								{langs.get("btnforgotPwd")[language]}
							</Typography>
						</Button>
					)}
				</Grid>
			);
		} else if (typ === "checkbox") {
			input = (
				<Grid key={idx} item xs={12}>
					<FormControlLabel control={<Switch name={item.id} checked={policy} onChange={item.cb} color="danger" />} label={langs.get(item.label)[language]} />
				</Grid>
			);
		}
		return input;
	};

	const createFormInputs = (obj) => obj.map((item, idx) => createInputByTyp(item.typ, item, idx));

	const handleSubmit = (e) => {
		e.preventDefault();
		sessionStorage.removeItem("token");
		dispatch(setStateLockIcon(false));
		setAfterErrloading(false);
		setAfterSuccessloading(false);
		setLoading(true);
		setIsSubmited(true);

		const firstN = firstName?.current?.value || "";
		const lastN = lastName?.current?.value || "";
		const emailN = email?.current?.value || "";
		const passN = passwdRef?.current?.value || "";
		const repassN = repasswdRef?.current?.value || "";
		const policyN = policy;

		const regPasswd = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
		const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		const body =
			formType === "login"
				? {
						email: { msg: "errLang_msgEmail", value: emailN, valid: emailReg.test(emailN) },
						pass: { msg: "errLang_msgPaswd", value: passN, valid: regPasswd.test(passN) },
				  }
				: {
						firstName: { msg: "errLang_msgFirst", value: firstN, valid: firstN.length > 2 },
						lastName: { msg: "Last name is not valid!", value: lastN, valid: lastN.length > 2 },
						email: { msg: "errLang_msgEmail", value: emailN, valid: emailReg.test(emailN) },
						pass: { msg: "errLang_msgPaswd", value: passN, valid: regPasswd.test(passN) },
						repass: { msg: "errLang_msgRePaswd", value: repassN, valid: repassN === passN },
						policy: { msg: "errLang_msgTerms", value: policyN, valid: policyN !== false },
				  };

		let timeouts;
		const errors = Object.values(body)
			.map((item) => (item.valid ? null : item))
			.filter((i) => i !== null);
		if (errors.length) {
			setErr(errors);
			timeouts = setTimeout(() => {
				setLoading(false);
			}, 1000);
		} else {
			setErr("");
			try {
				submitForm(formType, body)
					.then((res) => {
						if (res.status && (res.status === 200 || res.status === 204)) {
							dispatch(setStateLockIcon(true));
							try {
								getFinanceData(emailN)
									.then((res) => {
										dispatch(setFinanceData(res.data.data.data));
									})
									.catch((err) => {
									});
							} catch {
								console.log(" err FIN CONTAINER");
							}
							timeouts = setTimeout(() => {
								setLoading(false);
								setAfterSuccessloading(true);
								dispatch(setUserName(emailN));

								clearTimeout(timeouts);
								navigate("/topics");
							}, 1000);
						} else if (res.status === 409) {
							timeouts = setTimeout(() => {
								setLoading(false);
								setAfterErrloading(true);
								setErr([{ msg: "errLang_msgConflict" }]);
							}, 1000);
							setTimeout(() => {
								timeouts = setAfterErrloading(false);
								clearTimeout(timeouts);
							}, 4000);
						}
					})
					.catch((error) => {
						if (typeof error === "number") {
							dispatch(setStateLockIcon(false));
						}
						timeouts = setTimeout(() => {
							setLoading(false);
						}, 1000);
						if (error === 409) {
							timeouts = setTimeout(() => {
								setAfterErrloading(true);
								setErr([{ msg: "errLang_msgConflict" }]);
							}, 1000);
							setTimeout(() => {
								timeouts = setAfterErrloading(false);
								clearTimeout(timeouts);
							}, 4000);
						} else if (error === 401) {
							timeouts = setTimeout(() => {
								setAfterErrloading(true);
								setErr([{ msg: "errLang_msgBadMailOrPass" }]);
							}, 1000);
							setTimeout(() => {
								timeouts = setAfterErrloading(false);
								clearTimeout(timeouts);
							}, 4000);
						}
						if (error === (500 || 501)) {
							setLoading(false);
							setAfterErrloading(true);
							setErr([{ msg: "errLang_msgServerErr" }]);
							return error;
						}
					});
			} catch (error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		if (formType) {
			setIsSubmited(false);
		}
	}, [formType]);

	return (
		<Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
			<Grid container spacing={3}>
				{createFormInputs(stateImputs)}
				{err && isSubmited && (
					<Grid item xs={12}>
						<Alert severity="error">
							<AlertTitle>{langs.get("errTitleFrom")[language]}</AlertTitle>
							{err.map((item, idx) => {
								return <Typography key={idx}>{langs.get(item.msg) ? langs.get(item.msg)[language] : item.msg}</Typography>;
							})}
						</Alert>
					</Grid>
				)}

				<SubmitButton langs={langs} language={language} formType={formType} loading={loading} afterSuccessloading={afterSuccessloading} afterErrloading={afterErrloading} />
			</Grid>
		</Box>
	);
};
