import { outlinedInputClasses } from "@mui/material";

export const getDesignTokens = (mode) => ({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			xxl: 2200,
		},
	},
	components: {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: "#F2EBE0",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& input": {
						color: "#ffffff",
					},
					"& label": {
						color: "#ffffff",
					},
					"& label.Mui-focused": {
						color: "#ffffff",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					background: "rgba(143,143, 143,  0.15)",
				},
				root: {
					[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: "#242424",
					},
					[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: "#242424",
					},
					"& label.Mui-focused": {
						color: "#242424",
					},
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
				sx: {
					display: "flex",
					gap: 1,
				},
			},
		},
		MuiBadge: {
			defaultProps: {
				overlap: "circular",
			},
			styleOverrides: {
				badge: {
					backgroundColor: "#F2EBE0",
					color: "#7F056E",
					zIndex: 1500,
					borderRadius: 2,
				},
			},
		},
	},
	typography: {
		fontFamily: ["Popins", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
	},
	palette: {
		mode,
		...(mode === "light"
			? {
					contrast: "#7F056E",
					darker: "#151414",
					neutral: "#8F8F8F",
					background: {
						paper: "#CAB387",
					},
					js: {
						main: "#EFDA4F",
						mainTransparent: "#efda4f8a",
					},
					primary: {
						main: "#CAB387",
						mainTransparent: "rgba(202, 178, 135, 0.61)",
					},
					secondary: {
						main: "#C09247",
						mainTransparent: "rgba(192, 146, 71, 0.5)",
					},
					danger: {
						main: "#7F056E",
						mainTransparent: "#7f056fb8",
					},
					info: {
						main: "#24457d",
						mainTransparent: "rgba(36, 69, 125, 0.47)",
					},
					light: {
						main: "#F7F7F7",
						mainTransparent: "rgba(244, 242, 242, 0.56)",
					},
					dark: {
						main: "#242424",
						mainTransparent: "rgba(36, 36, 36, 0.45)",
					},
					gray: {
						main: "#8F8F8F",
						mainTransparent: "rgba(143,143, 143,  0.45)",
					},
					text: {
						primary: "#F7F7F7",
						secondary: "#C09247",
						danger: "#7F056E",
						info: "#24457d",
						light: "#242424",
						dark: "#F7F7F7",
						gray: "#8F8F8F",
					},
			  }
			: {
					contrast: "#7F056E",
					darker: "#fff",
					neutral: "#8F8F8F",
					background: {
						paper: "#242424",
					},
					js: {
						main: "#EFDA4F",
						mainTransparent: "#efda4f8a",
					},
					primary: {
						main: "#CAB387",
						mainTransparent: "rgba(202, 178, 135, 0.61)",
					},
					secondary: {
						main: "#C09247",
						mainTransparent: "rgba(192, 146, 71, 0.5)",
					},
					danger: {
						main: "#7F056E",
						mainTransparent: "#7f056fb8",
					},
					info: {
						main: "#24457d",
						mainTransparent: "rgba(36, 69, 125, 0.47)",
					},
					dark: {
						main: "#F7F7F7",
						mainTransparent: "rgba(244, 242, 242, 0.56)",
					},
					light: {
						main: "#242424",
						mainTransparent: "rgba(36, 36, 36, 0.45)",
					},
					gray: {
						main: "#8F8F8F",
						mainTransparent: "rgba(143,143, 143,  0.45)",
					},
					text: {
						primary: "#CAB387",
						secondary: "#C09247",
						danger: "#7F056E",
						info: "#24457d",
						dark: "#242424",
						light: "#F7F7F7",
						gray: "#8F8F8F",
					},
			  }),
	},
});
