export const engBenefits = [
	"Coffee",
	"Parking",
	"13th salary",
	"Multisport card",
	"Team buildings",
	"Medical coverage",
	"Dental insurance",
	"Vision insurance",
	"Life insurance policies",
	"Prescription and pharmacy benefits",
	"Specialist services",
	"Mental health coverage",
	"Retirement planning",
	"Paid time off",
	"Paid vacation time",
	"Paid sick leave",
	"Extended leave",
	"Family leave",
	"Disability benefits",
	"Workers compensation",
	"Living stipends",
	"Student loan repayment",
	"College grants and scholarships",
	"Paid training and development",
	"Continuing education allowances",
	"Travel and spending expenses",
	"Company equipment",
	"Company transportation",
	"Remote work flexibility",
	"Investment opportunities",
];
export const skBenefits = [
	"Káva",
	"Parkovisko",
	"13. plat",
	"Multisport karta",
	"Team buildings",
	"Lekárske pokrytie",
	"Zubné poistenie",
	"Poistenie zraku",
	"Životné poistky",
	"Predpis a výhody lekárne",
	"Špecializované služby",
	"Pokrytie duševného zdravia",
	"Plánovanie odchodu do dôchodku",
	"Platené voľno",
	"Platený čas dovolenky",
	"Platená nemocenská dovolenka",
	"Predĺžená dovolenka",
	"Rodinná dovolenka",
	"Dávky v invalidite",
	"Odškodnenie zamestnancov",
	"Finančnú podpora",
	"Splácanie študentskej pôžičky",
	"Vysokoškolské granty a štipendiá",
	"Platené školenia a rozvoj",
	"Príspevky na ďalšie vzdelávanie",
	"Cestovné a výdavky",
	"Vybavenie firmy",
	"Firemná doprava",
	"Flexibilita práce na diaľku",
	"Investičné príležitosti",
];
