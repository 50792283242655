import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../../theme/theme";
import { AboutCardItem } from "./aboutCardItem";
import { aboutMeData } from "../../../assets/dummyData/homeData";
import { createWelcomeLangs } from "../../../assets/dummyData/langy";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

export const AboutMeSection = () => {
	const [langs] = useState(createWelcomeLangs());
	const language = useSelector((state) => state.language.value);

	const query = useTheme();
	const mobil = useMediaQuery(query.breakpoints.up("sm"));
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const desk = useMediaQuery(query.breakpoints.up("xl"));

	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
				marginTop: mobil ? "4rem" : "",
			}}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				whileInView={{ opacity: 1, scale: 1 }}
				viewport={{ once: true }}
				transition={{
					duration: 0.4,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<Grid
					container
					justifyContent="center"
					alignItems="end"
					sx={{
						alignContent: "center",
						marginBottom: 3,
					}}
				>
					<Grid
						item
						xs="auto"
						sx={{
							borderRadius: "10px 10px 0px 10px",
							width: "auto",
							padding: 1,
							background: theme.palette.dark.main,
						}}
					>
						<Typography variant={tablet ? "h2" : "h4"} fontWeight={900} align="center" color={theme.palette.light.main}>
							{langs.get("titleAbout")[language]}
						</Typography>
					</Grid>
					<Grid
						item
						xs="auto"
						sx={{
							borderRadius: "0px 10px 10px 0px",
							padding: 1,
							width: "auto",
							background: theme.palette.dark.main,
							height: "100%",
							position: "relative",
						}}
					>
						<div
							style={{
								position: "absolute",
								top: -5,
								left: -5,
								width: 10,
								height: 10,
								borderRadius: "1in",
								boxShadow: ` inset .05in -5px ${theme.palette.dark.main}`,
							}}
						></div>
						<Typography
							variant="h5"
							style={{
								background: `-webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, #EFDA4F 22%, #EFDA4F 80%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%)`,
								WebkitBackgroundClip: "text",
								WebkitTextFillColor: "transparent",
								marginBottom: tablet ? 10 : 0,
								marginLeft: 0,
							}}
						>
							{langs.get("subAbout")[language]}
						</Typography>
					</Grid>
				</Grid>
			</motion.div>
			<Grid
				container
				spacing={4}
				align={desk ? "" : "center"}
				color={theme.palette.dark.main}
				sx={{
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{aboutMeData.map((item, idx) => {
					return (
						<Grid
							key={idx}
							item
							xs={12}
							xl={4}
							sx={{
								height: "100%",
							}}
						>
							<motion.div
								initial={{ opacity: 0, scale: 0.5 }}
								whileInView={{ opacity: 1, scale: 1 }}
								viewport={{ once: true }}
								transition={{
									duration: 0.4,
									ease: [0, 0.71, 0.2, 1.01],
								}}
							>
								<AboutCardItem image={item.imgSrc} subtitle={langs.get(item.subText)[language]} text={langs.get(item.text)[language]} imgSize={mobil ? 10 : 8} />
							</motion.div>
						</Grid>
					);
				})}
				<Grid item xs={12} align="center" sx={{}}>
					<Typography
						align="center"
						variant={desk ? "subtitle1" : "subtitle2"}
						fontWeight={400}
						color={theme.palette.light.main}
						sx={{
							width: desk ? "70%" : "100%",
							textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
						}}
					>
						{langs.get("subWelcome")[language]}
					</Typography>
				</Grid>
			</Grid>
		</Container>
	);
};
