import React, { useState } from "react";
import { Grid } from "@mui/material";
import { sourceProjectsData } from "../../../assets/dummyData/projectsData";
import { ParentProjectAcordeon } from "./parentProjectAcordeon";

export const ProjectGrid = () => {
	const [projectData] = useState(sourceProjectsData);
	const [opened, setOpened] = useState([
		{ open: false, idx: 0 },
		{ open: false, idx: 1 },
		{ open: false, idx: 2 },
		{ open: false, idx: 3 },
	]);

	const hadleOpenChilds = (e, idx) => {
		let newState = opened.map((item) => {
			if (item.idx === idx) {
				return { open: e, idx: idx };
			} else {
				return { open: false, idx: item.idx };
			}
		});
		setOpened(newState);
	};

	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			{projectData.map((item, idx) => {
				if (item.projects.length) {
					return (
						<Grid key={idx} item xs="auto">
							<ParentProjectAcordeon index={idx} open={opened[idx].open} data={item} cbOpen={hadleOpenChilds} />
						</Grid>
					);
				} else {
					return null;
				}
			})}
		</Grid>
	);
};
