import React, { useRef, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Alert, AlertTitle, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { createFormularLangs } from "../../../assets/dummyData/langy";
import { sendMail } from "../../../assets/js/axiosCalls";
import { CreateInput } from "../../../assets/js/formInputs";
import { theme } from "../../../theme/theme";
import { SubmitContactBtn } from "./submitContactBtn";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

export const ContactForm = (props) => {
	const [langs] = useState(createFormularLangs());
	const language = useSelector((state) => state.language.value);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const inputRef = useRef({});
	const [err, setErr] = useState(false);
	const [showForm, setShowForm] = useState(true);
	const [reSubmit, setReSubmit] = useState({ init: false, msg: "" });

	const [showLoading, setShowLoading] = useState(false);
	const [errSubmit, setErrSubmit] = useState(false);

	const returnHandleBoxPath = (succes) => {
		let newObj;
		if (succes === true) {
			newObj = { init: true, bg: theme.palette.success.main };
		} else {
			newObj = { init: true, bg: theme.palette.danger.main };
		}
		props.boxColorPath(newObj);
	};

	// TODO  HANDLOVANIE SUBMITU
	const handleSubmit = (e) => {
		e.preventDefault();
		let ipDevice = window.location.host;

		let localipDevice = localStorage.getItem("ipDevice");
		let actualDate = new Date();
		let fiveMinuteLater = new Date(actualDate.getTime() + 5 * 60000);
		let storeThisIp;

		if (!localipDevice) {
			storeThisIp = {
				refreshTime: fiveMinuteLater,
				ip: ipDevice,
			};
		} else {
			let checkIp = JSON.parse(localipDevice);
			if (new Date(checkIp.refreshTime).getTime() > actualDate.getTime()) {
				setReSubmit({ init: true, msg: langs.get("errSendMoreThanOneFrom")[language] });
				setShowForm(false);
				returnHandleBoxPath(false);
				return;
			} else {
				storeThisIp = {
					refreshTime: fiveMinuteLater,
					ip: ipDevice,
				};
			}
		}

		setErrSubmit(false);
		setShowLoading(true);

		let sendObj = inputRef.current;

		if (!sendObj.mail || !sendObj.message) {
			setErr(true);
		} else {
			setErr(false);
			try {
				sendMail(sendObj)
					.then((res) => {
						if (res === 500 || res === 400) throw new Error("ERR CONNECTION");
						setShowLoading(false);
						setShowForm(false);
						returnHandleBoxPath(true);

						localStorage.setItem("ipDevice", JSON.stringify(storeThisIp));
						return res;
					})
					.catch((err) => {
						setShowLoading(false);
						setErrSubmit(true);
						console.log(err, "FRONT");
					});
			} catch (error) {
				setShowLoading(false);
				setErrSubmit(true);
				console.log(err, "FRONT TRY");
			}
		}
	};

	const handleChangeInputs = (resEvent, resName, errorInput) => {
		inputRef.current[resName] = errorInput ? "" : resEvent.target.value;
		return {};
	};

	const dummyInputData = [
		{
			col: 4,
			name: "fname",
			typ: "text",
			label: langs.get("firstNameFrom")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
		},
		{
			col: 4,
			name: "lname",
			typ: "text",
			label: langs.get("lastNameFrom")[language],
			errBool: false,
			reqBool: false,
			cb: handleChangeInputs,
			help: "",
			row: "",
		},
		{
			col: 4,
			name: "mail",
			typ: "email",
			label: langs.get("emailFrom")[language],
			errBool: false,
			reqBool: true,
			cb: handleChangeInputs,
			help: "",
			row: "",
			require: true,
		},
		{
			col: "",
			name: "message",
			typ: "area",
			label: langs.get("msgFrom")[language],
			errBool: false,
			reqBool: true,
			cb: handleChangeInputs,
			help: "",
			row: 4,
			clas: 'textAreaStyle'
		},
	];
	return (
		<>
			{showForm && (
				<Box component="form" noValidate autoComplete="off">
					<Grid
						container
						justifyContent="center"
						spacing={3}
						sx={{
							padding: 3,
						}}
					>
						{dummyInputData.map((item, idx) => {
							return (
								<Grid key={idx} item xs={12} md={item.col ? item.col : true}>
									<CreateInput data={item} />
								</Grid>
							);
						})}

						{err && (
							<Grid item xs={12} align="left">
								<Alert severity="error">
									<AlertTitle>{langs.get("errTitleFrom")[language]}</AlertTitle>
									<Typography>{langs.get("errRequiredMailAndMsgFrom")[language]} </Typography>
								</Alert>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "end",
								width: "100%",
							}}
						>
							<SubmitContactBtn loading={showLoading} afterErrloading={errSubmit} cb={handleSubmit} text={langs.get("btnSendMsgFrom")[language]} errMsg={err} />
						</Grid>
					</Grid>
				</Box>
			)}
			{!showForm && (
				<Box
					sx={{
						minHeight: "220px",
					}}
				>
					<Grid
						container
						justifyContent="center"
						sx={{
							marginTop: 4,
							height: "100%",
						}}
					>
						<Grid item xs={12} align="center">
							<Typography
								variant="h5"
								align="center"
								color={theme.palette.light.main}
								sx={{
									width: tablet ? "50%" : "80%",
									textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
								}}
							>
								{reSubmit.init === true ? reSubmit.msg : langs.get("successSendMsgFrom")[language]}
							</Typography>
						</Grid>
						<Grid item xs={12} align="center">
							{reSubmit.init === false ? (
								<CheckIcon
									sx={{
										fontSize: "5rem",
										color: theme.palette.light.main,
									}}
								/>
							) : (
								<ErrorOutlineIcon
									sx={{
										marginTop: "1rem",
										fontSize: "4rem",
										color: theme.palette.light.main,
									}}
								/>
							)}
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	);
};
