import { Paper, Typography } from "@mui/material";
import React from "react";

export const Policy = () => {
	return (
		<Paper
			sx={{
				padding: 4,
			}}
		>
			<Typography variant="h4" fontWeight={900} align="center">
				Ochrana osobných údajov
			</Typography>
			<Typography>
				Záleží nám na našich klientoch a ich súkromí. V tomto dokumente sa dozviete ako nakladáme s Vašimi osobnými údajmi: ako ich získavame, na čo ich používame, s kým ich zdieľame, ako ich chránime a tiež právach, ktoré v
				súvislosti so spracúvaním osobných údajov máte. Prevádzkovateľom webovej stránky https://krasnansky.org a zároveň prevádzkovateľom v zmysle legislatívy o ochrane osobných údajov je spoločnosť krasnansky, s. r. o., Hodalova 10,
				841 04 Bratislava, spoločnosť zapísaná Obchodnom registri okresného súdu Bratislava I, oddiel Sro, vložka. 126527/B. V prípade akýchkoľvek otázok ohľadne ochrany osobných údajov, nás môžete kontaktovať na
				miso.krasnansky@gmail.com
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				AKÉ ÚDAJE ZBIERAME A AKO S NIMI NAKLADÁME
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Registrácia
			</Typography>
			<Typography>
				Aby ste si mohli prezerať digitálny obsah na našej webovej stránke, je potrebné sa zaregistrovať. Pri registrácii od Vás získavame Vaše kontaktné údaje v rozsahu registračného formuláru (meno, priezvisko, e-mailová adresa) a
				tieto ďalej spracúvame spoločne s údajmi o Vami sledovaných videách za účelom poskytovania našich služieb, nášho neustáleho zlepšovania a na zmluvnom právnom základe. Informácie vedúce k lepšiemu odporúčaniu videí Aby bol pre
				Vás zážitok z prezerania obsahu na našich webových stránkach čo najlepší, môžeme od Vás žiadať poskytnutie doplňujúcich informácii, za účelom lepšej personalizácie odporúčaných videí. Poskytnutie týchto informácii je
				dobrovoľné, ich neposkytnutie neovplyvňuje základnú kvalitu poskytovania našich služieb.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Kontaktný formulár
			</Typography>
			<Typography>
				V prípade ak nám zašlete akékoľvek Vaše otázky prostredníctvom nášho kontaktného formuláru alebo aj priamo na naše kontaktné emailové adresy spracúvame Vaše kontaktné osobné údaje a akékoľvek ďalšie osobné údaje, ktoré sa
				rozhodnete o sebe poskytnúť za účelom zodpovedania Vášho dotazu a na základe nášho oprávneného záujmu.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Newsletter
			</Typography>
			<Typography>
				V prípade, ak ste nám udelili súhlas, spracúvame Vaše kontaktné údaje za účelom zasielania Vám nášho newslettru o novinkách, pripravovaných akciách a špeciálnych ponukách. Váš súhlas je dobrovoľný a môžete ho kedykoľvek
				odvolať čo však nemá vplyv na zákonnosť spracúvania pred jeho odvolaním. Z každého newslettru sa môžete jednoducho odhlásiť prostredníctvom funkcie „odhlásiť sa“ / „unsubscribe“ v päte e-mailu, alebo v používateľskom rozhraní
				dostupnom po prihlásení.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Cookies
			</Typography>
			<Typography>
				Súbory cookies sú malé kúsky kódu, ktoré nám umožňujú zapamätať si vaše úkony a preferencie (napríklad prihlasovacie údaje) v priebehu určitého časového obdobia, aby ste ich nemuseli opakovane zadávať vždy, keď sa vrátite na
				webovú stránku alebo prechádzate z jednej webovej stránky na druhú. Cookies nám zároveň pomáhajú pochopiť, čo vás zaujíma na našej webovej stránke, či je pre vás dostatočne prehľadná a viete na nej nájsť to, čo hľadáte.
				Cookies teda používame tiež za účelom zlepšenia užívateľského zážitku. Na využívanie funkčných a analytických cookies nie je nevyhnutné, aby ste nám na ich uloženie dali súhlas. V poslednom rade používame cookies na účely
				marketingu v prípadoch, kedy nám to povolíte. Ak nechcete, aby sa cookies ukladali do Vášho zariadenia, máte možnosť zmeniť si nastavenia Vášho prehliadača alebo použiť niektoré z dostupných softwarových riešení, ktoré blokujú
				ukladanie cookies do Vášho zariadenia.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				TRETIE STRANY
			</Typography>
			<Typography>Vaše osobné údaje sa neposkituju tretim stranám.</Typography>
			<Typography variant="h5" fontWeight={500}>
				DOBA UCHOVANIA
			</Typography>
			<Typography>
				Osobné údaje, ktoré súvisia s Vašou registráciou a následným prezeraním digitálneho obsahu a s tým súvisiacimi bezpečnostnými opatrenia pre zabránenie neoprávneného prístupu uchovávame, kým máte u nás zriadenú registráciu. V
				prípade, ak Vaše osobné údaje spracúvame na základe Vášho súhlasu, spracúvame ich dokým súhlas neodvoláte. Lehoty spracúvania pri Cookies sa riadia pravidlami spoločnosti Google a Facebook. Prosím zoberte na vedomie, že
				niektoré Vaše osobné údaje môžeme uchovávať aj po dlhšiu dobu, a to najmä osobné údaje, ktoré sú súčasťou nášho účtovníctva alebo nám ich uchovávanie ukladajú osobitné právne predpisy.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				PRENOS DO TRETÍCH KRAJÍN
			</Typography>
			<Typography>
				Vaše osobné údaje aktívne do tretích krajín mimo EÚ neprenášame, k ich prenosu do tretích krajín však môže prísť prostredníctvom partnerov, ktorých služby využívame (Google/Facebook), a to konkrétne do USA. Títo partneri
				dodržiavajú podmienky US-EU Privacy Shield a tým poskytujú primeranú úroveň ochrany pre vaše osobné údaje.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				AKO VAŠE ÚDAJE CHRÁNIME
			</Typography>
			<Typography>
				V súlade s požiadavkami platnej legislatívy vykonávame všetky potrebné bezpečnostné, technické a organizačné opatrenia, aby sme Vaše osobné údaje chránili. Elektronické údaje sú uchovávané v chránených databázach na serveroch
				našich partnerov, kde sú pred ich poškodením, zničením, stratou a zneužitím. Všetky osoby, ktoré prichádzajú do styku s osobnými údajmi klientov majú povinnosť dodržiavať mlčanlivosť o informáciách získaných v súvislosti so
				spracovávaním týchto údajov.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				AKÉ SÚ VAŠE PRÁVA
			</Typography>
			<Typography>
				V prípade, ak si uplatníte akékoľvek z nižšie uvedených práv, budeme Vás o vybavení Vašej žiadosti informovať do 30 dní odo dňa jej doručenia k nám. V odôvodených prípadoch môžeme uvedenú lehotu predĺžiť na 60 dní, o čom Vás
				budeme informovať. Ak si chcete Vaše práva uplatniť prosím, kontaktujte nás na denisa.owen@do-pilates.sk.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Právo na prístup k údajom
			</Typography>
			<Typography>
				Máte právo získať od nás potvrdenie o tom, či sa o Vás spracúvajú osobné údaje a v prípade, ak Vaše osobné údaje spracúvame, poskytneme Vám informáciu o tom, aké údaje o Vás spracúvame, za akým účelom, komu boli Vaše osobné
				údaje poskytnuté, či boli prenesené do tretej krajiny a ako dlho budeme Vaše osobné údaje uchovávať.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Právo na opravu
			</Typography>
			<Typography>V prípade, ak máte za to, že spracúvame o Vás nesprávne osobné údaje, máte právo nás požiadať o ich opravu.</Typography>
			<Typography variant="h5" fontWeight={500}>
				Právo na vymazanie (právo na „zabudnutie“)
			</Typography>
			<Typography>
				Máte právo na vymazanie Vašich osobných údajov, v prípade ak sú splnené nasledovné podmienky a neuplatňujú sa zákonné výnimky: údaje a) už nie sú potrebné na účely, na ktoré sa získavali, b) odvoláte súhlas na spracúvanie
				Vašich osobných údajov, c) Vaše osobné údaje sú spracúvané na základe legitímneho záujmu našej spoločnosti a neprevažujú oprávnené dôvody na spracúvanie alebo namietnete spracúvanie za účelom priameho marketingu, d) osobné
				údaje sa spracúvali nezákonne. V prípade, ak by boli Vaše osobné údaje zverejnené a Vy si uplatníte právo na vymazanie, vymažeme aj takéto zverejnené osobné údaje. Zároveň budeme informovať ostatných príjemcov Vašich osobných
				údajov, že ste si uplatnili právo na vymazanie a mali by teda vymazať všetky odkazy na Vaše osobné údaje, ak to bude technicky možné so zreteľom na dostupnú technológiu a náklady na vykonanie týchto opatrení.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Právo na obmedzenie spracúvania
			</Typography>
			<Typography>Máte právo požiadať o dočasné obmedzenie spracúvania v prípade, ak máte za to, že o Vás spracúvame nesprávne osobné údaje alebo vaše osobné údaje spracúvame nezákonným spôsobom.</Typography>
			<Typography variant="h5" fontWeight={500}>
				Právo na prenosnosť údajov
			</Typography>
			<Typography>
				Máte právo získať osobné údaje, ktoré o Vás spracúvame na základe súhlasu a/alebo zmluvy a sú spracúvané automatizovanými prostriedkami, a to v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte. Máte tiež právo
				preniesť tieto údaje k ďalšiemu prevádzkovateľovi. Ak je to technicky možné, prenesieme na Vašu žiadosť Vaše osobné údaje priamo k Vami zvolenému prevádzkovateľovi.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Právo namietať a automatizované individuálne rozhodovanie
			</Typography>
			<Typography>
				Máte právo namietať z dôvodov týkajúcich sa Vašej konkrétnej situácie spracúvanie Vašich osobných údajov, ktoré je vykonávané na základe nášho oprávneného záujmu vrátane namietania proti profilovaniu založenému na našom
				oprávnenom záujme. Vždy máte právo namietať voči spracúvaniu Vašich osobných údajov ak sa tieto spracúvajú na účely priameho marketingu.
			</Typography>
			<Typography variant="h5" fontWeight={500}>
				Podávanie sťažností
			</Typography>
			<Typography>
				V prípade, ak sa domnievate, že spracúvanie Vašich osobných údajov je v rozpore s platnými právnymi predpismi na ochranu osobných údajov, máte právo podať sťažnosť na Úrad na ochranu osobných údajov Slovenskej republiky, a to
				prostredníctvom kontaktných údajov uverejnených tu. Platné od: 17.8.2023
			</Typography>
		</Paper>
	);
};
