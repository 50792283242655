import { useTheme } from "@emotion/react";
import { Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createWelcomeLangs } from "../../assets/dummyData/langy";
import { NewsItem } from "./newsItem.js";

export const NewsSection = () => {
	const [langs] = useState(createWelcomeLangs());
	const language = useSelector((state) => state.language.value);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	return (
		<Stack
			justifyContent="center"
			spacing={.5}
			sx={{
				height: "100%",
				marginLeft: tablet && 10,
			}}
		>
			<NewsItem delay={0.1} title={langs.get("titleNews")[language]} />
			<NewsItem delay={.7} title={langs.get("itemLoginGoogleNews")[language]} url="/topics/login" />
			<NewsItem delay={1.4} title={langs.get("itemZabudnuteHesloNews")[language]} url="/topics/login" />
			<NewsItem delay={2.1} title={langs.get("itemFinManagerNews")[language]} url="/topics/apps" />
			<NewsItem delay={2.8} title={langs.get("introduceTitle")[language]} url="/topics/introduce" />
			<NewsItem delay={3.5} title={langs.get("lb_editProfile")[language]} url="/profile" />
		</Stack>
	);
};
