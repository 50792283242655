import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { initialData } from "../../assets/dummyData/cvData";
import { theme } from "../../theme/theme";
import { useSelector } from "react-redux";
export const CvAcordeon = (props) => {
	const { langs, language } = props;
	const mode = useSelector((state) => state.mode.value);

	const [data] = useState(initialData);
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Paper>
			<Typography variant="h5" align="center" p={2} color={mode === 'dark'? '': theme.palette.dark.main}>
				{langs.get("lang_backgroundTitle")[language]}
			</Typography>
			{data.map((item, key) => {
				return (
					<Accordion key={key} expanded={expanded === item.panel} onChange={handleChange(item.panel)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${item.panel}bh-content`} id={`${item.panel}bh-header`}>
							<Typography color={mode === "dark" ? "" : theme.palette.dark.main} variant="subtitle1" sx={{ width: "33%", flexShrink: 0 }}>
								{langs.get(item.textHeader)[language]}
							</Typography>
							<Typography color={mode === "dark" ? "" : theme.palette.dark.main} variant="subtitle1">
								{langs.get(item.textSubHeader)[language]}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<List sx={{ width: "100%", maxHeight: "500px", overflow: "auto" }}>
								{item.textDetail.map((detail, keyDetail) => {
									return (
										<ListItem key={keyDetail}>
											<ListItemAvatar>
												<Avatar
													src={detail.iconSrc}
													alt={detail.title}
													style={{
														background: "white",
													}}
												/>
											</ListItemAvatar>
											<ListItemText>
												<Typography color={mode === "dark" ? "" : theme.palette.dark.main} variant="body1">
													{item.lang ? langs.get(detail.title)[language] : detail.title}
												</Typography>
												<Typography color={mode === "dark" ? "" : theme.palette.dark.main} variant="body2">
													{detail.date}
												</Typography>
												<Typography color={mode === "dark" ? "" : theme.palette.dark.main} variant="body2">
													{item.lang ? (!langs.get(detail.subtitle) ? detail.subtitle : langs.get(detail.subtitle)[language]) : detail.subtitle}
												</Typography>
												<Typography color={mode === "dark" ? "" : theme.palette.dark.main} variant="body2">
													{detail.address}
												</Typography>
											</ListItemText>
										</ListItem>
									);
								})}
							</List>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</Paper>
	);
};
