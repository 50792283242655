import { TextField } from "@mui/material";
import React from "react";

export const NumberFieldStyled = (props) => {
	const handleChangeInput = (e) => {
		props.cb(e.target.value, props.name, null);
	};
	return (
		<TextField
			sx={{
				width: "100%",
			}}
			name={props.name}
			autoComplete="false"
			type="number"
			label={props.lang}
			onChange={handleChangeInput}
			required={props.require ? true : false}
		/>
	);
};
