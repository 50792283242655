import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ListItemPlay } from "./listItemPlay";

export const PlayListContainer = (props) => {
	const songsList = useSelector((state) => state.music.list);

	return (
		<Grid
			container
			style={{
				display: "flex",
				zIndex: 2000,
				height: "100%",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Grid item xs={12}>
				<ListItemPlay data={songsList}  />
			</Grid>
		</Grid>
	);
};
