import { Card, Container } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { EditFormContainer } from "./editFormContainer";

export const EditProfileContainer = () => {
	return (
		<Container maxWidth="md">
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{
					duration: 0.8,
					delay: 0.5,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<Card className="bg-gradient">
					<EditFormContainer />
				</Card>
			</motion.div>
		</Container>
	);
};
