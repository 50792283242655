import { GlobalStyles, ThemeProvider, createTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkToken, coldStart } from "./assets/js/axiosCalls";
import { SwitchRouter } from "./router/switchRouter";
import { fetchData } from "./store/features/dboStore/cvDataSlice";
import { IndexedDBManager } from "./store/features/indexeddbstore/apiIndexeddb";
import { setStateLockIcon } from "./store/features/topics/lockIconSlice";
import { setUserName } from "./store/features/topics/userNameSlice";
import { getDesignTokens } from "./theme/modeDesign";

function App() {
	const dispatch = useDispatch();
	const init = useRef(false);

	const mode = useSelector((state) => state.mode.value);

	const memoizedCallback = useCallback(async () => {
		const indexedDBManager = new IndexedDBManager();
		await indexedDBManager.checkIndexedDBExists().then(async (exist) => {
			let enptyCheck = await indexedDBManager.checkDatabaseNotEmpty().then((emp) => emp);
			if (exist && enptyCheck) return;
			dispatch(fetchData());
		});
	}, [dispatch]);

	useEffect(() => {
		coldStart()
		memoizedCallback();
		if (init.current) {
			return;
		}
		init.current = true;
	}, [mode, init, memoizedCallback]);

	useEffect(() => {
		try {
			checkToken()
				.then((res) => {
					if (res && res.status === 200) {
						dispatch(setStateLockIcon(true));
						dispatch(setUserName(res.user));
					} else {
						dispatch(setStateLockIcon(false));
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} catch (error) {
			console.error(error);
		}
	}, [dispatch]);

	const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles
				styles={{
					body: { backgroundColor: theme.palette.light.main },
				}}
			/>
			<SwitchRouter />
		</ThemeProvider>
	);
}

export default App;
