import { Card, Container } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { ProfileContainer } from "../components/profile/profileContainer";
import { useSelector } from "react-redux";
import { PrivateSectionAlert } from "../components/privateSectionAlert";
export const Profile = () => {
	const isLoged = useSelector((state) => state.lockIcon.value);

	return (
		<Container maxWidth="md">
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{
					duration: 0.8,
					delay: 0.5,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				{isLoged ? (
					<Card className="bg-gradient">
						<ProfileContainer />
					</Card>
				) : (
					<PrivateSectionAlert />
				)}
			</motion.div>
		</Container>
	);
};
