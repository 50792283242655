import { DeleteOutline } from "@mui/icons-material";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setPlaying } from "../../../../../../store/features/music/actualPlaySlice";
import { deleteMusicFavorite } from "../../../../../../store/features/music/musicSlice";

export const FavItemPlay = (props) => {
	const { item } = props;
	const dispatch = useDispatch();

	const sendTrack = (e) => {
		console.log("LI send", e);
		dispatch(setPlaying(e));
	};

	const handleChangeFavorit = (e) => {
		dispatch(deleteMusicFavorite(item));
	};
	const iName = item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name;
	const artistName = item.artists[0].name.length > 15 ? item.artists[0].name.slice(0, 15) + "..." : item.artists[0].name;
	return (
		<ListItem
			secondaryAction={
				<IconButton
					edge="end"
					aria-label="comments"
					onClick={handleChangeFavorit}
					sx={{
						zIndex: 3000,
					}}
				>
					<DeleteOutline color="danger" fontSize="inherit" />
				</IconButton>
			}
			alignItems="flex-start"
			sx={{
				width: "100%",
				background: "linear-gradient(rgb(255, 38, 142) 0%, rgb(255, 105, 79) 100%)",
				border: 1,
				borderColor: "text.danger",
				borderRadius: 2,
				marginBottom: 1,
			}}
		>
			<ListItemButton role={undefined} onClick={() => sendTrack(item)} dense>
				<ListItemAvatar>
					<Avatar alt={item.album.name} src={item.album.images[2].url} />
				</ListItemAvatar>
				<ListItemText
					primary={iName}
					secondary={
						<>
							<Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
								{artistName}
							</Typography>
						</>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
};
