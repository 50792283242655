import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { IconComponent } from "../assets/images/svg/iconComponent";
export const CustomizedButton = (props) => {
	return (
		<Stack
			sx={{
				width: 200,
			}}
		>
			<Button variant="contained" component={Link} to={props.data.path}>
				<Stack justifyContent="center" alignItems="center">
					<IconComponent src={props.data.icon} size={6} color="black" />
					<Typography fontWeight={700}>{props.data.title}</Typography>
				</Stack>
			</Button>
		</Stack>
	);
};
