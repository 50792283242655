import React from "react";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import { motion } from "framer-motion";
import { AppMusicContainer } from "./appMusicContainer";
import { PrivateSectionAlert } from "../../../privateSectionAlert";

export const MusicPlayer = () => {
	const isLoged = useSelector((state) => state.lockIcon.value);
	return (
		<Container maxWidth="xl">
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{
					opacity: 1,
					scale: 1,
					transition: {
						opacity: {
							duration: 0.8,
							delay: 0.5,
							ease: [0, 0.71, 0.2, 1.01],
						},
					},
				}}
			>
				{isLoged ? <AppMusicContainer /> : <PrivateSectionAlert />};
			</motion.div>
		</Container>
	)
}
