import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import styles from "../../../assets/styles/css/projectCards.module.scss";
import { ItemProject } from "./itemProject";
import { theme } from "../../../theme/theme";

export const ParentProjectAcordeon = (props) => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const [isOpen] = useState(false);
	const { parentIcon, projects } = props.data;

	const handleOpen = (e) => {
		props.cbOpen(e, props.index);
	};

	return (
		<motion.div
			layout
			data-isopen={props.open}
			initial={{ borderRadius: tablet ? 150 : 10 }}
			className={styles.parent}
			onClick={() => handleOpen(!isOpen)}
			style={{
				background: theme.palette.danger.mainTransparent,
				color: theme.palette.light.main,
			}}
		>
			<motion.div layout>
				{!props.open && (
					<motion.div
						initial={{ opacity: 0, scale: 0 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{
							duration: 3,
							delay: 0.5,
							ease: [0, 0.71, 0.2, 1.01],
						}}
					>
						{parentIcon}
					</motion.div>
				)}
				{props.open && (
					<Grid container justifyContent="center" alignItems="center">
						{projects.map((item, idx) => {
							if (item.projectData) {
								return (
									<motion.div key={idx}
										initial={{ opacity: 0, scale: 0 }}
										animate={{ opacity: 1, scale: 1 }}
										transition={{
											duration: 3,
											delay: 0.5,
											ease: [0, 0.71, 0.2, 1.01],
										}}
									>
										<ItemProject key={idx} data={item} />
									</motion.div>
								);
							}
							return null;
						})}
					</Grid>
				)}
			</motion.div>
		</motion.div>
	);
};
