import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../../theme/theme";
import { oblastiData } from "../../../assets/dummyData/homeData";
import { OblastCardItem } from "./oblastCardItem";
import { createWelcomeLangs } from "../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const CreationSection = () => {
	const [langs] = useState(createWelcomeLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("titleSpecializtion")[language]}

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const desk = useMediaQuery(query.breakpoints.up("lg"));
	const mode = useSelector((state) => state.mode.value);
	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
				paddingBottom: 9,
				backgroundColor: mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.main,
				clipPath: "polygon(50% 0%, 100% 0, 100% 87%, 93% 87%, 0 100%, 0 0)",
				position: "relative",
				paddingTop: "15rem",
				zIndex: "-10",
				top: "-15rem",
				overflow: "visible",
			}}
		>
			<Grid container spacing={4} paddingBottom={7} align={desk ? "" : "center"} color={theme.palette.light.main}>
				<Grid item xs={12}>
					<Typography variant={tablet ? "h2" : "h4"} pt={5} fontWeight={900} align="center" color={theme.palette.light.main}>
						{langs.get("titleSpecializtion")[language].toLocaleUpperCase()}
					</Typography>
				</Grid>
				{oblastiData.map((item, idx) => {
					return (
						<Grid item xs={12} lg key={idx} mb={2}>
							<OblastCardItem langs={{ title: langs.get(item.subText)[language], text: langs.get(item.text)[language] }} />
						</Grid>
					);
				})}
			</Grid>
		</Container>
	);
};
