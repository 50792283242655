import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { theme } from "../../theme/theme";
import { CreateInput } from "../../assets/js/formInputs";
import { Grid } from "@mui/material";

const inputsData = [
	{
		col: "",
		name: "title",
		typ: "text",
		label: "Your project title",
		errBool: false,
		reqBool: true,
		cb: null,
		help: "",
		row: "",
	},
	{
		col: "",
		name: "subtitle",
		typ: "text",
		label: "Your project subtitle",
		errBool: false,
		reqBool: true,
		cb: null,
		help: "",
		row: "",
	},
	{
		col: "",
		name: "describe",
		typ: "text",
		label: "Project describe",
		errBool: false,
		reqBool: true,
		cb: null,
		help: "",
		row: "",
	},
    {
		col: "",
		name: "describe",
		typ: "file",
		label: "",
		errBool: false,
		reqBool: true,
		cb: null,
		help: "",
		row: "",
	},
];

export const AddProject = () => {
	const [expanded, setExpanded] = useState(false);

	return (
		<>
			<Accordion
				expanded={expanded}
				onChange={() => setExpanded(!expanded)}
				sx={{
					background: theme.palette.dark.main,
					color: theme.palette.light.main,
				}}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon color="light" />} aria-controls="panel1bh-content" id="panel1bh-header">
					<Typography variant="h5" fontWeight={700}>
						ADD PROJECT
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={2}>
						{inputsData.map((item, idx) => {
							return (
								<Grid key={idx} item xs={12} md={item.col ? item.col : true}>
									<CreateInput data={item} />
								</Grid>
							);
						})}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
};
