/**
 * INITIALS
 * 	const [langs] = useState(createCvLangs());
 * 	const language = useSelector((state) => state.language.value);
 * 	{langs.get("storyTitle")[language]}
 */

export const createProfileLangs = () => {
	const langs = new Map();

	langs.set("lb_withoutReason", {
		eng: "Without reason",
		sk: "Bez dôvodu",
	});
	langs.set("lb_reasonGive", {
		eng: "Give a reason",
		sk: "Uvedte dôvod",
	});
	
	langs.set("lb_titleDelete", {
		eng: "Delete account",
		sk: "Zmazať účet",
	});
	
	langs.set("btn_subscribe", {
		eng: "Subscribe news",
		sk: "Prihláste sa na odber noviniek",
	});
	langs.set("btn_installApp", {
		eng: "Install the application",
		sk: "Inštalovať aplikáciu",
	});
	langs.set("btn_deleteAccount", {
		eng: "Delete account",
		sk: "Zmazať účet",
	});
	langs.set("btn_changePassword", {
		eng: "Change Password",
		sk: "Zmeniť heslo",
	});
	langs.set("btn_editProfile", {
		eng: "Profile editing",
		sk: "Úprava profilu",
	});
	langs.set("logout", {
		eng: "Log out",
		sk: "Odhlásiť sa",
	});
	langs.set("langProfile", {
		eng: "Profile",
		sk: "Účet",
	});
	langs.set("lb_titleCompany", {
		eng: "(Company Name)",
		sk: "(Meno spoločnosti)",
	});
	langs.set("lb_titlePosition", {
		eng: "(Work position)",
		sk: "(Pracovná pozícia)",
	});
	langs.set("lb_titleArea", {
		eng: "(Area of ​​operation)",
		sk: "(Oblasť prevádzky)",
	});
	langs.set("lb_address", {
		eng: "(Address)",
		sk: "(Adresa)",
	});
	langs.set("lb_titleWeb", {
		eng: "(Web page)",
		sk: "(Web stránka)",
	});
	langs.set("lb_addPhoto", {
		eng: "add photo",
		sk: "pridaj fotku",
	});
	langs.set("lb_jobVacancy", {
		eng: "Job vacancy",
		sk: "Voľné pracovné miesta",
	});
	langs.set("lb_btnConfirm", {
		eng: "Confirm!",
		sk: "Potvrdiť!",
	});
	langs.set("lb_editInfo", {
		eng: "Editing profile information",
		sk: "Úprava informácií o profile",
	});
	langs.set("lb_addPosition", {
		eng: "Add a job position",
		sk: "Pridajte pracovnú pozíciu",
	});
	langs.set("lb_listVacancy", {
		eng: "List of vacancies",
		sk: "Zoznam voľných pracovných miest",
	});
	return langs;
};
export const createNavLangs = () => {
	const langs = new Map();
	/**
	 * Navigacia
	 */
	langs.set("langProfile", {
		eng: "Profile",
		sk: "Účet",
	});
	langs.set("lang_projectSectionTitle", {
		eng: "My projects",
		sk: "Moje projekty",
	});
	langs.set("introduceTitle", {
		eng: "Introduce yourself",
		sk: "Predstav sa mi",
	});
	langs.set("appsTitle", {
		eng: "Applications",
		sk: "Aplikácie",
	});
	langs.set("titleRecriuter", {
		eng: "Portfolio for Recruiters",
		sk: "Portfolio pre recruiterov",
	});
	langs.set("titleByMe", {
		eng: "by Michal Krasňanský",
		sk: "od Michal Krasňanský",
	});
	langs.set("aboutme", {
		eng: "About me",
		sk: "O mne",
	});
	langs.set("topics", {
		eng: "Topics",
		sk: "Témy",
	});
	langs.set("cv", {
		eng: "Resume",
		sk: "Životopis",
	});
	langs.set("login", {
		eng: "Login",
		sk: "Prihlásenie",
	});
	langs.set("logout", {
		eng: "Log out",
		sk: "Odhlásiť sa",
	});

	return langs;
};

export const createWelcomeLangs = () => {
	const langs = new Map();
	/**
	 * WELCOPE PAGE
	 */
	langs.set("lb_dark_mode", {
		eng: "Light / dark mod",
		sk: "Svetlý / tmavý mod",
	});
	langs.set("introduceTitle", {
		eng: "Introduce yourself",
		sk: "Predstav sa mi",
	});
	langs.set("titleWelcome", {
		eng: "Welcome!",
		sk: "Vitajte!",
	});
	langs.set("subWelcome", {
		eng: "I really appreciate your trust and my aim is to provide you the best services. Feel free to familiarize yourself with the solutions and services I offer. I will gladly provide you high quality and innovative solutions through professional programming and product design. Please do not hesitate to contact me with any questions. I am looking forward to our cooperation.",
		sk: "Vážim si dôveru a mojim cieľom je poskytnúť Vám tie najlepšie služby. Nech sa páči, zoznámte sa s riešeniami a službami, ktoré ponúkam. Rád Vám poskytnem vysoko kvalitné a inovatívne riešenia prostredníctvom profesionálneho programovania a produktového navrhovania. V prípade akýchkoľvek otázok ma, prosím, neváhajte kontaktovať. Teším sa na našu spoluprácu.",
	});
	langs.set("titleAbout", {
		eng: "Who I",
		sk: "Kto",
	});
	langs.set("subAbout", {
		eng: "{ am }",
		sk: "{ som }",
	});
	langs.set("programovanieAbout", {
		eng: "Programming",
		sk: "Programovanie",
	});
	langs.set("subProgramovanieAbout", {
		eng: "One of my passions is programming in JavaScript and React.js, which allows me to create sophisticated software. In teamwork, I take care of a friendly and harmonic working environment and I do my best to be a good colleague.",
		sk: "Jednou z mojich vášní je programovanie v jazyku JavaScript a React.js, čo mi umožňuje vytvárať sofistikované softvéry. V tímovej práci dbám na priateľské a harmonické pracovné prostredie a snažím sa byť dobrým spolupracovníkom.",
	});
	langs.set("hobbyAbout", {
		eng: "Hobbies",
		sk: "Koníčky",
	});
	langs.set("subHobbyAbout", {
		eng: "Apart from work, I am a sports enthusiast. I like to run and play soccer. I spend my free time running because it helps me keep fit and relax. I like to go to play football with other football enthusiasts.",
		sk: "Vo voľnom čase som veľký športový nadšenec. Najradšej behám a hrám futbal. Beh mi pomáha udržiavať sa v kondícii a zároveň zrelaxovať. Tiež si rád zahrám futbal s ďalšími futbalovými nadšencami.",
	});
	langs.set("rodinaAbout", {
		eng: "Family",
		sk: "Rodina",
	});
	langs.set("subRodinaAbout", {
		eng: "I am a proud father and husband. My family is very important to me, it brings me endless joy and happiness. In my personal life, I try to be an understanding and caring father and partner.",
		sk: "Som hrdým otcom a manželom. Moja rodina je pre mňa veľmi dôležitá, prináša mi nekonečnú radosť a šťastie. Vo svojom osobnom živote sa snažím byť chápavým a starostlivým otcom a partnerom.",
	});
	langs.set("titleSpecializtion", {
		eng: "Specializtion",
		sk: "Špecializácia",
	});
	langs.set("programovanieSpecializtion", {
		eng: "Web application",
		sk: "Programovanie webových aplikácií",
	});
	langs.set("subProgramovanieSpecializtion", {
		eng: 'In the field of "Web application programming" I specialize in Javascript, node.js and frameworks such as React.js and Express.js. In order to create web applications that are fast, robust and user-friendly, I use modern development tools such as VS Code. For working with databases, I prefer MongoDB or T-SQL with MS SQL Server, that allows me to efficiently store and manage data.',
		sk: 'V oblasti "Programovanie webových aplikácií" sa špecializujem na Javascript, node.js a frameworky ako React.js a Express.js. Aby som vytvoril webové aplikácie, ktoré sú rýchle, robustné a užívateľsky prívetivé používam moderné vývojové nástroje, ako je VS Code,. Pri práci s databázami preferujem MongoDB alebo T-SQL s MS SQL Serverom, čo mi umožňuje efektívne ukladať a spravovať dáta.',
	});
	langs.set("navSpecializtion", {
		eng: "Product Design",
		sk: "Produktové navrhovanie",
	});
	langs.set("subNavSpecializtion", {
		eng: 'In the second area, "Product design", I deal with the creation of complex and visually attractive solutions and products. I systematically analyse the requirements and needs of clients and based on that I propose solutions that meet their expectations. I focus on every detail to ensure the final product is not only functional but aesthetically appealing, too.',
		sk: 'V druhej oblasti, "Produktové navrhovanie", sa zaoberám vytváraním komplexných a vizuálne atraktívnych riešení a produktov. Systematicky analyzujem požiadavky a potreby klientov a na základe toho navrhujem riešenia, ktoré spĺňajú ich očakávania. Sústredím sa na každý detail, aby som zabezpečil, že konečný produkt je nielen funkčný, ale aj esteticky príťažlivý.',
	});
	langs.set("titleNews", {
		eng: "News",
		sk: "Novinky",
	});
	langs.set("itemLoginGoogleNews", {
		eng: "Google login",
		sk: "Prihlasovanie Google účtom",
	});
	langs.set("itemZabudnuteHesloNews", {
		eng: "Forgotten password",
		sk: "Zabudnute heslo",
	});
	langs.set("itemFinManagerNews", {
		eng: "Financial manager",
		sk: "Finančný správca",
	});
	langs.set("itemRegNews", {
		eng: "Section Registration",
		sk: "Sekcia Registrácia",
	});
	langs.set("titleContact", {
		eng: "Contact",
		sk: "Kontakt",
	});
	langs.set("lb_editProfile", {
		eng: "Profile editing",
		sk: "Úprava profilu",
	});
	return langs;
};

/**
 * Projects section
 */

export const createProjectSectionLangs = () => {
	const langs = new Map();

	langs.set("lang_projectSectionTitle", {
		eng: "My projects",
		sk: "Moje projekty",
	});
	langs.set("lang_subtitleMealShop", {
		eng: "Food ordering solution",
		sk: "Riesenie pre objedavanie jedla",
	});
	langs.set("lang_describeMealShop", {
		eng: "An example of what food delivery can look like.",
		sk: "Príklad, ako môže vyzerať rozvoz jedla.",
	});
	langs.set("lang_subtitleNft", {
		eng: "NFT Gallery",
		sk: "NFT Galeria",
	});
	langs.set("lang_describeNft", {
		eng: "I want to create an NFT Gallery where it will be possible to buy various collections.",
		sk: "Chcem vytvoriť NFT Galériu, kde bude možné kúpiť rôzne zbierky.",
	});
	langs.set("lang_subtitleOwee", {
		eng: "Website directed at the customer",
		sk: "Webová stránka zameraná na zákazníka",
	});
	langs.set("lang_describeOwee", {
		eng: "I wanted to create something natural and simple using a theme.",
		sk: "Chcel som vytvoriť niečo prirodzené a jednoduché pomocou témy.",
	});
	langs.set("lang_subtitleTunes", {
		eng: "Search and play music",
		sk: "Vyhladavanie a prehravanie hudby",
	});
	langs.set("lang_describeTunes", {
		eng: "One of my older projects when my programming was in its infancy.",
		sk: "Jeden z mojich starších projektov, keď bolo moje programovanie v plienkach.",
	});
	langs.set("lang_subtitlePepe", {
		eng: "A solution for hairdressers",
		sk: "Riesenie pre kadernictvo",
	});
	langs.set("lang_describePepe", {
		eng: "This website is suspended because the owner has not responded to cooperation.",
		sk: "Tento web je pozastaveny pretoze sa majitel nevyjadril k spolupraci.",
	});
	langs.set("lang_subtitleRm", {
		eng: "Website for a gardening company",
		sk: "Web pre zahradkarsku firmu",
	});
	langs.set("lang_describeRm", {
		eng: "This site is a customized solution according to the owner.",
		sk: "Táto stránka je riešením na mieru podla majitela.",
	});
	langs.set("lang_subtitleOld", {
		eng: "My old portfolio",
		sk: "Moje staré portfólio",
	});
	langs.set("lang_describeOld", {
		eng: "This project is here to compare the shift of my experience.",
		sk: "Tento projekt je tu na porovnanie posunu mojich skúseností.",
	});
	langs.set("lang_subtitleTodo", {
		eng: "Todo app",
		sk: "Todo aplikácia",
	});
	langs.set("lang_describeTodo", {
		eng: "A smart application for planning your tasks.",
		sk: "Inteligentná aplikácia na plánovanie vašich úloh.",
	});
	langs.set("lang_btnCloseModal", {
		eng: "Close",
		sk: "Zavrieť",
	});
	return langs;
};
/**
 * Topics
 */

export const createTopicsLangs = () => {
	const langs = new Map();
	langs.set("sportTitle", {
		eng: "Sport activities",
		sk: "Športové aktivity",
	});
	langs.set("lang_blog", {
		eng: "Blog",
		sk: "Blog",
	});
	langs.set("sportText", {
		eng: "Here you will find some of my sports experiences.",
		sk: "Tu nájdete zopár mojich športových zážitkov.",
	});
	langs.set("projectTitle", {
		eng: "My projects",
		sk: "Moje projekty",
	});
	langs.set("projectSubTitle", {
		eng: "The projects I am working on.",
		sk: "Projekty, na ktorých pracujem.",
	});
	langs.set("projectText", {
		eng: "Here you can find projects I am currently working on or successfully completed projects.",
		sk: "Tu nájdete projekty, na ktorých aktuálne pracujem alebo úspešne ukončené projekty.",
	});
	langs.set("introduceTitle", {
		eng: "Introduce yourself",
		sk: "Predstav sa mi",
	});
	langs.set("introduceSubTitle", {
		eng: "I want to get to know you, too.",
		sk: "Aj ja ťa chcem spoznať!",
	});
	langs.set("introduceText", {
		eng: "Fill out a short questionnaire in which you introduce yourself to me.",
		sk: "Vyplňte krátky dotazník, v ktorom sa mi predstavíte.",
	});
	langs.set("loginTitle", {
		eng: "Login",
		sk: "Prihlásenie",
	});
	langs.set("loginSubTitle", {
		eng: "Log in and unlock more sections.",
		sk: "Prihláste sa a odomknite ďalšie časti.",
	});
	langs.set("loginText", {
		eng: "Registration is anonymous and the information provided is not shared.",
		sk: "Registrácia je anonymná a poskytnuté informácie nie sú zdieľané.",
	});
	langs.set("appsTitle", {
		eng: "Applications",
		sk: "Aplikácie",
	});
	langs.set("appsSubTitle", {
		eng: "Some applications",
		sk: "Nejaké aplikácie",
	});
	langs.set("appsText", {
		eng: "Here is my applications.",
		sk: "Tu nájdeš moje aplikacie.",
	});
	langs.set("codeTitle", {
		eng: "Clean code",
		sk: "Clean code",
	});
	langs.set("codeText", {
		eng: "Here you can find some programming tips.",
		sk: "Tu nájdete tipy na programovanie.",
	});
	langs.set("goalTitle", {
		eng: "The goal is the journey",
		sk: "Cieľom je cesta",
	});
	langs.set("goalText", {
		eng: "The way to the goals.",
		sk: "Cesta k cieľom.",
	});

	return langs;
};
/**
 * Zivotopis
 */
export const createCvLangs = () => {
	const langs = new Map();

	/**
	 * Side
	 */
	langs.set("langCv", {
		eng: "Curriculum Vitae",
		sk: "Životopis",
	});
	langs.set("langDevJs", {
		eng: "JavaScript Developer",
		sk: "Vývojár v JavaScript",
	});
	/**
	 * Story
	 */
	langs.set("storyTitle", {
		eng: "True Story",
		sk: "True Story",
	});
	langs.set("story1", {
		eng: "I have worked in various industries in the past. For example, in a car service, in a clothing store, at a gas station and in a bakery. I also worked as a taxi driver, a public transport driver, at Volkswagen as a quality inspector and finally as a manager in a moving service.",
		sk: "V minulosti som pracoval v rôznych odvetviach. Napríklad v autodielni, v predajni s oblečením, na pumpe, v pekárni. Robil som taxikára, šoféra MHD, vo Volkswagene ako kvalitár a nakoniec aj manažéra v sťahovacej službe.",
	});
	langs.set("story2", {
		eng: "",
		sk: "",
	});
	langs.set("story3", {
		eng: "I got into programming thanks to the perspective that life brought me. I have always had a logical mind. I remember that as a child I borrowed a book about HTML and wrote some index.htm.",
		sk: "K programovaniu som sa dostal vďaka rozhľadu, ktorý mi život priniesol. Vždy som mal logické myslenie. Pamätám si, že už ako malý som mal požičanú knižku o HTML a napísal som nejaký index.htm.",
	});
	langs.set("story4", {
		eng: "I didn't have a lot of motivation during high school and mostly just chased girls.",
		sk: "Počas strednej som nemal veľa motivácie a väčšinou som iba nahánal baby.",
	});
	langs.set("story5", {
		eng: "I am currently teaching two beginners who are attending IT schools. I was not so lucky. However, I took full advantage of the COVID period and my journey as a programmer could begin.",
		sk: "Momentálne učím dvoch začiatočníkov, ktorí chodia na IT školy. Ja som toto šťastie nemal. Avšak využil som obdobie COVID-u naplno a moja cesta programátora sa mohla začať.",
	});
	langs.set("story6", {
		eng: "I'm purely self-taught, learning through online courses, documentation, and just banging on it until it works.",
		sk: "Som čisto samouk, ktorý sa učí pomocou online kurzov, dokumentácie a tým, že do toho buchám, až kým to nezačne fungovať.",
	});
	/**
	 * main section
	 */
	langs.set("btnDownloadPdf", {
		eng: "Download pdf version",
		sk: "Stiahnite si pdf verziu",
	});
	langs.set("btnGoToProject", {
		eng: "Go to projects",
		sk: "Prejsť na projekty",
	});
	langs.set("lang_backgroundTitle", {
		eng: "Professional Background",
		sk: "Pracovné pozadie",
	});
	langs.set("lang_onlyRelevatWork", {
		eng: "Relevant work experience only",
		sk: "Iba súvisiace pracovné skúsenosti",
	});
	langs.set("label_school", {
		eng: "University of Animation",
		sk: "Stredná umelecká Škola Animovanej Tvorby",
	});
	langs.set("label_education", {
		eng: "Education",
		sk: "Vzdelanie",
	});
	langs.set("label_schoolTitle", {
		eng: "University of Animation",
		sk: "Stredná umelecká Škola Animovanej Tvorby",
	});
	langs.set("label_schoolOdb", {
		eng: "Graphic design",
		sk: "Grafický dizajn",
	});
	langs.set("label_other", {
		eng: "Other",
		sk: "Iné",
	});
	langs.set("label_otherSubtitle", {
		eng: "Other characteristics and skills",
		sk: "Iné vlastnosti a schopnosti",
	});
	/**
	 * curriculum
	 */
	langs.set("label_contactText", {
		eng: "Contact information",
		sk: "Kontaktné údaje",
	});
	langs.set("label_znalostiText", {
		eng: "Knowledge",
		sk: "Znalosti",
	});
	langs.set("cvUvodText", {
		eng: "I am interested in programming, especially front-end. I like to learn new technologies. I deal with programming on a daily basis at work and after work at home, too. I focus my interest primarily on technologies (React.js, JavaScript, Material-UI, Bootstrap 5, Css/Sass, Node.js, Express.js, TSQL, MongoDb), I am also open to new challenges.",
		sk: "Zaujímam sa o programovanie, predovšetkým front-end. Rád sa učím nové technológie. S programovaním sa stretávam na dennej báze v práci a aj po práci doma. Svoj záujem smerujem predovšetkým na technológie (React.js, JavaScript, Material-UI, Bootstrap 5, Css/Sass, Node.js, Express.js, TSQL, MongoDb ), no zároveň som otvorený novým výzvam.",
	});
	langs.set("workExperienceTitle", {
		eng: "Work experience",
		sk: "Pracovné skúsenosti",
	});
	langs.set("label_actualJob", {
		eng: "Present",
		sk: "Súčasnosť",
	});
	langs.set("label_educationTitle", {
		eng: "Education | Graphic design",
		sk: "Vzdelanie | Grafický dizajn",
	});
	langs.set("label_educationSubtitle", {
		eng: "University of Animation | Bratislava",
		sk: "Stredná umelecká Škola Animovanej Tvorby | Bratislava",
	});
	langs.set("label_courses", {
		eng: "Courses",
		sk: "Kurzy",
	});
	langs.set("label_courseTitles", {
		eng: "Udemy, Vzhurudolu, Vue Mastery, Skillmea, ITnetwork.cz",
		sk: "Udemy, Vzhurudolu, Vue Mastery, Skillmea, ITnetwork.cz",
	});
	langs.set("label_engLanguage", {
		eng: "English language:",
		sk: "Anglický jazyk:",
	});
	langs.set("label_diverlicense", {
		eng: "Driver's license type: ",
		sk: "Vodičský preukaz typu: ",
	});

	return langs;
};

/**
 * Login
 */

export const createLoginLangs = () => {
	const langs = new Map();
	langs.set("lang_login", {
		eng: "Login",
		sk: "Prihlásenie",
	});
	langs.set("errMsgLoginGoogleExistAcc", {
		eng: "This account has already been registered using a different method.",
		sk: "Tento účet je už zaregistrovaný iným spôsobom.",
	});
	langs.set("lang_registration", {
		eng: "Create account",
		sk: "Vytvoriť účet",
	});
	return langs;
};
export const createInfoLoginLangs = () => {
	const langs = new Map();

	langs.set("lang_infoTitle", {
		eng: "Information",
		sk: "Informácie",
	});
	langs.set("lang_info1", {
		eng: "Your registration is only for the purpose of unlocking additional information on this website, none of the data you provide will be shared with third parties.",
		sk: "Vaša registrácia slúži len na účely odomknutia ďalších informácií na tejto webovej stránke, žiadne z údajov, ktoré poskytnete, nebudú zdieľané s tretími stranami.",
	});
	langs.set("lang_info2", {
		eng: "Once you register, you will be able to follow the content I create. You will have the opportunity to add comments on posts and new creations that will come to my mind.",
		sk: "Po registrácii budete môcť sledovať obsah, ktorý tvorím. Budete mať možnosť komentovať príspevky a iné veci, ktoré ma ešte len napadnú.",
	});
	langs.set("lang_btnInfo", {
		eng: "Detail information",
		sk: "Podrobné informácie",
	});

	return langs;
};
/**
 * Fromular
 */
export const createFormularLangs = () => {
	const langs = new Map();

	langs.set("btn_msg_sent", {
		eng: "Message Sent",
		sk: "Správa bola odoslaná",
	});
	langs.set("lb_in_person", {
		eng: "Personal",
		sk: "Osobnom",
	});
	langs.set("lb_in_company", {
		eng: "Company",
		sk: "Firmy",
	});
	langs.set("lb_company_or_person", {
		eng: "In representation of",
		sk: "V zastupeni",
	});
	langs.set("lb_plat", {
		eng: "Salary",
		sk: "Plat",
	});
	langs.set("lb_benefits", {
		eng: "Benefits",
		sk: "Benefity",
	});
	langs.set("lb_project_tech", {
		eng: "Technologies Used in the Project",
		sk: "Technológie použité v projekte",
	});
	langs.set("lb_project_name", {
		eng: "Project Title",
		sk: "Názov projektu",
	});
	langs.set("lb_tpp", {
		eng: "Permanent Employment",
		sk: "Trvalý pracovný pomer",
	});
	langs.set("lb_partime", {
		eng: "Part-Time Employment",
		sk: "Práca na čiastočný úväzok",
	});
	langs.set("lb_selfemploiment", {
		eng: "Self-Employment",
		sk: "Samostatne zárobková činnosť",
	});
	langs.set("lb_contract", {
		eng: "Contract Work",
		sk: "Práca na dohodu",
	});
	langs.set("lb_assistance", {
		eng: "Occasional Assistance",
		sk: "Príležitostná pomoc",
	});
	langs.set("lb_cooperation", {
		eng: "Collaboration Proposal",
		sk: "Návrh na spoluprácu",
	});
	langs.set("lb_zadaj_svoj_mail", {
		eng: "Enter your email",
		sk: "Zadajte svoj e-mail",
	});
	langs.set("lbMsg_zadaj_svoj_mail", {
		eng: "If the specified e-mail is registered, an authorization will be sent to you, which will allow you to change your password additionally",
		sk: "Ak je uvedený e-mail zaregistrovaný, bude vám zaslaná autorizácia, ktorá vám umožní dodatočnú zmenu hesla",
	});
	langs.set("btn_poziadat_o_zmenu", {
		eng: "Request a password change",
		sk: "Požiadať o zmenu hesla",
	});
	langs.set("btnforgotPwd", {
		eng: "Forgot your password?",
		sk: "Zabudol si heslo?",
	});
	langs.set("lb_name_or_company", {
		eng: "Your name or Company name",
		sk: "Vaše meno alebo názov Firmy",
	});
	langs.set("firstNameFrom", {
		eng: "First name",
		sk: "Meno",
	});
	langs.set("errFirstNameFrom", {
		eng: "Not valid is short or empty",
		sk: "Neplatné je krátke alebo prázdne",
	});
	langs.set("lastNameFrom", {
		eng: "Last name",
		sk: "Priezvisko",
	});
	langs.set("errLastNameFrom", {
		eng: "Not valid is short or empty",
		sk: "Neplatné je krátke alebo prázdne",
	});
	langs.set("emailFrom", {
		eng: "E-mail",
		sk: "E-mail",
	});
	langs.set("lb_address", {
		eng: "Address",
		sk: "Adresa",
	});
	langs.set("errEmailFrom", {
		eng: "E-mail is not valid",
		sk: "E-mail nie je platný",
	});
	langs.set("paswdFrom", {
		eng: "Password",
		sk: "Heslo",
	});
	langs.set("errPaswdFrom", {
		eng: "Password is not valid",
		sk: "Heslo nie je platné",
	});
	langs.set("helpPaswdFrom", {
		eng: "At least eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
		sk: "Aspoň osem znakov, aspoň jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak.",
	});
	langs.set("rePaswdFrom", {
		eng: "Repeat your password",
		sk: "Zopakujte svoje heslo",
	});
	langs.set("errRePaswdFrom", {
		eng: "Passwords not matches",
		sk: "Heslá sa nezhodujú",
	});
	langs.set("msg_feedBack", {
		eng: "Share your insights with me and let me know how my project impressed you.",
		sk: "Podeľte sa so mnou o svoje postrehy a dajte mi vedieť, ako na vás môj projekt zapôsobil.",
	});
	langs.set("msgFrom", {
		eng: "Your message",
		sk: "Vaša správa",
	});
	langs.set("btnSendMsgFrom", {
		eng: "Send message",
		sk: "Odoslať správu",
	});
	langs.set("errSendMoreThanOneFrom", {
		eng: "I have already received one of your messages. If you want to write another one, use the email miso.krasnansky@gmail.com well thank you",
		sk: "Jednu Vašu správu som už prijal. Ak chcete napísať ďalšiu, použite e-mail miso.krasnansky@gmail.com Ďakujem",
	});
	langs.set("errTitleFrom", {
		eng: "Error",
		sk: "Chyba",
	});
	langs.set("errRequiredMailAndMsgFrom", {
		eng: "E-mail and message are required!",
		sk: "Je potrebné zadať E-mail a správu!",
	});
	langs.set("errRequiredMail", {
		eng: "E-mail are required!",
		sk: "Je potrebné zadať E-mail!",
	});
	langs.set("errNespravnyMail", {
		eng: "The specified e-mail does not exist!",
		sk: "Zadaný e-mail neexistuje!",
	});
	langs.set("successSendMsgFrom", {
		eng: "Your message was sent!",
		sk: "Váša správa bola odoslaná!",
	});
	langs.set("successCheckEmailMsg", {
		eng: "Check your e-mail!",
		sk: "Skontrolujte si svoj e-mail!",
	});
	langs.set("lang_termsInput", {
		eng: "I accept the Terms of Use & Privacy Policy",
		sk: "Súhlasím s podmienkami používania a zásadami ochrany osobných údajov",
	});
	langs.set("errLang_termsInput", {
		eng: "Confirmation required",
		sk: "Vyzaduje sa potvrdit",
	});
	langs.set("errLang_msgEmail", {
		eng: "E-mail is not valid!",
		sk: "E-mail nie je platný!",
	});
	langs.set("errLang_msgPaswd", {
		eng: "Password is not valid!",
		sk: "Heslo nie je platné!",
	});
	langs.set("errLang_msgRePaswd", {
		eng: "Passwords do not match!",
		sk: "Heslá sa nezhodujú!",
	});
	langs.set("errLang_msgTerms", {
		eng: "Terms of Use & Privacy Policy is required!",
		sk: "Podmienky používania a zásady ochrany osobných údajov sú povinné!",
	});
	langs.set("errLang_msgFirst", {
		eng: "First name is not valid!",
		sk: "Meno nie je platné!",
	});
	langs.set("errLang_msgLast", {
		eng: "Last name is not valid!",
		sk: "Priezvisko nie je platné!",
	});
	langs.set("errLang_msgConflict", {
		eng: "A user with this e-mail already exists!",
		sk: "Uzivatel s timto e-mailom uz existuje!",
	});
	langs.set("errLang_msgBadMailOrPass", {
		eng: "Incorrect password or email!",
		sk: "Nespravne heslo alebo email!",
	});
	langs.set("errLang_msgServerErr", {
		eng: "Unexpected server error!",
		sk: "Neočakávana chyba servera!",
	});

	return langs;
};

/**
 * finance manager
 */

export const createFinanceLangs = () => {
	const langs = new Map();
	langs.set("titleManager", {
		eng: "Calculate your expenses",
		sk: "Spočítaj si výdavky",
	});
	langs.set("month_salary", {
		eng: "Monthly salary",
		sk: "Mesačný plat",
	});
	langs.set("depth_fee", {
		eng: "Monthly installments",
		sk: "Mesačné splátky",
	});
	langs.set("food_fee", {
		eng: "Monthly grocery expenses",
		sk: "Mesačne za potraviny",
	});
	langs.set("month_totalfee", {
		eng: "Monthly expenses",
		sk: "Mesačné náklady",
	});
	langs.set("month_save", {
		eng: "Saved money",
		sk: "Ušetrené peniaze",
	});
	langs.set("infoTitle_reserve", {
		eng: "Your reserve should be",
		sk: "Tvoja rezerva by mala byt",
	});
	langs.set("actualState_reserve", {
		eng: "The current balance of the reserve is",
		sk: "Aktualny stav rezervy",
	});
	langs.set("infoActualState_reserve", {
		eng: "The value of the reserve is",
		sk: "Hodnota rezervy",
	});
	langs.set("infoActualPercentage_reserve", {
		eng: "Financial reserve as a percentage",
		sk: "Rezerva v percentách",
	});
	langs.set("infoSave_money", {
		eng: "You will save",
		sk: "Mesačne ušetríš",
	});
	langs.set("perDay_money", {
		eng: "You can spend about",
		sk: "Denne môžete minút o",
	});
	langs.set("infoFinish_reserve", {
		eng: "To fill the financial reserve will take",
		sk: "Naplniť rezervu vám potrvá",
	});
	langs.set("upTo_budget", {
		eng: "Up to Budget",
		sk: "Cez rozpočet",
	});

	langs.set("lang_month", {
		eng: "months",
		sk: "mesiacov",
	});
	langs.set("lang_year", {
		eng: "years",
		sk: "rokov",
	});
	langs.set("lang_more", {
		eng: "more",
		sk: "viac",
	});
	langs.set("btn_save", {
		eng: "Save Changes",
		sk: "Uložiť zmeny",
	});
	langs.set("btn_add", {
		eng: "Add",
		sk: "Pridať",
	});
	langs.set("lang_total", {
		eng: "Total",
		sk: "Spolu",
	});
	langs.set("listOther_title", {
		eng: "List other expenses",
		sk: "Zoznam ostatných výdavkov",
	});
	langs.set("other_fee", {
		eng: "Other expenses",
		sk: "Ostatné výdavky",
	});
	return langs;
};

export const createFooterLangs = () => {
	const langs = new Map();
	/**
	 * Footer
	 */
	langs.set("infoPageFooter", {
		eng: "This page is in the process of creation.",
		sk: "Táto stránka je v procese vytvárania.",
	});
	langs.set("oldPortfolioFooter", {
		eng: "Old Portfolio",
		sk: "Staré portfólio",
	});
	return langs;
};

/**
 * Other
 */
export const createOtherLangs = () => {
	const langs = new Map();
	langs.set("comingOther", {
		eng: "Coming soon",
		sk: "Už čoskoro",
	});
	langs.set("langPriavateOther", {
		eng: "Private",
		sk: "Súkromné",
	});
	langs.set("langProfile", {
		eng: "Profile",
		sk: "Účet",
	});
	langs.set("langLang", {
		eng: "Eng",
		sk: "Sk",
	});
	langs.set("loginBtnOther", {
		eng: "Go to login",
		sk: "Prejdite na prihlásenie",
	});
	langs.set("privatSectionMsgOther", {
		eng: "Only logged in users have access to this section.",
		sk: "Do tejto sekcie majú prístup iba prihlásení užívatelia.",
	});

	return langs;
};
