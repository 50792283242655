import React from "react";
import { useTheme } from "@emotion/react";
import { Container, useMediaQuery } from "@mui/material";
import { TopicGrid } from "../components/topics/topicGrid";

export const Topics = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<TopicGrid />
		</Container>
	);
};
