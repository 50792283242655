import { Grid, Typography } from "@mui/material";
import React from "react";
import { AddProject } from "./addProject";
import { theme } from "../../theme/theme";

export const AdminContainer = () => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h3" color={theme.palette.dark.main} align="center" fontWeight={900}>
					ADMIN
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<AddProject />
			</Grid>
		</Grid>
	);
};
