import React from "react";
import { Box, Button, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../../theme/theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTheme } from "@emotion/react";

export const SubmitContactBtn = (props) => {
	const { loading, afterErrloading, cb, errMsg, text, w = null, color='js' } = props;
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	return (
		<Box sx={{ position: "relative", width: tablet ? (w ? w : 1 / 4) : 1 }}>
			<Button
				id="submitBtn"
				onClick={cb}
				type="submit"
				variant="contained"
				color={color}
				sx={{
					width: "100%",
					paddingX: 5,
				}}
			>
				<Typography noWrap color={theme.palette.dark.main} fontWeight={500}>
					{text}
				</Typography>
			</Button>
			{loading && !errMsg && (
				<CircularProgress
					size={24}
					sx={{
						color: theme.palette.light.main,
						position: "absolute",
						top: "50%",
						right: "2%",
						marginTop: "-12px",
						marginLeft: "-12px",
						zIndex: 2000,
					}}
				/>
			)}

			{(afterErrloading || errMsg) && (
				<ErrorOutlineIcon
					sx={{
						color: theme.palette.light.main,
						position: "absolute",
						top: "50%",
						right: "2%",
						marginTop: "-12px",
						marginLeft: "-12px",
					}}
				/>
			)}
		</Box>
	);
};
