import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { setMusicList } from "../../../../../../store/features/music/musicSlice";
import { useDispatch, useSelector } from "react-redux";

export const InputSearch = () => {
	const [searchText, setSearchText] = useState("");
	const token = useSelector((state) => state.music.token);

	const dispatch = useDispatch();

	const getMusic = async (t, query = null) => {
		if (query) {
			let list = await axios.get(query, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + t,
					"Content-Type": "application/json",
				},
			});
			return list;
		}
	};

	const searchSong = () => {
		console.log("search : ", searchText);
		var songName = searchText;
		var query = "https://api.spotify.com/v1/search?query=track%3A" + encodeURIComponent(songName) + "&type=track&include_external=audio&locale=sk-SK%2Csk%3Bq%3D0.9%2Ccs%3Bq%3D0.8%2Cen-US%3Bq%3D0.7%2Cen%3Bq%3D0.6&offset=0&limit=5";
		getMusic(token.token, query)
			.then((we) => {
				console.log(we.data.tracks.items, "WE");
				dispatch(setMusicList(we.data.tracks.items));
			})
			.catch((e) => {
				console.log(e);
			});
		return query;
	};
	return (
		<Grid container spacing={2}>
			<Grid item xs>
				<TextField
					fullWidth
					size="small"
					type="text"
					id="songNameInput"
					placeholder="Zadajte názov skladby"
					value={searchText}
					onKeyUp={(e) => (e.key === "Enter" ? searchSong() : () => null)}
					onChange={(e) => setSearchText(e.target.value)}
				/>
			</Grid>
			<Grid item xs={"auto"}>
				<Button variant="contained" color="danger" onClick={searchSong} id="searchButton">
					Vyhľadať
				</Button>
			</Grid>
		</Grid>
	);
};
