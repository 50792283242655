import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Rating } from "@mui/material";
import { theme } from "../../theme/theme";

const StyledRating = styled(Rating)({
	"& .MuiRating-iconFilled": {
		color: theme.palette.warning.main,
	},
	"& .MuiRating-iconHover": {
		color: theme.palette.warning.main,
	},
});

export const RatingCards = (props) => {
	const [ratingState, setRatingState] = useState(0);
	const handleChangeRating = (e) => {
		let ratingTopicsArr = JSON.parse(localStorage.getItem("favoriteTopics"));

		if (!ratingTopicsArr) {
			setRatingState(parseFloat(e.target.value));
			localStorage.setItem("favoriteTopics", JSON.stringify({ [props.label]: e.target.value }));
		} else {
			setRatingState(parseFloat(e.target.value));
			ratingTopicsArr[props.label] = e.target.value;
			localStorage.setItem("favoriteTopics", JSON.stringify(ratingTopicsArr));
		}
	};
	useEffect(() => {
		let ratingTopicsArr = JSON.parse(localStorage.getItem("favoriteTopics"));
		if (ratingTopicsArr) setRatingState(parseFloat(ratingTopicsArr[props.label]));
	}, [ratingState, props.label]);
	return (
		<StyledRating
			name={props.label}
			onChange={handleChangeRating}
			defaultValue={props.rate}
			value={ratingState}
			getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
			precision={0.5}
			icon={<FavoriteIcon color="danger" fontSize="small" />}
			emptyIcon={<FavoriteBorderIcon color="secondary" fontSize="small" />}
		/>
	);
};
