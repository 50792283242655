import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const htmlCanvasToPdf = (element, exMob) => {
	function zistiTypZariadenia() {
		var userAgent = navigator.userAgent;

		if (/mobi/i.test(userAgent)) {
			openPrintWindow(exMob);
			return true;
		} else if (/tablet/i.test(userAgent)) {
			openPrintWindow(exMob);
			return true;
		} else if (/iPad/i.test(userAgent)) {
			openPrintWindow(exMob);
			return true;
		} else if (/iPhone/i.test(userAgent)) {
			openPrintWindow(exMob);
			return true;
		} else if (/android/i.test(userAgent)) {
			openPrintWindow(exMob);
			return true;
		} else if (/windows phone/i.test(userAgent)) {
			openPrintWindow(exMob);
			return true;
		} else if (/desktop/i.test(userAgent)) {
			return false;
		} else {
			return false;
		}
	}

	// Použitie funkcie na zistenie typu zariadenia
	let mobil = zistiTypZariadenia();
	if (mobil) return;

	html2canvas(element).then((canvas) => {
		const imgData = canvas.toDataURL("image/png");
		const pdf = new jsPDF("p", "mm", "a4");
		const imgWidth = 210;
		const pageHeight = 297;
		const imgHeight = (canvas.height * imgWidth) / canvas.width;
		let heightLeft = imgHeight;
		let position = 0;

		pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
		heightLeft -= pageHeight;

		while (heightLeft >= 0) {
			position = heightLeft - imgHeight;
			pdf.addPage();
			pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;
		}

		pdf.save("CV_krasnansky_CV.pdf");
	});
};

export function openPrintWindow(printHtmlStr) {
	console.log(window._printIframe);
	let hrefo = "../styles/css/styleforExMob.css";
	var html =
		"<!DOCTYPE HTML>" +
		"<html>" +
		"<head>" +
		"<title>CV_krasnansky_CV_print_Mobil</title>" +
		'<meta http-equiv="x-ua-compatible" content="IE=Edge"/>' +
		'<link rel="stylesheet" type="text/css" href="' +
		hrefo +
		' media="all" />' +
		"</head>" +
		"<body>" +
		'<div class="print">' +
		printHtmlStr.outerHTML +
		"</div>";

	html += "</body>";
	html += "</html>";

	var iframe = window._printIframe;
	if (!window._printIframe) {
		iframe = window._printIframe = document.createElement("iframe");
		document.body.appendChild(iframe);

		iframe.style.display = "none";
		iframe.onload = function () {
			setTimeout(function () {
				iframe.focus();
				iframe.contentWindow.print();
			}, 1);
		};
	}
	iframe.src = "about:blank";
	var doc = iframe.contentWindow.document;
	var windowUrl = window.location; // iframe.contentWindow.location;
	var uniqueName = new Date();
	var windowName = "Print" + uniqueName.getTime();
	window.testwindow = doc.open(windowUrl, windowName);

	doc.write(html);
	doc.close();
}
