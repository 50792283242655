import { Grid, Typography } from "@mui/material";
import React from "react";
import { FavoriteItemsList } from "./favoriteItemsList";

export const FavoriteContainer = () => {
	return (
		<Grid
			container
			style={{
				display: "flex",
				zIndex: 2000,
				height: "100%",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Grid item xs={12}>
				<Typography variant="body1" >Favorite List</Typography>
				<FavoriteItemsList />
			</Grid>
		</Grid>
	);
};
