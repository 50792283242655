import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	username: null,
	finance: {},
	userdata: {},
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setUserName: (state, action) => {
			state.username = action.payload;
		},
		setFinanceData: (state, action) => {
			state.finance = { ...state.finance, ...action.payload };
		},
		setUserData: (state, action) => {
			state.userdata = { ...state.userdata, ...action.payload };
		},
	},
});

export const { setUserName, setFinanceData, setUserData } = profileSlice.actions;

export default profileSlice.reducer;
