import strava from "../images/jpg/Strava.jpg";
import code from "../images/jpg/cleanCode.jpg";
import goal from "../images/jpg/goal.jpg";
import introduce from "../images/jpg/introduce.jpg";
import login from "../images/jpg/login.jpg";
import project from "../images/jpg/proj.jpg";
import { ReactComponent as HandsIcon } from "../images/loga/hands_media_meeting_icon.svg";
import { ReactComponent as LoginIcon } from "../images/loga/login_protection_secure_icon.svg";
import { ReactComponent as StackOIcon } from "../images/loga/logo_overflow_stack_icon.svg";
import { ReactComponent as StravaIcon } from "../images/loga/logo_strava_icon.svg";
import { ReactComponent as GoalIcon } from "../images/svg/goal.svg";
import { ReactComponent as ProjIcon } from "../images/svg/progress.svg";
import { IconComponent } from "../images/svg/iconComponent";
export const topicInitData = [
	{
		active: false,
		private: true,
		cardName: "strava",
		icon: <IconComponent src={StravaIcon} size={3} />,
		img: strava,
		title: "sportTitle",
		subtitle: "lang_blog",
		text: "sportText",
		buttons: ["Info"],
		rating: 0,
		countRating: 0,
		url: "",
	},
	{
		active: true,
		private: true,
		cardName: "projects",
		icon: <IconComponent src={ProjIcon} size={3} />,
		img: project,
		title: "projectTitle",
		subtitle: "projectSubTitle",
		text: "projectText",
		buttons: ["info"],
		rating: 0,
		countRating: 0,
		url: "/topics/projects",
	},
	{
		active: true,
		private: false,
		cardName: "questionnaire",
		icon: <IconComponent src={HandsIcon} size={3} />,
		img: introduce,
		title: "introduceTitle",
		subtitle: "introduceSubTitle",
		text: "introduceText",
		buttons: ["Info"],
		rating: 0,
		countRating: 0,
		url: "/topics/introduce",
	},
	{
		active: true,
		private: true,
		cardName: "apps",
		icon: <IconComponent src={LoginIcon} size={3} />,
		img: login,
		title: "appsTitle",
		subtitle: "appsSubTitle",
		text: "appsText",
		buttons: ["Info"],
		rating: 0,
		countRating: 0,
		url: "/topics/apps",
	},
	{
		active: false,
		private: true,
		cardName: "code",
		icon: <IconComponent src={StackOIcon} size={3} />,
		img: code,
		title: "codeTitle",
		subtitle: "lang_blog",
		text: "codeText",
		buttons: ["Info"],
		rating: 0,
		countRating: 0,
		url: "",
	},
	{
		active: false,
		private: true,
		cardName: "goal",
		icon: <IconComponent src={GoalIcon} size={3} />,
		img: goal,
		title: "goalTitle",
		subtitle: "lang_blog",
		text: "goalText",
		buttons: ["Info"],
		rating: 0,
		countRating: 0,
		url: "",
	},
];
