import { openDB } from "idb";

const DB_NAME = "dbData";
const DB_VERSION = 1;

const STORE_NAME = "dbStore";

// CLASS INDEXED DB
export class IndexedDBManager {
	constructor() {
		this.dbPromise = openDB(DB_NAME, DB_VERSION, {
			upgrade(db) {
				db.createObjectStore(STORE_NAME, { keyPath: "id" });
			},
		});
	}
    
	// CHECK DB
	async checkIndexedDBExists() {
		try {
			await openDB(DB_NAME, DB_VERSION);
			return true;
		} catch (error) {
			return false;
		}
	}
    
	// CHECK EMPTY
	async checkDatabaseNotEmpty() {
		const db = await this.dbPromise;
		const transaction = db.transaction(STORE_NAME, "readonly");
		const store = transaction.objectStore(STORE_NAME);

		const count = await store.count();

		return count > 0;
	}
    
	// CREATE
	async createRecord(record) {
		const db = await this.dbPromise;
		const transaction = db.transaction(STORE_NAME, "readwrite");
		const store = transaction.objectStore(STORE_NAME);

		await store.add(record);

		return transaction.complete;
	}

	// UPDATE
	async updateRecord(record) {
		const db = await this.dbPromise;
		const transaction = db.transaction(STORE_NAME, "readwrite");
		const store = transaction.objectStore(STORE_NAME);

		await store.put(record);

		return transaction.complete;
	}
    
	// GET BY ID
	async getRecordById(id) {
		const db = await this.dbPromise;
		const transaction = db.transaction(STORE_NAME, "readonly");
		const store = transaction.objectStore(STORE_NAME);

		return store.get(id);
	}
    
	//GET ALL
	async getAllRecords() {
		const db = await this.dbPromise;
		const transaction = db.transaction(STORE_NAME, "readonly");
		const store = transaction.objectStore(STORE_NAME);

		const storedData = await store.getAll();

		return storedData;
	}
}
