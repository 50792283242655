import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Button, Divider, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createFooterLangs } from "../../assets/dummyData/langy";
import { theme } from "../../theme/theme";
import { LinksFooter } from "./linksFooter";
import { NewsSection } from "./newsSection.js";

export const PageFooter = () => {
	const [langs] = useState(createFooterLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("titleSpecializtion")[language]}
	const mode = useSelector((state) => state.mode.value);

	let location = useLocation();
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.down("md"));

	const redirToLinkedin = () => {
		window.open("https://linkedin.com/in/michal-krasňanský-24a4b41b0");
	};
	const redirToGithub = () => {
		window.open("https://github.com/mkrasnansk");
	};
	useEffect(() => {
		let prewPath = localStorage.getItem("path");
		if (prewPath) {
			let arrPath = JSON.parse(prewPath);
			arrPath.push(location.pathname);
			if (arrPath.length > 3) arrPath.shift();
			localStorage.setItem("path", JSON.stringify(arrPath));
		} else {
			let arrPath = [];
			arrPath.push(location.pathname);
			localStorage.setItem("path", JSON.stringify(arrPath));
		}
	}, [location, tablet]);
	return (
		<Paper
			className={mode === "dark" ? "bg-gradient-dark" : "bg-gradient "}
			id="paper"
			elevation={3}
			sx={{
				width: "100%",
				height: !tablet ? 200 : "auto",
				position: "relative",
			}}
		>
			<Grid
				container
				pb={3}
				p={2}
				alignItems="center"
				justifyContent="center"
				sx={{
					height: "100%",
				}}
			>
				<Grid item xs={12} md align={tablet ? "center" : "left"}>
					<NewsSection />
				</Grid>
				<Grid
					item
					xs={12}
					md="auto"
					sx={{
						height: 1,
					}}
				>
					{!tablet && (
						<Divider
							orientation="vertical"
							sx={{
								marginX: "10vw",
							}}
						/>
					)}

					{tablet && (
						<Divider
							orientation="horizontal"
							sx={{
								marginY: 1,
							}}
						/>
					)}
				</Grid>
				<Grid item xs={12} md align="center">
					<Typography color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} variant="subtitle2">
						2023 © Michal Krasňanský
					</Typography>
					<Stack direction="row" justifyContent="center">
						<Button
							variant="text"
							onClick={redirToLinkedin}
							color="dark"
							sx={{
								padding: 0,
							}}
						>
							<LinkedInIcon
								sx={{
									fontSize: "2rem",
								}}
							/>
						</Button>
						<Button
							variant="text"
							onClick={redirToGithub}
							color="dark"
							sx={{
								padding: 0,
							}}
						>
							<GitHubIcon
								sx={{
									fontSize: "2rem",
								}}
							/>
						</Button>
					</Stack>
					<Typography color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} variant="subtitle2">
						{langs.get("infoPageFooter")[language]}
					</Typography>
				</Grid>
				{!tablet && (
					<>
						<Grid
							item
							xs={"auto"}
							sx={{
								height: "100%",
							}}
						>
							<Divider
								orientation="vertical"
								variant="middle"
								sx={{
									marginX: "10vw",
								}}
							/>
						</Grid>

						<Grid item xs={12} md>
							<LinksFooter />
						</Grid>
					</>
				)}
			</Grid>
		</Paper>
	);
};
