import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import BusinessIcon from "@mui/icons-material/Business";
import ExploreIcon from "@mui/icons-material/Explore";
import SignpostIcon from "@mui/icons-material/Signpost";
import WebIcon from "@mui/icons-material/Web";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProfileLangs } from "../../../assets/dummyData/langy";
import { IconComponent } from "../../../assets/images/svg/iconComponent";
import { getProfileData } from "../../../assets/js/axiosCalls";
import { theme } from "../../../theme/theme";
import { PhotoContainer } from "./photoContainer";
import { VacancyContainer } from "./vacancyContainer";
import { setUserData } from "../../../store/features/profile/profileSlice";

export const InfoProfileContainer = () => {
	const [langs] = useState(createProfileLangs());
	const language = useSelector((state) => state.language.value);
	const userName = useSelector((state) => state.userName.value);
	const profileData = useSelector((state) => state.profile.userdata);
	const dispatch = useDispatch();

	const [userProfile, setUserProfile] = useState({
		company: "",
		position: "",
		area: "",
		address: "",
		web: "",
		vacancy: [],
	});

	const getProfile = useCallback(() => {
		if (userName && Object.keys(profileData).length === 0) {
			try {
				getProfileData(userName)
					.then((res) => {
						dispatch(setUserData(res.data.data));
						setUserProfile(res.data.data);
					})
					.catch((err) => {
					});
			} catch {
				console.log(" err FIN CONTAINER");
			}
		}else{
			setUserProfile(profileData);
		}
	}, [userName, dispatch, profileData]);

	useEffect(() => {
		getProfile();
	}, [getProfile]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} lg order={{ xs: 2, lg: 2 }}>
				<Stack direction="column" spacing={1}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconComponent src={AccountCircleIcon} size={2} color={theme.palette.light.main} />
						<Typography>{userName}</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconComponent src={BusinessIcon} size={2} color={theme.palette.light.main} />
						<Typography color={theme.palette.light.mainTransparent}>{userProfile.company && userProfile.company !== "" ? userProfile.company : langs.get("lb_titleCompany")[language]}</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconComponent src={BadgeIcon} size={2} color={theme.palette.light.main} />
						<Typography color={theme.palette.light.mainTransparent}>{userProfile.position && userProfile.position !== "" ? userProfile.position : langs.get("lb_titlePosition")[language]}</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconComponent src={ExploreIcon} size={2} color={theme.palette.light.main} />
						<Typography color={theme.palette.light.mainTransparent}>{userProfile.area && userProfile.area !== "" ? userProfile.area : langs.get("lb_titleArea")[language]}</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconComponent src={SignpostIcon} size={2} color={theme.palette.light.main} />
						<Typography color={theme.palette.light.mainTransparent}>{userProfile.address && userProfile.address !== "" ? userProfile.address : langs.get("lb_address")[language]}</Typography>
					</Stack>
					<Stack direction="row" alignItems="center" spacing={1}>
						<IconComponent src={WebIcon} size={2} color={theme.palette.light.main} />
						<Typography color={theme.palette.light.mainTransparent}>{userProfile.web && userProfile.web !== "" ? userProfile.web : langs.get("lb_titleWeb")[language]}</Typography>
					</Stack>
				</Stack>
			</Grid>
			<Grid
				item
				xs={12}
				lg
				order={{ xs: 1, lg: 2 }}
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<PhotoContainer subtitle={langs.get("lb_addPhoto")[language]} />
			</Grid>
			<Grid item xs={12} lg order={{ xs: 1, lg: 2 }}>
				<VacancyContainer data={userProfile.vacancy} title={langs.get("lb_jobVacancy")[language]} />
			</Grid>
		</Grid>
	);
};
