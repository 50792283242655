import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setStateLockIcon } from "../../store/features/topics/lockIconSlice";
import { setUserName } from "../../store/features/topics/userNameSlice";
import { createProfileLangs } from "../../assets/dummyData/langy";

export const EditProfileButtons = () => {
	const [langs] = useState(createProfileLangs());
	const language = useSelector((state) => state.language.value);
	const dispatch = useDispatch();
	const userName = useSelector((state) => state.userName.value);
	const navigate = useNavigate();

	const handleLogOut = useCallback(() => {
		sessionStorage.removeItem("token");
		dispatch(setUserName(""));
		dispatch(setStateLockIcon(false));
		navigate("/topics/login");
	}, [dispatch, navigate]);

	return (
		<Grid container justifyContent="stretch" spacing={1}>
			<Grid item xs={12} sm={6} md={4} xl>
				<Button fullWidth variant="contained" onClick={handleLogOut} endIcon={<LogoutIcon color="danger" />}>
					<Typography noWrap> {langs.get("logout")[language]}</Typography>
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} md={4} xl>
				<Button fullWidth variant="contained" component={Link} to="/profile/edit" endIcon={<SettingsIcon color="info" />}>
					<Typography noWrap>{langs.get("btn_editProfile")[language]}</Typography>
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} md={4} xl>
				<Button disabled fullWidth variant="contained" endIcon={<LockResetIcon color="info" />}>
					<Typography noWrap> {langs.get("btn_changePassword")[language]}</Typography>
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} md={4} xl>
				<Button  fullWidth variant="contained" component={Link} to="/profile/delete" endIcon={<PersonRemoveIcon color="danger" />}>
					<Typography noWrap> {langs.get("btn_deleteAccount")[language]}</Typography>
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} md={4} xl>
				<Button disabled fullWidth variant="contained" endIcon={<SubscriptionsIcon color="info" />}>
					<Typography noWrap>{langs.get("btn_subscribe")[language]}</Typography>
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} md={4} xl>
				<Button disabled fullWidth variant="contained" endIcon={<InstallMobileIcon color="info" />}>
					<Typography noWrap> {langs.get("btn_installApp")[language]}</Typography>
				</Button>
			</Grid>
			{(userName === "m.krasnansky.fafo@gmail.com" || userName === "miso.krasnansky@gmail.com") && (
				<Grid item xs={12}>
					<Button variant="contained" fullWidth component={Link} to="/admin/master">
						<AdminPanelSettingsIcon color="danger" />
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
