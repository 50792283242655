import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../theme/theme";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { createLoginLangs } from "../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const SubmitButton = (props) => {
	const [langs] = useState(createLoginLangs());
	const language = useSelector((state) => state.language.value);

	const { formType, loading, afterSuccessloading, afterErrloading } = props;
	return (
		<Grid item xs={12}>
			<Box sx={{ position: "relative" }}>
				<Button
					type="submit"
					variant="contained"
					color="danger"
					sx={{
						color: theme.palette.light.main,
						width: "100%",
					}}
				>
					{formType !== "login" ? langs.get("lang_registration")[language] : langs.get("lang_login")[language]}
				</Button>
				{loading && (
					<CircularProgress
						size={24}
						sx={{
							color: theme.palette.light.main,
							position: "absolute",
							top: "50%",
							right: "2%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
				)}
				{afterSuccessloading && (
					<CheckIcon
						sx={{
							color: theme.palette.light.main,
							position: "absolute",
							top: "50%",
							right: "2%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
				)}
				{afterErrloading && (
					<ErrorOutlineIcon
						sx={{
							color: theme.palette.danger.main,
							position: "absolute",
							top: "50%",
							right: "2%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
				)}
			</Box>
		</Grid>
	);
};
