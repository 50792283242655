import axios from "axios";

let link;
if (process.env.REACT_APP_NODE_ENV !== "prod") {
	link = "http://localhost:5000/";
} else {
	link = "https://cv-23-backend.onrender.com/";
}

export const coldStart = async () => {
	let getLink = `${link}`;
	try {
		const response = await axios.get(getLink);
		const result = await { status: response.status };
		return result;
	} catch (err) {}
};

export const deleteProfile = async (data) => {
	let getLink = `${link}users/deleteuser`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				throw error;
			});
		return result;
	} catch (error) {
		return error;
	}
};
export const getProfileData = async (data) => {
	let getLink = `${link}profile/profile`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				throw error;
			});
		return result;
	} catch (error) {
		return error;
	}
};
export const editProfileInfo = async (data) => {
	let getLink = `${link}profile/updateprofile`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				return 500;
			});
		return result;
	} catch (error) {
		return error;
	}
};

export const checkGoogleAuth = async (data) => {
	let getLink = `${link}google/auth`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				return { status: 500, user: error };
			});
		if (result.status === 200) {
			return googleRegOrLogin(result.data);
		}
	} catch (error) {
		return { status: 500, user: error };
	}
};
export const googleRegOrLogin = async (data) => {
	let getLink = `${link}google/regorlogin`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				return { status: error.response.status, message: error.response.statusText };
			});
		if (result.data.token) {
			sessionStorage.setItem("token", result.data.token);
		}
		return result;
	} catch (error) {
		return error;
	}
};

export const forgot = async (mail) => {
	let getLink = `${link}users/forgot`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				mail,
			})
			.catch((error) => {
				return { status: 500, user: error };
			});
		return result;
	} catch (error) {
		return { status: 500, user: error };
	}
};

export const sendMail = async (objSend) => {
	let getLink = `${link}sendMail/contact`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				objSend,
			})
			.catch((error) => {
				return 500;
			});
		return result;
	} catch (error) {
		return error;
	}
};

export const sendIntroduce = async (objSend) => {
	let getLink = `${link}sendMail/introduce`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				objSend,
			})
			.catch((error) => {
				return 500;
			});
		return result;
	} catch (error) {
		return error;
	}
};

export const submitForm = async (formType, data) => {
	let getLink = `${link}users/${formType}`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
				typ: formType,
			})
			.catch((error) => {
				if (error.response) {
					return error.response;
				} else {
					return { data: { ...error }, status: 500 };
				}
			})
			.then((res) => res)
			.catch((error) => {
				if (error.response) {
					return error.response;
				} else {
					return { data: { ...error }, status: 501 };
				}
			});
		if (formType === "login" && !result.data.token) {
			throw result.status;
		}
		if (result.data.token) {
			sessionStorage.setItem("token", result.data.token);
		}
		return result;
	} catch (error) {
		throw error;
	}
};

export const checkToken = async () => {
	let getLink = `${link}users/check`;
	let token = sessionStorage.getItem("token");
	try {
		const response = await axios.get(getLink, {
			headers: {
				Authorization: token,
			},
		});
		const result = await { status: response.status, user: response.data.user };
		return result;
	} catch (err) {}
};
export const checkTokenChngPwd = async (token) => {
	let getLink = `${link}users/checkForgotAuthToken`;
	try {
		const response = await axios.get(getLink, {
			headers: {
				Authorization: token,
			},
		});
		const result = await { status: response.status, user: response.data.message };
		return result;
	} catch (err) {
		const result = await { status: 500, user: err };
		return result;
	}
};
export const updateForgotPwd = async (data) => {
	let getLink = `${link}users/updPwd`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				console.log(error, "ERR UPD AXIOS");
				throw error;
			});
		return result;
	} catch (error) {
		return error;
	}
};
export const authSpotify = async (data) => {
	let getLink = `${link}spotify/auth`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				throw error;
			});
		return result;
	} catch (error) {
		return error;
	}
};
export const saveFinanceData = async (data) => {
	let getLink = `${link}finance/create`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				throw error;
			});
		return result;
	} catch (error) {
		return error;
	}
};
export const getFinanceData = async (data) => {
	let getLink = `${link}finance/finance`;
	try {
		const result = await axios
			.post(getLink, {
				headers: {
					"Content-Type": "application/json",
				},
				data,
			})
			.catch((error) => {
				throw error;
			});
		return result;
	} catch (error) {
		return error;
	}
};
