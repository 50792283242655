import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProfileLangs } from "../../../assets/dummyData/langy";
import { SubmitContactBtn } from "../../home/contact/submitContactBtn";
import { setUserData } from "../../../store/features/profile/profileSlice";
import { useNavigate } from "react-router-dom";
import { deleteProfile } from "../../../assets/js/axiosCalls";
import { setUserName } from "../../../store/features/topics/userNameSlice";
import { setStateLockIcon } from "../../../store/features/topics/lockIconSlice";

export const ReasonForm = (props) => {
	const [langs] = useState(createProfileLangs());
	const language = useSelector((state) => state.language.value);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [showLoading, setShowLoading] = useState(false);
	const [errSubmit, setErrSubmit] = useState(false);
	const [err, setErr] = useState(false);
	const [msg, setMsg] = useState("");

	const handleChangeWithout = (e) => {
		if (e.target.checked) {
			setMsg(langs.get("lb_withoutReason")[language]);
		} else {
			setMsg("");
		}
	};

	const sendEditForm = (e) => {
		e.preventDefault();
		setErr(false);
		setShowLoading(true);
		setErrSubmit(false);
		if (msg === "") {
			setErr(true);
			return;
		}
		const data = { user: props.user, data: msg };

		try {
			deleteProfile(data).then((res) => {
				if (res.status !== 500) {
					setShowLoading(false);
					dispatch(
						setUserData({
							username: null,
							finance: {},
							userdata: {},
						}),
					);
					sessionStorage.removeItem("token");
					dispatch(setUserName(""));
					dispatch(setStateLockIcon(false));

					navigate("/topics/login");
				} else {
					setErr(true);
					setShowLoading(false);
					setErrSubmit(true);
				}
			});
		} catch {
			console.log("ERR EDIT");
		}
	};
	return (
		<Box component="form" noValidate autoComplete="off">
			<Grid
				container
				justifyContent="center"
				spacing={2}
				sx={{
					padding: 3,
				}}
			>
				<Grid item xs={12}>
					<Stack spacing={2}>
						<Typography color="error" variant={"h5"} fontWeight={900}>
							{langs.get("lb_reasonGive")[language]}
						</Typography>
						<TextField fullWidth id="outlined-multiline-static" multiline rows={4} onChange={(e) => setMsg(e.target.value)} value={msg} />
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel control={<Switch color="error" onChange={handleChangeWithout} />} label={langs.get("lb_withoutReason")[language]} />
				</Grid>
				<Grid item xs={12}>
					<SubmitContactBtn loading={showLoading} afterErrloading={errSubmit} cb={sendEditForm} color={"error"} text={langs.get("lb_btnConfirm")[language]} errMsg={err} w={1} />
				</Grid>
			</Grid>
		</Box>
	);
};
