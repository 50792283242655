import { TextField } from "@mui/material";
import React, { useState } from "react";

export const EmailFieldStyled = (props) => {
	const [err, setErr] = useState(false);

	const handleChangeInput = (e) => {
		setErr(false);
		let email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		props.cb(e.target.value, props.name, !email.test(e.target.value));
		let out = setTimeout(() => {
			if (!email.test(e.target.value)) {
				props.require && setErr(true);
			}
			clearTimeout(out);
		}, 2000);
	};
	return (
		<TextField
			name={props.name}
			sx={{
				width: "100%",
			}}
			autoComplete="false"
			type="email"
			error={err}
			label={props.lang}
			onChange={handleChangeInput}
			required={props.require ? true : false}

		/>
	);
};
