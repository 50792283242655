import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Slide, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { createProjectSectionLangs } from "../../../assets/dummyData/langy";
import { theme } from "../../../theme/theme";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const ProjectModal = (props) => {
	const [langs] = useState(createProjectSectionLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);

	const { projectTextStack, icon } = props.data;
	const { title, subtitle, describe, img, links } = props.data?.projectData;
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const handleClose = () => {
		props.cb(false);
	};
	const redirTo = (url) => {
		window.open(url);
	};

	return (
		<Dialog open={props.open} fullWidth maxWidth="lg" TransitionComponent={Transition} keepMounted onClose={handleClose}>
			<DialogTitle color={mode === "dark" ? theme.palette.primary.main : theme.palette.dark.main}>
				<Grid container alignItems="center">
					<Grid item xs={12} md={6} align={tablet ? "" : "center"}>
						<Typography variant="h5" fontWeight={500}>
							{title}
						</Typography>
						<Typography variant="subtitle1">{langs.get(subtitle)[language]}</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container justifyContent={tablet ? "end" : "center"} alignItems="center">
							<Grid item p={1}>
								<div
									style={{
										padding: "0.5rem",
										outline: `1px solid ${theme.palette.dark.main}`,
										borderRadius: ".3rem",
									}}
								>
									<Typography fontWeight={500}>STACK</Typography>
								</div>
							</Grid>
							{projectTextStack.map((item, idx) => {
								return (
									<Fragment key={idx}>
										<Divider orientation="vertical" flexItem />
										<Grid item key={idx} p={1}>
											<div
												style={{
													padding: "0.5rem",
													background: theme.palette.dark.main,
													borderRadius: ".3rem",
												}}
											>
												<Typography color={theme.palette.light.main}>{item}</Typography>
											</div>
										</Grid>
									</Fragment>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container justifyContent="center" alignItems="center">
					{tablet && icon && (
						<Grid
							item
							xs={12}
							md="auto"
							sx={{
								transform: "scale(2)",
								filter: "invert(75%)",
							}}
						>
							{icon}
						</Grid>
					)}
					<Grid
						item
						xs={12}
						md={8}
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						{img.map((item, idx) => {
							return <img key={idx} src={`${item}?w=248&fit=crop&auto=format`} alt="" height={tablet ? 600 : 200} image={img} loading="lazy" />;
						})}
					</Grid>
					{tablet && icon && (
						<Grid
							item
							xs={12}
							md="auto"
							sx={{
								transform: "scale(2)",
								filter: "invert(75%)",
							}}
						>
							{icon}
						</Grid>
					)}
				</Grid>
				<DialogContentText color={mode === "dark" ? theme.palette.primary.main : theme.palette.dark.main}>{langs.get(describe)[language]}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color={mode === "dark" ? "dark" : "dark"} onClick={() => redirTo(links.github)}>
					Github
				</Button>
				<Button color={mode === "dark" ? "dark" : "dark"} onClick={() => redirTo(links.webSite)}>
					Web
				</Button>
				<Button color={mode === "dark" ? "dark" : "dark"} onClick={handleClose}>
					{langs.get("lang_btnCloseModal")[language]}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
