import React from "react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Typography } from "@mui/material";

export const TrueStory = (props) => {
	const { langs, language } = props;

	return (
		<>
			<Typography gutterBottom color="text.primary" variant="h5">
				{langs.get("storyTitle")[language]}
			</Typography>
			<Typography gutterBottom color="text.light" variant="body1">
				{langs.get("story1")[language]}
			</Typography>
			<Typography gutterBottom color="text.primary" variant="body1">
				{langs.get("story3")[language]}
			</Typography>
			<Typography gutterBottom color="text.light" variant="body1">
				{langs.get("story4")[language]}
			</Typography>
			<Typography gutterBottom color="text.primary" variant="body1">
				{langs.get("story5")[language]}
			</Typography>
			<Typography gutterBottom color="text.light" variant="body1">
				{langs.get("story6")[language]} <EmojiEmotionsIcon color="text.light" />
			</Typography>
		</>
	);
};
