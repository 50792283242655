import React, { useState } from "react";
import { Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { createOtherLangs } from "../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const ComingSoon = (props) => {
	const [langs] = useState(createOtherLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("titleSpecializtion")[language]}

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	return (
		<Paper
			sx={{
				position: "absolute",
				top: "20%",
				right: tablet ? "10%" : "10%",
				transform: "rotate(15deg)",
				whiteSpace: "nowrap",
				padding: 3,
				zIndex: 1200,
			}}
		>
			<Typography color="secondary" variant={tablet ? `h${props.size}` : `h${props.size + 2}`} fontWeight={600}>
				{props.text ? langs.get(props.text)[language] : langs.get("comingOther")[language]}
			</Typography>
		</Paper>
	);
};
