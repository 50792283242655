import React, { useState } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { theme } from "../../../../theme/theme";
import { createFinanceLangs } from "../../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const InfoRezervaRight = (props) => {
	const [langs] = useState(createFinanceLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("lang_more")[language]}
	const mode = useSelector((state) => state.mode.value);
	const { rezerva, plat, usetrene } = props;
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{
				duration: 0.8,
				delay: 0.5,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			<Paper
				sx={{
					background: mode === "dark" ? theme.palette.info.mainTransparent : theme.palette.gray.mainTransparent,
					color: theme.palette.info.main,
					padding: 2,
				}}
			>
				<Stack spacing={1}>
					<Typography variant="body1" color="text.light">
						{langs.get("infoActualState_reserve")[language]}
						<span
							style={{
								background: "#24457d",
								paddingInline: 10,
								borderRadius: 3,
								marginInline: 5,
								color: "white",
							}}
						>
							{rezerva} / {6 * plat} €
						</span>
					</Typography>

					<Typography variant="body1" color="text.light">
						{langs.get("infoActualPercentage_reserve")[language]}
						<span
							style={{
								background: "#24457d",
								paddingInline: 10,
								borderRadius: 3,
								marginInline: 5,
								color: "white",
							}}
						>
							{(rezerva / ((6 * plat) / 100)).toFixed(2)} %
						</span>
					</Typography>

					{usetrene > 0 && (
						<>
							<Typography variant="body1" color="text.light">
								{langs.get("infoSave_money")[language]}
								<span
									style={{
										background: "#24457d",
										paddingInline: 10,
										borderRadius: 3,
										marginInline: 5,
										color: "white",
									}}
								>
									{usetrene} €
								</span>
							</Typography>
							<Typography variant="body1" color="text.light">
								{langs.get("perDay_money")[language]}
								<span
									style={{
										background: "#24457d",
										paddingInline: 10,
										borderRadius: 3,
										marginInline: 5,
										color: "white",
									}}
								>
									{(usetrene / 30).toFixed(2)} € {langs.get("lang_more")[language]}.
								</span>
							</Typography>
							{rezerva < plat * 6 && (
								<>
									<Typography variant="body1" color="text.light">
										{langs.get("infoFinish_reserve")[language]}
										<span
											style={{
												background: "#24457d",
												paddingInline: 10,
												borderRadius: 3,
												marginInline: 5,
												color: "white",
											}}
										>
											{((6 * plat - rezerva) / usetrene).toFixed(2)} {langs.get("lang_month")[language]}
										</span>
										<span
											style={{
												background: "#24457d",
												paddingInline: 10,
												borderRadius: 3,
												marginInline: 5,
												color: "white",
											}}
										>
											{((6 * plat - rezerva) / usetrene / 12).toFixed(2)} {langs.get("lang_year")[language]}.
										</span>
									</Typography>
								</>
							)}
						</>
					)}
				</Stack>
			</Paper>
		</motion.div>
	);
};
