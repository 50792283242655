import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: localStorage.getItem("language") ? localStorage.getItem("language") : "eng",
};

export const globalLanguageSlice = createSlice({
	name: "language",
	initialState,
	reducers: {
		setLanguage: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setLanguage } = globalLanguageSlice.actions;

export default globalLanguageSlice.reducer;
