import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { CustomizedButton } from "../../customizedButton";
import { PrivateSectionAlert } from "../../privateSectionAlert";
import SavingsIcon from "@mui/icons-material/Savings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
export const AppsGrid = () => {
	const isLoged = useSelector((state) => state.lockIcon.value);
	const buttons = [
		{
			path: "/topics/apps/finance",
			title: "Finance manager",
			icon: SavingsIcon,
		},
		{
			path: "/topics/apps/music",
			title: "Music player",
			icon: PlayArrowIcon,
		},
	];
	return (
		<Container maxWidth="xl">
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{
					opacity: 1,
					scale: 1,
					transition: {
						opacity: {
							duration: 0.8,
							delay: 0.5,
							ease: [0, 0.71, 0.2, 1.01],
						},
					},
				}}
			>
				{isLoged ? (
					<Grid container spacing={3}>
						{buttons.map((item, idx) => {
							return (
								<Grid item key={idx}>
									<CustomizedButton data={item} />
								</Grid>
							);
						})}
					</Grid>
				) : (
					<PrivateSectionAlert />
				)}
			</motion.div>
		</Container>
	);
};
