import { SvgIcon } from "@mui/material";
import React from "react";

export const IconComponent = (props) => {
	return (
		<SvgIcon
			className={props.class? props.class : ''}
			component={props.src}
			inheritViewBox
			color={props.color ? props.color : "primary"}
			sx={{
				fontSize: `${props.size}rem`,
				fill: props.color,
			}}
		/>
	);
};
