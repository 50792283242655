import React, { useEffect } from "react";
import { Container, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { theme } from "../theme/theme";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const navigate = useNavigate();
	useEffect(() => {
		setTimeout(() => {
			navigate("/topics");
		}, 5000);
	}, [navigate]);
	return (
		<Container
			maxWidth="xl"
			sx={{
				padding: tablet ? 5 : 1,
			}}
		>
			<Paper
				sx={{
					background: theme.palette.dark.mainTransparent,
					padding: 5,
				}}
			>
				<Typography variant="h1" color={theme.palette.light.main} fontWeight={900} align="center">
					404
				</Typography>
				<Typography variant="h1" color={theme.palette.light.main} fontWeight={300} align="center">
					Page not found
				</Typography>
			</Paper>
		</Container>
	);
};
