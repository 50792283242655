import React, { useState } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { theme } from "../../../../theme/theme";
import { createFinanceLangs } from "../../../../assets/dummyData/langy";
import { useSelector } from "react-redux";

export const DescribeLeft = (props) => {
	const [langs] = useState(createFinanceLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);
	const { plat, splatky, potraviny, ostatne, usetrene } = props.data;
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{
				duration: 0.8,
				delay: 0.5,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			<Stack spacing={1}>
				<Paper
					sx={{
						background: mode === "dark" ? theme.palette.info.mainTransparent : theme.palette.gray.mainTransparent,
						color: theme.palette.info.main,
						padding: 2,
					}}
				>
					<Stack spacing={1}>
						<Typography variant="h5" color="text.light">
							{langs.get("month_salary")[language]}
							<span
								style={{
									background: "#24457d",
									paddingInline: 10,
									borderRadius: 3,
									marginInline: 5,
									color: "white",
								}}
							>
								{plat} €
							</span>
						</Typography>
						<Typography variant="h5" color="text.light">
							{langs.get("month_totalfee")[language]}
							<span
								style={{
									background: theme.palette.danger.main,
									paddingInline: 10,
									borderRadius: 3,
									marginInline: 5,
									color: "white",
								}}
							>
								{Number(splatky) + Number(potraviny) + Number(ostatne)} €
							</span>
						</Typography>
						<Typography variant="h5" color="text.light">
							{usetrene > 0 ? langs.get("month_save")[language] : langs.get("upTo_budget")[language]}
							<span
								style={{
									background: usetrene > 0 ? theme.palette.success.main : "red",
									paddingInline: 10,
									borderRadius: 3,
									marginInline: 5,
									color: "white",
								}}
							>
								{usetrene > 0 ? usetrene : Math.abs(usetrene)} €
							</span>
						</Typography>
					</Stack>
				</Paper>
			</Stack>
		</motion.div>
	);
};
