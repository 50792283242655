import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../pages/notFound";

export const RouterPath = (props) => {
	return (
		<Routes>
			{props.data.map((route, key) => {
				return <Route key={key} path={route.url} element={route.component} />;
			})}

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};
