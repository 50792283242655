import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { IndexedDBManager } from "../indexeddbstore/apiIndexeddb";

const initialState = {
	value: [],
};

export const fetchData = createAsyncThunk("data/fetchData", async () => {
	let link;
	if (process.env.REACT_APP_NODE_ENV !== "prod") {
		link = "http://localhost:5000/";
	} else {
		link = "https://cv-23-backend.onrender.com/";
	}

	const axiosGetData = async (sublink = "") => {
		let getLink = `${link}${sublink}`;
		try {
			let data;
			await axios
				.get(getLink, {
					retry: 5,
					retryDelay: 3000,
					headers: {
						"Content-Type": "application/json",
					},
				})
				.catch((error) => {
					return error;
				})
				.then((res) => (data = res))
				.catch((error) => {
					return error;
				});

			return data;
		} catch (error) {
			return error;
		}
	};
	try {
		const data = await axiosGetData("store/data");
		if (data.data.length) {
			const indexedDBManager = new IndexedDBManager();

			data.data.forEach(async (item) => {
				let obj = { id: item._id, ...item };
				await indexedDBManager.createRecord(obj);
			});
			return data.data;
		}
	} catch (error) {
		throw Error(error);
	}
});

const cvDataSlice = createSlice({
	name: "data",
	initialState,
	extraReducers: (builder) => {
		builder.addCase(fetchData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchData.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
			state.error = "";
		});
		builder.addCase(fetchData.rejected, (state, action) => {
			state.loading = false;
			state.data = [];
			state.error = action.error.message;
		});
	},
});

export default cvDataSlice.reducer;
