import React from "react";
import { BgLeo } from "../components/bgLeo";
import { AboutMeSection } from "../components/home/aboutMe/aboutMeSection";
import { ContactSection } from "../components/home/contact/contactSection";
import { CreationSection } from "../components/home/creation/creationSection";
import { WelcomeText } from "../components/home/welcomeText";
export const WelcomePage = () => {
	return (
		<>
			<BgLeo />
			<WelcomeText />
			<AboutMeSection />
			<CreationSection />
			<ContactSection />
		</>
	);
};
