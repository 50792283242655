import { useTheme } from "@emotion/react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Card, CardContent, CircularProgress, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFinanceLangs } from "../../../../assets/dummyData/langy";
import { saveFinanceData } from "../../../../assets/js/axiosCalls";
import { setFinanceData } from "../../../../store/features/profile/profileSlice";
import { theme } from "../../../../theme/theme";
import { DescribeLeft } from "./describeLeft";
import { DescribeRightOstatne } from "./describeRightOstatne";
import { FirstInputs } from "./firstInputs";
import { InfoRezervaLeft } from "./infoRezervaLeft";
import { InfoRezervaRight } from "./infoRezervaRight";
import { MainPieItem } from "./mainPieItem";

export const FinContainer = () => {
	
	const mode = useSelector((state) => state.mode.value);
	const [langs] = useState(createFinanceLangs());
	const language = useSelector((state) => state.language.value);
	const financeRData = useSelector((state) => state.profile.finance);
	const dispatch = useDispatch();
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const userName = useSelector((state) => state.userName.value);

	const [plat, setPlat] = useState(0);
	const [splatky, setSplatky] = useState(0);
	const [potraviny, setPotraviny] = useState(0);
	const [ostatne, setOstatne] = useState(0);
	const [usetrene, setUsetrene] = useState(0);
	const [rezerva, setRezerva] = useState(0);
	const [showPie, setShowPie] = useState(false);
	const [listOstatne, setListOstatne] = useState([]);
	const [loading, setLoading] = useState(false);
	const [afterErrloading, setAfterErrloading] = useState(false);
	const handleUpdPlat = (val) => setPlat(val);
	const handleUpdSplatky = (val) => setSplatky(val);
	const handleUpdPotraviny = (val) => setPotraviny(val);
	const handleUpdOstatne = (val) => setOstatne(val);
	const handleUpdListOstatne = (val) => setListOstatne(val);
	const handleToSeTRezerva = (val) => setRezerva(val);
	const [startInputsData] = useState([
		{
			col: "",
			name: "plat",
			typ: "number",
			label: langs.get("month_salary")[language],
			errBool: false,
			reqBool: false,
			cb: handleUpdPlat,
			help: "",
			row: "",
			ref: "inputPlat",
			val: financeRData.plat > 0 ? financeRData.plat : null,
		},
		{
			col: "",
			name: "splatky",
			typ: "number",
			label: langs.get("depth_fee")[language],
			errBool: false,
			reqBool: false,
			cb: handleUpdSplatky,
			help: "",
			row: "",
			ref: "inputSplatky",
			val: financeRData.splatky > 0 ? financeRData.splatky : null,
		},
		{
			col: "",
			name: "potraviny",
			typ: "number",
			label: langs.get("food_fee")[language],
			errBool: false,
			reqBool: false,
			cb: handleUpdPotraviny,
			help: "",
			row: "",
			ref: "inputPotraviny",
			val: financeRData.potraviny > 0 ? financeRData.potraviny : null,
		},
	]);

	const handleSendData = () => {
		setAfterErrloading(false);
		setLoading(true);
		const sendObj = { user: userName, data: { plat: Number(plat), splatky: Number(splatky), potraviny: Number(potraviny), ostatne: Number(ostatne), rezerva: Number(rezerva), listOstatne: listOstatne } };
		try {
			saveFinanceData(sendObj)
				.then((res) => {
					if (res.status === 200) {
						let timeout = setTimeout(() => {
							setLoading(false);
							clearTimeout(timeout);
						}, 1000);
						dispatch(setFinanceData(sendObj.data));
					} else {
						throw new Error("crash");
					}
				})
				.catch((err) => {
					setTimeout(() => {
						setLoading(false);
						setAfterErrloading(true);
					}, 500);
				});
		} catch {
			console.log(" err FIN CONTAINER");
		}
	};

	const getFinance = useCallback(() => {
		if (userName) {
			financeRData.plat > 0 && setPlat(financeRData.plat);
			financeRData.splatky > 0 && setSplatky(financeRData.splatky);
			financeRData.potraviny > 0 && setPotraviny(financeRData.potraviny);
			financeRData.ostatne > 0 && setOstatne(financeRData.ostatne);
			financeRData.rezerva > 0 && setRezerva(financeRData.rezerva);
			financeRData.listOstatne && setListOstatne(financeRData.listOstatne);
		}
	}, [userName, financeRData]);
	useEffect(() => {
		if (plat === 0) {
			getFinance();
		}
		if (plat !== 0 && splatky !== 0 && potraviny !== 0) {
			setUsetrene(plat - splatky - potraviny - ostatne);
			setShowPie(true);
		}
	}, [plat, splatky, potraviny, ostatne, getFinance, listOstatne]);

	const data = [
		{ id: 0, label: langs.get("month_salary")[language], value: Number(plat), color: "#24457d" },
		{ id: 1, label: langs.get("depth_fee")[language], value: Number(splatky), color: theme.palette.danger.main },
		{ id: 2, label: langs.get("food_fee")[language], value: Number(potraviny), color: "#7f6c7c" },
		{ id: 3, label: langs.get("other_fee")[language], value: Number(ostatne), color: "#b3b3b3" },
		{ id: 4, label: langs.get("month_save")[language], value: Number(usetrene), color: `${theme.palette.success.main}` },
	];
	const dataWithoutPlat = [
		{ id: 1, label: langs.get("depth_fee")[language], value: Number(splatky), color: theme.palette.danger.main },
		{ id: 2, label: langs.get("food_fee")[language], value: Number(potraviny), color: "#7f6c7c" },
		{ id: 3, label: langs.get("other_fee")[language], value: Number(ostatne), color: "#b3b3b3" },
		{ id: 4, label: langs.get("month_save")[language], value: Number(usetrene), color: `${theme.palette.success.main}` },
	];
	return (
	
		<Grid container justifyContent="center">
			<Grid
				item
				sx={{
					width: "100%",
					marginBottom: 1,
				}}
			>
				<Card
					sx={{
						borderRadius: 2,
						background: mode === "dark" ? theme.palette.dark.main : theme.palette.light.main,
					}}
				>
					<CardContent>
						<Grid container spacing={2} alignItems="center" justifyContent="center" mb={3}>
							<Grid item xs={12} mb={4} align="center">
								<Typography variant="h2" fontWeight={900} color={theme.palette.info.main}>
									{langs.get("titleManager")[language]}
								</Typography>
							</Grid>
							<FirstInputs data={startInputsData} plat={plat} splatky={splatky} potraviny={potraviny} />
						</Grid>
						{tablet && showPie && (
							<Grid container spacing={2} alignItems="center" justifyContent="center" mb={3}>
								<Grid item xs={12} sm={6}>
									{tablet && showPie && <MainPieItem data={dataWithoutPlat} data2={data[0]} large={tablet} />}
								</Grid>
								{usetrene && usetrene > 0 && (
									<Grid item xs={12} sm={6} align="left">
										{showPie && (
											<MainPieItem
												large={tablet}
												data={[
													{ value: data[4].value, label: `${data[4].label}`, color: data[4].color },
													{ value: splatky + potraviny + ostatne, label: langs.get("month_totalfee")[language], color: theme.palette.danger.main },
												]}
												data2={data[0]}
											/>
										)}
									</Grid>
								)}
								{usetrene && usetrene <= 0 && (
									<Grid item xs={12} sm={6} align="left">
										{showPie && (
											<MainPieItem
												large={tablet}
												data={[
													{ value: Math.abs(data[4].value), label: langs.get("upTo_budget")[language], color: "red" },
													{ value: data[0].value, label: `${data[0].label}`, color: data[0].color },
												]}
												data2={{ value: splatky + potraviny + ostatne, label: langs.get("month_totalfee")[language], color: theme.palette.danger.main }}
											/>
										)}
									</Grid>
								)}
							</Grid>
						)}
						<Grid container spacing={2}>
							{showPie && (
								<>
									<Grid item xs={12} md={6}>
										<Stack spacing={2}>
											<DescribeLeft data={{ plat: plat, splatky: splatky, potraviny: potraviny, ostatne: ostatne, usetrene: usetrene }} />
											<InfoRezervaRight rezerva={rezerva} plat={plat} usetrene={usetrene} />
										</Stack>
									</Grid>
									<Grid item xs={12} md={6}>
										<Stack spacing={2}>
											<InfoRezervaLeft rezerva={rezerva} plat={plat} setR={handleToSeTRezerva} />
											<DescribeRightOstatne setList={handleUpdListOstatne} dataList={listOstatne} ostatne={ostatne} setO={handleUpdOstatne} />
										</Stack>
									</Grid>

									<Grid item xs={12} mt={2}>
										<Box sx={{ position: "relative" }}>
											<Button
												variant="contained"
												size="large"
												onClick={handleSendData}
												fullWidth
												color="info"
												sx={{
													color: "white",
												}}
											>
												{langs.get("btn_save")[language]}
											</Button>
											{loading && (
												<CircularProgress
													size={24}
													sx={{
														color: theme.palette.light.main,
														position: "absolute",
														top: "50%",
														right: "2%",
														marginTop: "-12px",
														marginLeft: "-12px",
														zIndex: 2000,
													}}
												/>
											)}
											{afterErrloading && (
												<ErrorOutlineIcon
													sx={{
														color: theme.palette.light.main,
														position: "absolute",
														top: "50%",
														right: "2%",
														marginTop: "-12px",
														marginLeft: "-12px",
													}}
												/>
											)}
										</Box>
									</Grid>
								</>
							)}
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
