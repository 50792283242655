import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../../../../../theme/theme";

export const PlayerButtons = (props) => {
	const [paused, setPaused] = useState(true);
	const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
	const oldTrack = useRef(null);

	const handlePouse = () => {
		paused ? oldTrack.current.play() : oldTrack.current.pause();
		setPaused(!paused);
	};
	useEffect(() => {
		if (oldTrack.current && !oldTrack.current.paused) {
			oldTrack.current.pause();
			setPaused(true);
		}
		oldTrack.current = props.track;
		if (props.track) {
			oldTrack.current.play();
			setPaused(false);
		}
	}, [props, oldTrack]);

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				mt: -1,
			}}
		>
			<IconButton aria-label="previous song">
				<FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
			</IconButton>
			<IconButton aria-label={paused ? "play" : "pause"} onClick={handlePouse}>
				{paused ? <PlayArrowRounded sx={{ fontSize: "3rem" }} htmlColor={mainIconColor} /> : <PauseRounded sx={{ fontSize: "3rem" }} htmlColor={mainIconColor} />}
			</IconButton>
			<IconButton aria-label="next song">
				<FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
			</IconButton>
		</Box>
	);
};
