import { useTheme } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../theme/theme";
import { EditForm } from "./editForm";
export const EditFormContainer = () => {
	const mode = useSelector((state) => state.mode.value);
	const userName = useSelector((state) => state.userName.value);
	const navigate = useNavigate();

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const handleClickBack = () => navigate("/profile");
	return (
		<Grid container p={3} spacing={4}>
			<Grid
				item
				xs={12}
				sx={{
					position: "relative",
					display: tablet ? "flex" : "grid",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<IconButton
					aria-label="back"
					size="small"
					onClick={handleClickBack}
					sx={{
						position: tablet && "absolute",
						left: tablet && 0,
						background: mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.main,
						width: tablet ? 100 : "100%",
						borderRadius: 1,
					}}
				>
					<ArrowBackIcon />
				</IconButton>
				<Typography variant={tablet ? "h4" : "body2"} align="center" color={mode === "dark" ? theme.palette.primary.main : theme.palette.dark.main} fontWeight={900}>
					{userName && userName.toLocaleUpperCase()}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<EditForm user={userName} />
			</Grid>
		</Grid>
	);
};
