import { ReactComponent as jsSvg } from "../images/loga/JavaScript.svg";
import { ReactComponent as runSvg } from "../images/svg/run.svg";
import { ReactComponent as seboSvg } from "../images/svg/sebo.svg";

export const aboutMeData = [
	{
		imgSrc: jsSvg,
		subText: "programovanieAbout",
		text: "subProgramovanieAbout",
	},
	{
		imgSrc: runSvg,
		subText: "hobbyAbout",
		text: "subHobbyAbout",
	},
	{
		imgSrc: seboSvg,
		subText: "rodinaAbout",
		text: "subRodinaAbout",
	},
];

export const oblastiData = [
	{
		subText: "programovanieSpecializtion",
		text: `subProgramovanieSpecializtion`,
	},
	{
		subText: "navSpecializtion",
		text: `subNavSpecializtion`,
	},
];
