import React, { useState } from "react";
import { Card, CardActionArea, CardContent, CardMedia, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { theme } from "../../theme/theme";
import { Link } from "react-router-dom";
import { topicInitData } from "../../assets/dummyData/topicInitData";
import { CardTopBar } from "./cardTopBar";
import { ComingSoon } from "../comingSoon";
import { motion } from "framer-motion";
import { createTopicsLangs } from "../../assets/dummyData/langy";

export const TopicGrid = () => {
	const [langs] = useState(createTopicsLangs());
	const language = useSelector((state) => state.language.value);

	const isLoged = useSelector((state) => state.lockIcon.value);
	const mode = useSelector((state) => state.mode.value);
	const [cards] = useState(topicInitData);
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const renderTopicCards = () => {
		let ratingTopicsCard = JSON.parse(localStorage.getItem("favoriteTopics"));
		const withRatting = cards.map((item) => {
			item.rating = ratingTopicsCard && ratingTopicsCard[item.cardName] ? parseFloat(ratingTopicsCard[item.cardName]) : 0;
			return item;
		});

		const cardSortByRating = withRatting.sort((a, b) => (a.rating < b.rating ? 1 : -1));

		return (
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{
					duration: 0.8,
					delay: 0.5,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<Grid spacing={2} container justifyContent="center">
					{cardSortByRating.map((item, idx) => {
						return (
							<Grid key={idx} item xs={12} md={6} lg={4}>
								<Stack
									sx={{
										height: "100%",
									}}
								>
									<motion.div whileHover={item.active ? (isLoged || !item.private) && { scale: 1.03 } : ""}>
										<Card
											className={`${item.private === true && !isLoged ? "bg-gradient-unavailable " : "bg-gradient"}`}
											sx={{
												height: 350,
												display: "flex",
												flexDirection: "column",
												justifyContent: "space-between",
												alignItems: "stretch",
											}}
										>
											<div
												style={{
													position: "relative",
												}}
											>
												<div
													style={{
														position: "absolute",
														top: "50%",
														left: "15%",
														right: "15%",
														transform: `translate(${tablet ? "-7%" : "-9%"}, -50%)`,
														zIndex: 1500,
														textAlign: "center",
													}}
												></div>
												<CardActionArea disabled={item.active ? (item.private ? !isLoged : false) : true}>
													{item.private
														? !isLoged && (
																<Stack
																	className="bg-gradient-unavailable"
																	sx={{
																		position: "absolute",
																		width: "100%",
																		height: "100%",
																	}}
																/>
														  )
														: ""}

													<Link
														to={item.url}
														style={{
															textDecoration: "none",
															color: mode === "dark" ? theme.palette.light.main : theme.palette.dark.main,
														}}
													>
														<CardMedia
															sx={{
																filter: "sepia(100%)",
															}}
															component="img"
															height="150"
															image={item.img}
															alt=""
														/>
														<CardContent
															sx={{
																height: "100%",
															}}
														>
															{item.private && !isLoged && <ComingSoon size={3} text="langPriavateOther" />}
															{!item.active && (!item.private || isLoged) && <ComingSoon size={3} />}
															<Typography variant="h5" component="div">
																<Stack
																	sx={{
																		display: "flex",
																		flexDirection: "row",
																		alignItems: "center",
																		justifyContent: "space-between",
																	}}
																>
																	{langs.get(item.title)[language]} {item.icon}
																</Stack>
															</Typography>
															<Typography gutterBottom variant="subtitle2">
																{langs.get(item.subtitle)[language]}
															</Typography>
															<Typography variant="body2" color="light">
																{langs.get(item.text)[language]}
															</Typography>
														</CardContent>
													</Link>
												</CardActionArea>
											</div>
											<CardTopBar data={item} />
										</Card>
									</motion.div>
								</Stack>
							</Grid>
						);
					})}
				</Grid>
			</motion.div>
		);
	};
	return renderTopicCards();
};
