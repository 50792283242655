import { Alert, IconButton, LinearProgress } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createLoginLangs } from "../../../assets/dummyData/langy";
import { ReactComponent as googlelogo } from "../../../assets/images/loga/Google__G__Logo.svg";
import { IconComponent } from "../../../assets/images/svg/iconComponent";
import { checkGoogleAuth, getFinanceData } from "../../../assets/js/axiosCalls";
import { setStateLockIcon } from "../../../store/features/topics/lockIconSlice";
import { setUserName } from "../../../store/features/topics/userNameSlice";
import { theme } from "../../../theme/theme";
import { setFinanceData } from "../../../store/features/profile/profileSlice";

export const OauthGoogleButton = (props) => {
	const [linearPreloade, setLinearPreloade] = useState(false);
	const [langs] = useState(createLoginLangs());
	const language = useSelector((state) => state.language.value);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [googleRegisterExistUser, setGoogleRegisterExistUser] = useState(false);

	const googleLogin = useGoogleLogin({
		flow: "auth-code",
		onSuccess: async (codeResponse) => {
			setLinearPreloade(true);
			const res = await checkGoogleAuth(codeResponse);
			if ((res.status && res.status === 200) || (res.status && res.status === 204)) {
				dispatch(setStateLockIcon(true));
				dispatch(setUserName(res.data.user));
				try {
					getFinanceData(res.data.user)
						.then((res) => {
							dispatch(setFinanceData(res.data.data.data));
						})
						.catch((err) => {
						});
				} catch {
					console.log(" err FIN CONTAINER");
				}
				setLinearPreloade(false);
				navigate("/topics");
			} else {
				setGoogleRegisterExistUser(true);
				setLinearPreloade(false);
				let out = setTimeout(() => {
					setGoogleRegisterExistUser(false);
					clearTimeout(out);
				}, 5000);
			}
		},
		onError: (errorResponse) => console.log(errorResponse),
	});
	return (
		<>
			{googleRegisterExistUser && (
				<div
					style={{
						position: "absolute",
						top: "100%",
						left: "50%",
						transform: "translate(-50%,-50%)",
						zIndex: 2000,
					}}
				>
					<motion.div
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{
							duration: 0.8,
							delay: 0.5,
							ease: [0, 0.71, 0.2, 1.01],
						}}
					>
						<Alert severity="error">{langs.get("errMsgLoginGoogleExistAcc")[language]}</Alert>
					</motion.div>
				</div>
			)}

			<IconButton onClick={() => googleLogin()}>
				<IconComponent color={theme.palette.light.main} src={googlelogo} size={2} />
			</IconButton>
			{linearPreloade && <LinearProgress color="success" sx={{ width: "100%", bottom: -10, position: "absolute" }} />}
		</>
	);
};
