import { useTheme } from "@emotion/react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ArticleIcon from "@mui/icons-material/Article";
import LoginIcon from "@mui/icons-material/Login";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { Container, Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createNavLangs } from "../assets/dummyData/langy";
import { menuUrlLinks } from "../assets/dummyData/menuUrl";
import { IconComponent } from "../assets/images/svg/iconComponent";
import { PageFooter } from "../components/footer/pageFooter";
import { PageTitle } from "../components/pageTitle";
import { LoginForm } from "../components/topics/login/loginForm";
import { Curriculum } from "../pages/curriculum";
import { Topics } from "../pages/topics";
import { WelcomePage } from "../pages/welcomePage";
import { theme } from "../theme/theme";
import { BurgerMenu } from "./burgerMenu";
import { LinksRouter } from "./linksRouter";
import { RouterPath } from "./routerPath";
import { Profile } from "../pages/profile";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
export const SwitchRouter = () => {
	const [langs] = useState(createNavLangs());
	const language = useSelector((state) => state.language.value);
	//{langs.get("titleSpecializtion")[language]}
	const mode = useSelector((state) => state.mode.value);

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const desktop = useMediaQuery(query.breakpoints.up("lg"));

	const initialLinks = [
		{ url: "/", text: langs.get("aboutme")[language], icon: <IconComponent src={AccountBoxIcon} size={3.5} color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} />, component: <WelcomePage /> },
		{ url: "/topics", text: langs.get("topics")[language], icon: <IconComponent src={ViewCarouselIcon} size={3.5} color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} />, component: <Topics /> },
		{ url: "/cv", text: langs.get("cv")[language], icon: <IconComponent src={ArticleIcon} size={3.5} color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} />, component: <Curriculum /> },
		{ url: "/profile", text: langs.get("langProfile")[language], icon: <IconComponent src={ManageAccountsIcon} size={3.5} color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} />, component: <Profile /> },
		{
			url: "/topics/login",
			text: langs.get("login")[language],
			icon: <IconComponent src={LoginIcon} size={3.5} color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} />,
			component: <LoginForm />,
		},
	];
	return (
		<Router>
			<Container
				className="header"
				maxWidth="xl"
				sx={{
					padding: tablet ? 5 : 1,
					position: "relative",
				}}
			>
				<PageTitle />
				{desktop === true ? <LinksRouter data={initialLinks} /> : <BurgerMenu data={initialLinks} />}
			</Container>
			<Grid container alignItems="center" className="main" justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<RouterPath data={menuUrlLinks} />
				</Grid>
			</Grid>
			<PageFooter />
		</Router>
	);
};
