import { List } from "@mui/material";
import React from "react";
import { ItemPlay } from "./itemPlay";

export const ListItemPlay = (props) => {
	return (
		<List sx={{ width: "100%", maxWidth: 360, maxHeight: 500, overflow:'hidden' }}>
			{props.data.length !== 0 &&
				props.data.map((item, idx) => {
					return item.preview_url && <ItemPlay key={idx} item={item} />;
				})}
		</List>
	);
};
