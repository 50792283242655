import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { createNavLangs } from "../assets/dummyData/langy";
import { theme } from "../theme/theme";

export const PageTitle = () => {
	const [langs] = useState(createNavLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);

	const query = useTheme();
	const lowpixel = useMediaQuery(query.breakpoints.down("sm"));
	const tablet = useMediaQuery(query.breakpoints.up("md"));
	const desktop = useMediaQuery(query.breakpoints.up("lg"));

	return (
		<Grid container>
			<Grid item>
				<Paper
					className={mode === "light" ? "bg-gradient" : "bg-gradient-dark"}
					sx={{
						display: lowpixel && "none",
						position: "absolute",
						top: !tablet ? "-8.5rem" : "-7rem",
						left: !tablet && "50%",
						transform: !tablet && `translate(-50%)`,
						paddingX: "1rem",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "end",
							paddingTop: "9rem",
							paddingBottom: "1rem",
						}}
					>
						<Typography
							noWrap
							color={theme.palette.light.main}
							variant={desktop ? "h5" : "body1"}
							sx={{
								textShadow: `1px 1px 2px ${theme.palette.dark.main}`,
							}}
						>
							{langs.get("titleRecriuter")[language]}
						</Typography>
						<Typography noWrap variant={desktop ? "subtitle2" : "body2"} color={theme.palette.light.main}>
							{langs.get("titleByMe")[language]}
						</Typography>
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};
