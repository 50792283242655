import { TextField } from "@mui/material";
import React, { useState } from "react";

export const TextareaFieldStyled = (props) => {
	const [err, setErr] = useState(false);

	const handleChangeInput = (e) => {
		setErr(false);
		props.cb(e.target.value, props.name, err);
		let out = setTimeout(() => {
			if (e.target.value.trim().length < 4) {
				props.require && setErr(true);
			}
			clearTimeout(out);
		}, 2000);
	};
	return (
		<TextField
			className="textAreaStyle"
			rows={3}
			multiline
			name={props.name}
			sx={{
				width: "100%",
			}}
			type="text"
			error={err}
			label={props.lang}
			onChange={handleChangeInput}
			required={props.require ? true : false}
		/>
	);
};
