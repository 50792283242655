import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	album: {},
	artists: [],
	duration_ms: 0,
	external_urls: {},
	href: null,
	id: null,
	name: null,
	popularity: null,
	preview_url: null,
	type: null,
	uri: null,
};

export const actualPlaySlice = createSlice({
	name: "song",
	initialState,
	reducers: {
		setPlaying: (state, action) => {
			return (state = { ...action.payload });
		},
	},
});

export const { setPlaying } = actualPlaySlice.actions;

export default actualPlaySlice.reducer;
