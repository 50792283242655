import { Grid, List, ListItemText } from "@mui/material";
import React from "react";
import { skillData } from "../../assets/dummyData/skillData";
import styles from "../../assets/styles/css/stylesCv.module.scss";

export const ExResumeMob = () => {
	return (
		<div id="resumeMob" className={styles.curiculum}>
			<div className={styles.container}>
				<aside className={styles.aside}>
					<div>
						<h1>Michal Krasňanský</h1>
						<small>JavaScript developer</small>
					</div>
					<h3 className={styles.dark}>Kontaktné údaje</h3>
					<div className={styles.contact}>
						<p>
							<strong>Mobil</strong>
						</p>
						0905 225 236
						<p>
							<strong>E-mail</strong>
						</p>
						miso.krasnansky@gmail.com
						<p>
							<strong>Stránka</strong>
						</p>
						www.krasnansky.online
					</div>
					<h3 className={styles.dark}>Znalosti</h3>
					<div
						style={{
							height: "100%",
						}}
					>
						<List>
							{skillData.map((item, idx) => {
								return <ListItemText key={idx}>{item.title}</ListItemText>;
							})}
						</List>
					</div>
				</aside>
				<article className={styles.article}>
					<p>
						Zaujímam sa o programovanie, predovšetkým <span className={styles.span}>front-end</span> Rád sa učím nové technológie. S programovaním sa stretávam na dennej báze v práci a aj po práci doma. Svoj záujem smerujem
						predovšetkým na technológie (<span className={styles.span}>React.js, TypeScript, Material-UI, Bootstrap 5, Css/Sass, Node.js, SQL, NoSql</span>
						), no zároveň som otvorený novým výzvam.
					</p>
					<hr className={styles.hr} />
					<h3>Pracovné skúsenosti</h3>
					<hr className={styles.hr} />
					<ul>
						<li>Ticketportal - Frontend Developer (11/2021 - trvá)</li>
						<li>Unicorn - Full-stack Developer (10/2021 - 11/2021)</li>
						<li>WEZEO | Hybrid Software Studio - Software Programmer (9/2021 - 10/2021)</li>
						<li>Ringier axel springer - web-developer (3/2021 - 7/2021)</li>
					</ul>

					<hr className={styles.hr} />
					<h3>Vzdelanie</h3>
					<hr className={styles.hr} />

					<p>
						<strong>Grafický dizajn</strong>
					</p>
					<p>
						<i>2004 - 2009 Stredná umelecká Škola Animovanej Tvorby – Bratislava</i>
					</p>

					<hr className={styles.hr} />
					<h3>Kurzy</h3>
					<hr className={styles.hr} />
					<div className={styles.kurzy}>
						<Grid container>
							<Grid item xs={12}>
								<ul>
									<strong>Udemy</strong>
									<li>Complete Microsoft SQL Server Database Administration Course</li>
									<li>Complete C# Masterclass</li>
									<li>React Native (The Practical Guide 2023)</li>
									<li>Serverless Framework Bootcamp (Node.js, AWS & Microservices)</li>
									<li>Amazon Web Services (AWS) with JavaScript & NodeJS</li>
									<li>Complete Linux Training Course to Get Your Dream IT</li>
									<li>React - The Complete Guide (incl Hooks, React Router, Redux)</li>
									<li>Master NestJS - The JavaScript Node.js Framework</li>
									<li>Understanding TypeScript - 2021 Edition</li>
									<li>NestJS Zero to Hero - Modern TypeScript Back-end Development</li>
									<li>NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)</li>
									<li>The Complete Modern JavaScript Masterclass 2021</li>
									<li>Quasar V1: PWA (with Vue JS 2, Firebase, NodeJS & Express)</li>
									<li>Vuetify: Create an App with Vue JS & Vuex - in 5 Hours!</li>
									<li>Intro to Node JS & Express</li>
									<li>JavaScript DOM Dynamic Web interactive content Boot Camp</li>
								</ul>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={6}>
										<ul>
											<strong>Learn2code</strong>
											<li>MongoDb</li>
											<li>Webrebel 1: HTML, CSS & JavaScript</li>
											<li>JavaScript & ES6</li>
											<li>React.js</li>
											<li>Angular 2+</li>
											<li>Vue.js + SPA</li>
											<li>Css preprocesory: Sass</li>
											<li>Git</li>
											<li>OOP</li>
											<li>Bootstrap</li>
											<li>Tailwind Css</li>
											<li>Adobe Illustrator, Photoshop</li>
										</ul>
									</Grid>
									<Grid item xs={6}>
										<ul>
											<strong>Vzhurudolu.cz</strong>
											<li>Praktický úvod do Vue & Vue 3 prakticky</li>
										</ul>
										<ul>
											<strong>Vue Mastery</strong>
											<li>Intro to Vue 2 & Vue 3</li>
											<li>Real World Vue 3 & Tourin Vue Router</li>
											<li>Vue 3 Forms</li>
										</ul>

										<ul>
											<strong>ITnetwork.cz</strong>
											<li>Úvod do OOP v JavaScripte & jQuery</li>
										</ul>
										<hr className={styles.hr} />
										<ul>
											<li>Anglický jazyk: pokročilý</li>
											<li>Vodičský preukaz typu: B</li>
										</ul>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</article>
			</div>
		</div>
	);
};
