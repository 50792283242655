import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createFormularLangs } from "../../../assets/dummyData/langy";
import { theme } from "../../../theme/theme";
import { SelectStyled } from "./selectStyled";
import { TextFieldStyled } from "./textFieldStyled";
import { EmailFieldStyled } from "./emailFieldStyled";
import { NumberFieldStyled } from "./numberFieldStyled";
import { skillData } from "../../../assets/dummyData/skillData";
import { TextareaFieldStyled } from "./textareaFieldStyled";
import { engBenefits, skBenefits } from "../../../assets/dummyData/introduceData";
import { SubmitContactBtn } from "../../home/contact/submitContactBtn";
import { sendIntroduce } from "../../../assets/js/axiosCalls";

export const FormIntroduceContainer = () => {
	const [err, setErr] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [errSubmit, setErrSubmit] = useState(false);
	const mode = useSelector((state) => state.mode.value);
	const [langs] = useState(createFormularLangs());

	const inputRef = useRef({});
	const language = useSelector((state) => state.language.value);
	const handleSubmit = (e) => {
		setErr(false);
		setShowLoading(true);
		e.preventDefault();

		let sendObj = inputRef.current;
		console.log(sendObj);

		if (!sendObj.mail || !sendObj.message) {
			setErr(true);
			setShowLoading(false);
		} else {
			try {
				sendIntroduce(sendObj)
					.then((res) => {
						if (res === 500 || res === 400) throw new Error("ERR CONNECTION");
						let out = setTimeout(() => {
							setShowLoading(false);
							document.getElementById("submitBtn").disabled = true;
							document.getElementById("submitBtn").style.backgroundColor = theme.palette.success.main;
							document.getElementById("submitBtn").innerText = langs.get("btn_msg_sent")[language];
							clearTimeout(out);
						}, 1000);

						return res;
					})
					.catch((err) => {
						setShowLoading(false);
						setErrSubmit(true);
						console.log(err, "FRONT");
					});
			} catch (error) {
				setShowLoading(false);
				setErrSubmit(true);
				console.log(err, "FRONT TRY");
			}
		}
	};

	const handleChangeInputs = (resEvent, resName, errorInput) => {
		inputRef.current[resName] = errorInput ? "" : resEvent;
		return {};
	};

	const optionsSelectPersonOrCompany = [langs.get("lb_in_company")[language], langs.get("lb_in_person")[language]];
	const optionsSelectCooperation = [langs.get("lb_tpp")[language], langs.get("lb_partime")[language], langs.get("lb_selfemploiment")[language], langs.get("lb_contract")[language], langs.get("lb_assistance")[language]];
	const optionsProjectStack = skillData.map((i) => i.title);
	const optionsSelectBenefits = language === "eng" ? engBenefits : skBenefits;
	return (
		<Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
			<Grid container color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main} p={2} spacing={2}>
				<Grid item xs={12} md={6} lg={4}>
					<SelectStyled name={"representation"} cb={handleChangeInputs} options={optionsSelectPersonOrCompany} lang={langs.get("lb_company_or_person")[language]} />
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<SelectStyled name={"cooperation"} cb={handleChangeInputs} options={optionsSelectCooperation} lang={langs.get("lb_cooperation")[language]} />
				</Grid>
				<Grid item xs={12} lg={4}>
					<TextFieldStyled name={"titleName"} cb={handleChangeInputs} lang={langs.get("lb_name_or_company")[language]} />
				</Grid>
				<Grid item xs={12} xl={6}>
					<TextFieldStyled name={"projectName"} cb={handleChangeInputs} lang={langs.get("lb_project_name")[language]} />
				</Grid>
				<Grid item xs={12} xl={6}>
					{/* TODO ADRESU DOPLNIT O MAPU */}
					<TextFieldStyled name={"address"} cb={handleChangeInputs} lang={langs.get("lb_address")[language]} />
				</Grid>
				<Grid item xs={12} md={6}>
					<EmailFieldStyled name={"mail"} require={"ano"} cb={handleChangeInputs} lang={langs.get("emailFrom")[language]} />
				</Grid>

				<Grid item xs={12} md={6}>
					{/* TODO minimalny plat prehodnotit podla navrhu spoluprace */}
					{/* TODO moynost plat od do */}
					<NumberFieldStyled name={"salary"} cb={handleChangeInputs} lang={langs.get("lb_plat")[language]} />
				</Grid>
				<Grid item xs={12} xl={6}>
					<SelectStyled name={"benefits"} cb={handleChangeInputs} options={optionsSelectBenefits} multi={"ano"} lang={langs.get("lb_benefits")[language]} />
				</Grid>
				<Grid item xs={12} xl={6}>
					<SelectStyled name={"stack"} cb={handleChangeInputs} options={optionsProjectStack} multi={"ano"} lang={langs.get("lb_project_tech")[language]} />
				</Grid>
				<Grid item xs={12}>
					<TextareaFieldStyled name={"message"} require={"ano"} cb={handleChangeInputs} lang={langs.get("msgFrom")[language]} />
				</Grid>
				<Grid item xs={12}>
					<TextareaFieldStyled name={"feedback"} cb={handleChangeInputs} lang={langs.get("msg_feedBack")[language]} />
				</Grid>
				{err && (
					<Grid item xs={12} xl align="left">
						<Alert severity="error">
							<AlertTitle>{langs.get("errTitleFrom")[language]}</AlertTitle>
							<Typography>{langs.get("errRequiredMailAndMsgFrom")[language]} </Typography>
						</Alert>
					</Grid>
				)}
				<Grid item xs={12} align="right">
					<SubmitContactBtn loading={showLoading} afterErrloading={errSubmit} cb={handleSubmit} text={langs.get("btnSendMsgFrom")[language]} errMsg={err} w={1 / 4} />
				</Grid>
			</Grid>
		</Box>
	);
};
