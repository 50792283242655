import { Grid, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { theme } from "../../../theme/theme";
import { createProfileLangs } from "../../../assets/dummyData/langy";
import { ReasonForm } from "./reasonForm";

export const DeleteContent = () => {
	const [langs] = useState(createProfileLangs());
	const language = useSelector((state) => state.language.value);
	const mode = useSelector((state) => state.mode.value);
	const userName = useSelector((state) => state.userName.value);
	const navigate = useNavigate();

	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.up("md"));

	const handleClickBack = () => navigate("/profile");
	return (
		<Grid container p={3} spacing={4}>
			<Grid
				item
				xs={12}
				sx={{
					position: "relative",
					display: tablet ? "flex" : "grid",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<IconButton
					aria-label="back"
					size="small"
					onClick={handleClickBack}
					sx={{
						position: tablet && "absolute",
						left: tablet && 0,
						background: mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.main,
						width: tablet ? 100 : "100%",
						borderRadius: 1,
					}}
				>
					<ArrowBackIcon color="success" />
				</IconButton>
				<Stack>
					<Typography variant={tablet ? "h5" : "body2"} align="center" color="error" fontWeight={900}>
						{langs.get("lb_titleDelete")[language].toLocaleUpperCase()}
					</Typography>
					<Typography variant={tablet ? "h5" : "body2"} align="center" color='text.info' fontWeight={900}>
						{userName && userName.toLocaleUpperCase()}
					</Typography>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<ReasonForm user={userName} />
			</Grid>
		</Grid>
	);
};
