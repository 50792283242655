import { Card, Container, Divider, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { FormContainer } from "./formContainer";
import { Information } from "./information";
import { useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { theme } from "../../../theme/theme";
export const LoginContent = () => {
	const query = useTheme();
	const tablet = useMediaQuery(query.breakpoints.down("md"));
	const mode = useSelector((state) => state.mode.value);
	return (
		<Container maxWidth="md">
			<motion.div
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{
					duration: 0.8,
					delay: 0.5,
					ease: [0, 0.71, 0.2, 1.01],
				}}
			>
				<Card className={mode === "dark" ? "bg-gradient-dark" : "bg-gradient"}>
					<Grid container color={mode === "dark" ? theme.palette.light.main : theme.palette.dark.main}>
						<Grid item xs={12} md={6} order={tablet ? 3 : 1}>
							<Information />
						</Grid>
						{tablet && (
							<Grid item xs={12} order={2}>
								<Divider />
							</Grid>
						)}
						<Grid item xs={12} md={6} order={tablet ? 1 : 3}>
							<FormContainer />
						</Grid>
					</Grid>
				</Card>
			</motion.div>
		</Container>
	);
};
